import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { deleteDocument, setDocument, uploadDocument, viewDocument } from './actions';

const initialState: any = {
    divisionData: '',
    matrix9x9: ' ',
    document:[]
};

export default createReducer<any, any>(initialState)
    .handleAction(uploadDocument, (state: any, { payload }: any) => {
        return produce(state, (draft: { matrix9x9: any; }) => {
            draft.matrix9x9 = payload;
        });
    })
    .handleAction(viewDocument, (state: any, { payload }: any) => {
        return produce(state, (draft: { matrix9x9: any; }) => {
            draft.matrix9x9 = payload;
        });
    })
    .handleAction(deleteDocument, (state: any, { payload }: any) => {
        console.log('payload', payload)
        return produce(state, (draft: { matrix9x9: any; }) => {
            draft.matrix9x9 = payload;
        });
    })
    .handleAction(setDocument, (state: any, { payload }: any) => {
        console.log('doc payload', payload)
        return produce(state, (draft: { document: any; }) => {
            draft.document = payload;
        });
    });
