import * as Yup from "yup";
import { useFormik } from "formik";

const Utils = () => {
  const InitialValues: any = {
    emailAndPhone: "",
    password: "",
  };
  const ValidationSchema = Yup.object({
    emailAndPhone: Yup.string().required("Required!").email("Invalid Email"),
    password: Yup.string().required("Required!"),
  });
  const onSubmit = async () => {};
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    touched,
    setTouched,
    setFieldTouched,
  } = useFormik({
    initialValues: InitialValues,
    validationSchema: ValidationSchema,
    validateOnMount: true,
    onSubmit,
  });

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    touched,
    setTouched,
    setFieldTouched,
    onSubmit,
  };
};

export default Utils;
