import { Button, TextField } from "@mui/material";
import InputField from "../../InputField";
import {
  btnGrpForBidStatus,
  buttonGroupProject,
  InvitedTradesHeaders,
  ProjectDocumentHeaders,
} from "../../../utils/const";
import ReactQuill from "react-quill";
import CustomizedSnackbar from "../../CustomizedSnackbar";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectProjectId,
  selectThemeColor,
} from "../../../redux/selectors/selectors";
import Modal from "react-modal";
import { CustomTable, ProjectDocumentTable } from "../../DataTable";
import { TabContext, TabPanel } from "@mui/lab";
import {
  askQuestionEmail,
  getAllSelectedTrades,
  getProjectBids,
  getProjectDetails,
  getProjectDocuments,
  updateBidStatus,
  updateOrgTrades,
  updateStatusForAllBids,
} from "../../../utils/api";
import Utils from "./utils";
import AskQuestionsModal from "../AskQuestionsModal/askQuestionsModal";

const style = [
  { width: "100%", height: "50vh", overflowX: "hidden", overflowY: "scroll" },
];

type TradeRow = {
  id: any;
  tradeName: string;
  tradeCode: string;
  status: string;
};

const InvitedBidDetailForm = (props: any) => {
  const [showSnackBar, setShowsnackBar] = useState(false);
  const [message, setMessage] = useState("");
  const [inviteStatus, setInviteStatus] = useState(props.selectRows.status);
  const [selectedButton, setSelectedButton] = useState("bidInfo");
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState(ProjectDocumentHeaders);
  const [projectOwnerName, setProjectOwnerName] = useState("");
  const [tradeRows, setTradeRows] = useState<TradeRow[]>([]);
  const [openSubmitModal, setOpenSubmitModal] = useState<boolean>(false);
  const [openAskQModal, setOpenAskQModal] = useState<boolean>(false);
  const [openAllTradeModal, setOpenAllTradeModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const color = useSelector(selectThemeColor);
  const { values, setFieldValue } = Utils();
  const projectId = useSelector(selectProjectId);

  const handleBidInfoClick = (docType: any, headerCells: any) => {
    setSelectedButton(docType);
    setHeaders(headerCells);
    setRows([]);
    if (projectId && !!rows) {
      if (docType != "bidInfo") {
        getProjectDocuments("", { projectId, docType }, undefined)
          .then((response) => {
            setRows(response.data);
          })
          .catch((error: any) => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    fetchGetProjectAndBidsDetailsApi()
  }, []);

  const fetchGetProjectAndBidsDetailsApi = useCallback(() => {
    getProjectDetails(" ", projectId, undefined)
      .then((response) => {
        console.log("response", response.data);
        if (response.status === "OK") {
          setFieldValue("projectName", response.data.projectName);
          setFieldValue("projectNumber", response.data.projectNumber);
          setFieldValue("buildingSize", response.data.buildingSize);
          setFieldValue("siteSize", response.data.siteSize);
          setFieldValue("projectStatus", response.data.projectStatus);
          setFieldValue("projectContact", response.data.projectContact);
          setFieldValue("siteSize", response.data.siteSize);
          setFieldValue("buildingCount", response.data.buildingCount);
          setFieldValue("storyCount", response.data.storyCount);
          setFieldValue("dollarSize", response.data.dollarSize);
          setFieldValue("duration", response.data.duration);
          setFieldValue("onlineAccess", response.data.onlineAccess);
          setFieldValue("buildingType", response.data.buildingType);
          setFieldValue("bidType", response.data.bidType);
          setFieldValue("jobWalkDate", response.data.jobWalkDate.split(" ")[0]);
          setFieldValue("jobWalkTime", response.data.jobWalkTime);
          setFieldValue("bidDate", response.data.bidDate.split(" ")[0]);
          setFieldValue("bidTime", response.data.bidTime);
          setFieldValue("zipCode", response.data.zipCode);
          setFieldValue("city", response.data.city);
          setFieldValue("state", response.data.state);
          setFieldValue("address1", response.data.address1);
          setFieldValue("constructionType", response.data.constructionType);
          setFieldValue("dollarSize", response.data.dollarSize);
          setFieldValue("sector", response.data.sector);
          setFieldValue("scope", response.data.scope);
          setProjectOwnerName(response.data.ownerName);
          console.log("name", projectOwnerName);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
      });

    getProjectBids("", projectId, undefined)
      .then((resp) => {
        console.log(resp.data, "getTrades");
        setFieldValue("bidPostingDate", resp.data[0].postingDate.split(" ")[0]);
        //setInviteStatus(resp.data[0].status);
        setFieldValue("remainingDays", resp.data[0].remainingDays);
        setTradeRows(resp.data);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }, []);

  // accept/reject/maybe buttons on top: onclick:
  const handleClickOnBidBtn = (event: any) => {
    console.log(event.target.value, "button value to set");
    const status = event.target.value;
    updateStatusForAllBids("", projectId, status)
      .then((response) => {
        if (response.status === "OK") {
          fetchGetProjectAndBidsDetailsApi();
          setMessage(response.message);
          setShowsnackBar(true);
        }
      })
      .catch((error: any) => {
        console.log("error :>> ", error);
      });
  };

  //changing statusof individual bid (buttons in trade table)
  // accept/reject/maybe buttons on trade: onclick:
  const handleChangeTradeStatus = (row: any, checked: boolean) => {
    console.log("rowww", row);
    const updatedRows = tradeRows.map((prevRow) =>
      prevRow.id === row.id ? { ...prevRow, status: row.status } : prevRow
    );
    setTradeRows(updatedRows);
    console.log("new Trade rows", tradeRows);
    const body = { bidId: row.id, status: row.status };
    console.log(body);
    updateBidStatus("", undefined, body)
      .then((resp) => {
        console.log("response.data", resp.data);
        if (resp.status === "OK") {
          setShowsnackBar(true);
          setMessage("Bid status updated Successfully");
        } else {
          setMessage(resp.message);
        }
      })
      .catch((error: any) => {
        console.log("error :>> ", error);
        setShowsnackBar(true);
        setMessage("Bid update failed");
      });
  };

  const handleSubmitBid = () => {
    setOpenSubmitModal(true);
  };

  //onclick of bid submit modal "Submit"
  const handleFormSubmit = () => {
    const body = { bidId: props.bidId, status: "Bid Submitted" };
    console.log(body);
    updateBidStatus("", undefined, body)
      .then((resp) => {
        console.log("response.data", resp.data);
        if (resp.status === "OK") {
          setShowsnackBar(true);
          setMessage("Bid Submitted Successfully");
          props.setBackButton(false);
        } else {
          setMessage(resp.message);
        }
      })
      .catch((error: any) => {
        console.log("error :>> ", error);
        setShowsnackBar(true);
        setMessage("Bid submit failed");
      });
  };

  //On click of "send" of ask questions modal
  const handleClose = () => {
    setOpenAskQModal(false);
    setOpenAllTradeModal(false);
  };

  const handleSubmitQuestion = ({ subject, emailBody, files }: any) => {
    setIsLoading(true);
    const formData = new FormData();
    const body = { projectId, subject, emailBody };
    formData.append("request", JSON.stringify(body));
    files.forEach((file: any) => {
      formData.append(
        "emailAttachments",
        new Blob([file], { type: file.files[0].type }),
        file.files[0].name
      );
    });
    console.log("body que mail", formData);
    askQuestionEmail("", undefined, formData)
      .then((response) => {
        setMessage(response.message);
        setShowsnackBar(true);
        if (response.status === "OK") {
          handleClose();
        }
        setIsLoading(false);
      })
      .catch((error: any) => {
        setShowsnackBar(true);
        setMessage(error.message);
        setIsLoading(false);
        handleClose();
      });
  };

  return (
    <>
      <div className="row gx-0">
        <h4 className="col-6">
          {values.projectName + " by " + projectOwnerName}
        </h4>
        <div className="col-2"></div>
        <div className=" col-4 d-flex justify-content-end">
          {inviteStatus === "Bid Submitted" ? (
            <></>
          ) : (
            btnGrpForBidStatus.map((button: any, index: number) => (
              <Button
                key={index}
                className="col-3 ms-2 btn mb-2 "
                value={button.value}
                style={{
                  background:
                    inviteStatus === button.value
                      ? color.darkColor
                      : color.mediumColor,
                  opacity: inviteStatus === button.value ? 1 : 0.7,
                  color: "white",
                  minHeight: "auto",
                  height: "30px",
                }}
                onClick={(e: any) => {
                  console.log("event bid", e);
                  setInviteStatus(e.target.value);
                  handleClickOnBidBtn(e);
                }}
              >
                {button.label}
              </Button>
            ))
          )}
        </div>

        <div className="row gx-0">
          <div className="col-10">
            {buttonGroupProject.map(
              (
                button: { label: string; value: string; headers: any },
                index: number
              ) => {
                return (
                  <Button
                    key={index}
                    className="col-2 btn ms-2 mb-2 p-1 col-md-2 col-sm-2"
                    style={{
                      background:
                        selectedButton === button.value
                          ? color.darkColor
                          : color.mediumColor,
                      color: "white",
                    }}
                    onClick={() => {
                      handleBidInfoClick(button.value, button.headers);
                    }}
                  >
                    {button.label}
                  </Button>
                );
              }
            )}
          </div>
          <div className="col-2">
            <InputField
              id={"remainingDays"}
              name={"remainingDays"}
              value={values.remainingDays + " Days"}
              type={"text"}
              className={""}
              label={"Remaining Days"}
              readonly
            />
          </div>
        </div>
        {selectedButton === "bidInfo" ? (
          <div style={{ height: "68vh" }}>
            <TabContext value={"Bid Details"}>
              <TabPanel
                className="h-75 mt-2 shadow bg-light"
                style={{ overflow: "scroll" }}
                value="Bid Details"
              >
                <div>
                  {/* Bid Info Form start */}

                  <form className="form-group">
                    <div className="row justify-content-center">
                      <div className="col-8 col-md-6 col-sm-6">
                        <InputField
                          id="projectName"
                          name="projectName"
                          type="text"
                          value={values.projectName}
                          className="form-control"
                          label="Project Name"
                          readonly
                        />
                      </div>
                      {/* <div className="col-3 col-md-3 col-sm-3">
                        <InputField
                          id="projectNumber"
                          name="projectNumber"
                          type="text"
                          value={values.projectNumber.replace(/\D/g, "")}
                          className="form-control"
                          label="Project Number"
                          readonly
                        />
                      </div> */}
                      {/* <div className="col-2 col-md-2 col-sm-2">
                        <InputField
                          id="projectStatus"
                          name="projectStatus"
                          className="form-control"
                          type="text"
                          label="Project Status"
                          value={values.projectStatus}
                        />
                      </div> */}
                    </div>
                    <div className="row justify-content-center mt-1">
                      <div className="col-8 col-md-3 col-sm-3">
                        <InputField
                          id="projectNumber"
                          name="projectNumber"
                          type="text"
                          value={values.projectNumber.replace(/\D/g, "")}
                          className="form-control"
                          label="Project Number"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-1">
                      <div className="col-8 col-md-3 col-sm-3">
                        <TextField
                          id="bidPostingDate"
                          name="bidPostingDate"
                          type="date"
                          label="Bid Posting Date"
                          value={values.bidPostingDate}
                          className="form-control"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: "100%", marginTop: 0 }}
                          fullWidth
                          size="small"
                          aria-readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-8 col-md-2 col-sm-2">
                        <InputField
                          id="projectStatus"
                          name="projectStatus"
                          className="form-control"
                          type="text"
                          label="Project Status"
                          value={values.projectStatus}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-1">
                      <div className="col-8 col-md-3 col-sm-3">
                        <TextField
                          id="bidDate"
                          name="bidDate"
                          label="Bid Date"
                          type="date"
                          className="form-control"
                          value={values.bidDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: "100%", marginTop: 0 }}
                          fullWidth
                          size="small"
                          aria-readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-1">
                      <div className="col-8 col-md-3 col-sm-3">
                        <TextField
                          id="jobWalkDate"
                          name="jobWalkDate"
                          label="Job Walk Date"
                          type="date"
                          className="form-control"
                          value={values.jobWalkDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: "100%", marginTop: 0 }}
                          fullWidth
                          size="small"
                          aria-readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-1">
                      <div className="col-8 col-md-3 col-sm-3">
                        <InputField
                          id="buildingType"
                          name="buildingType"
                          value={values.buildingType}
                          label="Building Type "
                          type="text"
                          className="form-control"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center gx-4">
                      <div className="col-6 justify-content-center">
                      <CustomTable
                        headCells={InvitedTradesHeaders}
                        rows={tradeRows}
                        handleCheck={handleChangeTradeStatus}
                      />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-8 col-md-5 col-sm-5">
                        <InputField
                          id="addressOne"
                          name="addressOne"
                          type="text"
                          value={values.address1}
                          className="form-control"
                          label="Street"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                      <div className="col-3 col-md-2 col-sm-2">
                        <InputField
                          id="zip"
                          name="zip"
                          type="text"
                          value={values.zipCode}
                          className="form-control"
                          label="Zip"
                          readonly
                          maxLength={5}
                        />
                      </div>
                      <div className="col-3 col-md-5 col-sm-5">
                        <InputField
                          id="city"
                          name="city"
                          type="text"
                          value={values.city}
                          className="form-control"
                          label="City"
                          readonly
                        />
                      </div>
                      <div className="col-2 col-md-3 col-sm-3">
                        <InputField
                          id="state"
                          name="state"
                          type="text"
                          value={values.state}
                          className="form-control"
                          readonly
                          label="State"
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-10 offset-2">
                        <Button
                          className="btn"
                          style={{
                            color: "white",
                            background: color.darkColor,
                          }}
                          onClick={() => setOpenAllTradeModal(true)}
                        >
                          All Trades
                        </Button>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-8 col-md-5 col-sm-5">
                        <InputField
                          id="duration"
                          name="duration"
                          type="text"
                          value={values.duration ? values.duration + " Working Days" : "NA" }
                          className="form-control"
                          label="Project Duration"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-8 col-md-3 col-sm-3">
                        <InputField
                          id="dollarSize"
                          name="dollarSize"
                          type="text"
                          value={values.dollarSize}
                          className="form-control"
                          label="Dollar Size"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-8 col-md-5 col-sm-5">
                        <InputField
                          id="constructionType"
                          name="constructionType"
                          label="Construction Type"
                          value={values.constructionType}
                          type="text"
                          className="form-control"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-4 col-md-3 col-sm-3">
                        <InputField
                          id="buildingSize"
                          name="buildingSize"
                          type="text"
                          value={values.buildingSize}
                          className="form-control"
                          label="Building SqFt"
                          readonly
                        />
                      </div>
                      <div className="col-4 col-md-2 col-sm-2">
                        <InputField
                          id="siteSqFt"
                          name="siteSize"
                          type="text"
                          value={values.siteSize}
                          className="form-control"
                          label="Site SqFt"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-4 col-md-3 col-sm-3">
                        <InputField
                          id="buildingCount"
                          name="buildingCount"
                          type="text"
                          value={values.buildingCount}
                          className="form-control"
                          label="Building Count"
                          shrink
                          readonly
                        />
                      </div>
                      <div className="col-4 col-md-2 col-sm-2">
                        <InputField
                          id="storyCount"
                          name="storyCount"
                          type="text"
                          value={values.storyCount}
                          className="form-control"
                          label="Story Count"
                          minValue={0}
                          shrink
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                      <div className="col-1 col-md-1 col-sm-1 mt-5">
                        <p>Scope</p>
                      </div>
                      <div className="col-7 col-md-11 col-sm-11">
                        <ReactQuill
                          theme="snow"
                          value={values.scope}
                          placeholder="Scope....."
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-8 col-md-2 col-sm-2">
                        <InputField
                          id="projectContact"
                          name="projectContact"
                          type="text"
                          className="form-control"
                          label="Project Contact "
                          value={values.projectContact}
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                      <div className="col-8">
                        <InputField
                          id="bidType"
                          name="bidType"
                          value={values.bidType}
                          className="form-control"
                          label="Bid Type "
                          type="text"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                      <div className="col-8 col-md-2 col-sm-2">
                        <InputField
                          id="sector"
                          name="sector"
                          type="text"
                          className="form-control"
                          value={values.sector}
                          label="Sector"
                          readonly
                        />
                      </div>
                    </div>
                  </form>
                  <CustomizedSnackbar
                    openSnackBar={showSnackBar}
                    message={message}
                    handCloseSnackBar={() => setShowsnackBar(false)}
                  />
                </div>
              </TabPanel>
            </TabContext>
            <div
              className="row justify-content-center"
              style={{
                bottom: "20",
                backgroundColor: "#f8f9fa",
                zIndex: "99",
              }}
            >
              {inviteStatus === "Bid Submitted" ? (
                <></>
              ) : (
                <>
                  <Button
                    className="btn rounded col-3 col-md-3 col-sm-3  m-2 p-2"
                    style={{
                      backgroundColor: color.darkColor,
                      color: "white",
                    }}
                    onClick={() => {
                      setOpenAskQModal(true);
                    }}
                  >
                    Ask Questions
                  </Button>
                  <Button
                    className="btn rounded col-3 col-md-3 col-sm-3  m-2 p-2"
                    style={{
                      backgroundColor: color.darkColor,
                      color: "white",
                    }}
                    onClick={() => {
                      // setInviteStatus("Bid Submitted");
                      // handleFormSubmit();
                      setOpenSubmitModal(true);
                      handleSubmitBid();
                    }}
                  >
                    Submit Bid
                  </Button>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row h-25">
              <ProjectDocumentTable
                rows={rows}
                headers={headers}
                style={style}
                isEdit={false}
              />
            </div>
          </div>
        )}
      </div>

      {/* MODAL FOR SUBMIT BID */}
      <Modal
        id="submitBid"
        isOpen={openSubmitModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "auto",
            transform: "translate(-50%, -50%)",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
            opacity: "1",
            background: "#f8f9fa",
            zIndex: "0",
          },
        }}
        ariaHideApp={false}
      >
        <div>
          <div
            className="d-grid shadow mt-3"
            style={{ backgroundColor: "#f3f6f7" }}
          >
            <div className="container">
              <div className="form-floating mt-3 mb-3">
                <InputField
                  id="bidAmount"
                  name="bidAmount"
                  type="text"
                  className="form-control"
                  label="Bid Amount"
                  required
                />
              </div>
              <div className="row justify-content-center mt-3">
                <div className="col-1 col-md-1 col-sm-1 ms-1">
                  <p>Scope</p>
                </div>
                <div className="col-10 col-md-9 col-sm-9"></div>
              </div>
              <div
                className="row justify-content-center"
                style={{ marginTop: "-20px" }}
              >
                <div className="col-11 col-md-11 col-sm-11">
                  <ReactQuill theme="snow" placeholder="Scope....." />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-around mt-4 mb-4">
            <div className="row mt-2 mb-2">
              <Button
                className="btn col-3 col-md-3 col-sm-3 p-2"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenSubmitModal(false);
                }}
                style={{
                  backgroundColor: color.darkColor,
                  color: "white",
                  width: "150px",
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="row mt-2 mb-2">
              <Button
                className="btn col-3 col-md-3 col-sm-3 p-2"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenSubmitModal(false);
                  // handleFormSubmit();
                }}
                style={{
                  backgroundColor: color.darkColor,
                  color: "white",
                  width: "150px",
                }}
              >
                Submit
              </Button>
            </div>
          </div>
          <form className="form-group">
            <div className="row justify-content-center mt-3"></div>
          </form>
        </div>
      </Modal>

      {/* MODAL FOR ASK QUESTIONS */}

      <AskQuestionsModal
        isOpen={openAskQModal}
        handleClose={handleClose}
        handleSubmit={handleSubmitQuestion}
        isLoading={isLoading}
      />
      <AllBidsModal
        isOpen={openAllTradeModal}
        handleClose={handleClose}
        color={color}
        projectId={projectId}
      />
    </>
  );
};

export default InvitedBidDetailForm;


const AllBidsModal =  (props : any) =>{

  const [response, setResponse] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    if(props.isOpen){
      getAllSelectedTrades("", props.projectId, undefined)
        .then((resp) => {
          console.log("resp.data", resp.data.AllTrades);
          if (resp.data.AllTrades.length > 0) {
            setResponse(resp.data.AllTrades);
          }
        })
        .catch(() => {});
    }
  }, [props.isOpen,isRefresh]);

  const  headerCells = [
    { id: "tradeCode", name: "Specs #", action: "" },
    { id: "name", name: "Spec title", action: "" },
    { id: "action", name: "Selected Trades", action: "addRemove" }
  ];
  const handleRowClick = (row : any , type: string, check : boolean) =>{

    let addDivisionList: any = [];
    let editDivisionList: any = [];
    let deletedIdsList: any = [];
  
    if (row["isAdded"] === "Y") {
      deletedIdsList.push(row["id"]);
    } else {
      addDivisionList = [
        {
          id: row["id"],
          name: "",
          parenttrade: null,
          tradeCode: "",
          subDivisionList: [],
        },
      ];
    }
    updateOrgTrades("", props.projectId, {
      addDivisionList,
      editDivisionList,
      deletedIdsList,
    })
      .then((resp) => {
        setIsRefresh(!isRefresh);
      })
      .catch(() => {});
  }

  return (
    <Modal
      id="submitBid"
      isOpen={props.isOpen}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "auto",
          transform: "translate(-50%, -50%)",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
          opacity: "1",
          background: "#f8f9fa",
          zIndex: "0",
        },
      }}
      ariaHideApp={false}
    >
      <div className="modal-content">
        <div className="modal-header mb-2">
          <div className="mx-auto fw-bold size-xl">All Trades</div>
          <Button
            type="button"
            className="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
            onClick={props.handleClose}
          ></Button>
        </div>
      </div>
      <div>
        <div className="d-grid mt-3 bg-white">
          <CustomTable
            headCells={headerCells}
            rows={response}
            onSelectRowClick={handleRowClick}
            style={style}
          />
        </div>
      </div>
    </Modal>
  );
}
