export const SubmenuEnum = {
  NEW_PROJECT: " / New Project",
  BID_OPPORTUNITIES_NETWORK: " / Bid Opportunities Network",
  MY_BIDS: " / My Bids",
  POST_BIDS: " / Post Bids",
  EDIT_PROJECT: " / Edit Bids",
  ADD_USER: "/ Add User",
  EDIT_USER: "/ Edit User",
  INVITED_BIDS: "/Invited Bids",
};

export const UserStatusEnum = {
A:"A",
R:"R"
};

export const ResponseEnum = {
  Y: "Y",
  N: "N",
};

export const PostProjectEnum = {
  ACTIVELY_BIDDING: "Actively Bidding",
  POST_BIDDING: "Post Bid",
  UNDER_CONSTRUCTION: "Under Construction",
  CLOSED: "Closed",
  DRAFT: "Draft",
};


