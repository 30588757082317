import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectProjectId } from "../../../redux/selectors/selectors";
import { getSubListForProject } from "../../../utils/api";
import SubListDropdown from "../SubListDropdown/subListDropdown";
import Loader from "../../Loader";
import { FormControl, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

interface Props {
  headerCells: any;
  index?: any;
  isEdit: any;
  isView: boolean;
  handleSelectedRow?: (row: any) => void;
}

const ProjectSubList = ({ headerCells, isEdit,handleSelectedRow }: Props) => {
  const [result, setResult] = useState<any>([]);
  const [initailResult, setInitialResult] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [searchItem, setSearchItem] = useState("");
  const [refresh, setRefresh] = useState(false);

  const projectId = useSelector(selectProjectId);
  

  useEffect(() => {
    setIsLoading(true);
    getSubListForProject("", projectId, undefined)
      .then((resp) => {
        if (resp === null || resp.data === new Map()) {
          return;
        }
        setResult(resp.data.selectedSubs);
        setInitialResult(resp.data.selectedSubs);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    return () => {
      setRefresh(false);
    };
  }, [refresh, projectId]);

  const refreshSubList = () => {
    setRefresh((prev) => !prev);
  };

  const handleSelect = (row : any)=>{
    console.log('row', row)
    handleSelectedRow!(row)
  }

  const resetToInitialdNodes = () => setResult(initailResult);

  const searchToFilteredNodes = (newSearchItem: any) => {
    const removeSpaces = (str: string) => str.replace(/\s+/g, '');
    const isNumeric = /^[0-9\s]*$/.test(newSearchItem);
    const cleanedSearchItem = isNumeric ? removeSpaces(newSearchItem).toLowerCase() : newSearchItem.toLowerCase();

    const filteredNodes = initailResult.filter((item: any) => {
        const itemNameLower = item.name.toLowerCase();
        const cleanedTradeCode = removeSpaces(item.tradeCode).toLowerCase();
        return itemNameLower.includes(cleanedSearchItem) ||
              cleanedTradeCode.includes(cleanedSearchItem);
    });

    setResult(filteredNodes);
    setSearchItem(newSearchItem);
};


  return (
    <>
      <div className="row col-12 bg-white">
        <FormControl className="search-box">
          <TextField
            size="small"
            variant="outlined"
            disabled={isLoading}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
           
              setShowClearIcon(event.target.value === "" ? "none" : "flex");
             
              searchToFilteredNodes(event.target.value);

              setSearchItem((prev) => event.target.value);
            }}
            value={searchItem}
            placeholder="Search Trade"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  className="pointer"
                  position="end"
                  style={{ display: showClearIcon }}
                  onClick={() => {
                    resetToInitialdNodes();
                    setSearchItem("");
                    setShowClearIcon("none");
                  }}
                >
                  <ClearIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>
      <div className="row gx-0 mt-3" style={{ maxHeight: "50vh", overflowY: "scroll" }}>
        {!isLoading ? (
          result && result.length > 0 ? (
            result.map((node: any, index: any) => (
                <SubListDropdown
                  isEdit={isEdit}
                  headerCells={headerCells}
                  node={node}
                  index={index}
                  isView={false}
                  handleSelectedRow={() => null}
                  projectId={projectId}
                  refreshSubList={refreshSubList}
                />
              )
            )
          ) : (
            <span className="d-flex opacity-70 justify-content-center mt-5 py-5 fs-2">
              No Trades Selected
            </span>
          )
        ) : (
          <Loader
            className="d-flex justify-content-center mt-5 py-5"
            label="Fetching Sub Contractor list"
          />
        )}
      </div>
    </>
  );
};

export default ProjectSubList;
