import { useContext, useRef } from "react";
import { FormControl, InputLabel, Select, Button, MenuItem, TextField } from "@mui/material";
import { CustomTable } from "../../../DataTable";

const BiddingBids = (props: any) => {

    const handleSelectedRow = (row: any, type: string, check: boolean) => {
        props.setBack(true)
        props.getSubMenu(" / In Progress Bids")
        if (type === 'edit')
            handleOpenModal(row)
    }

    const handleOpenModal = (data: any) => {
        props.setSelectedRow(data)
    };

    const headerCells = [
        { id: "projectName", name: "Project Name" },
        { id: "division", name: "Trade" },
        { id: "specification", name: "Specification" },
        { id: "city", name: "City" },
        { id: "bidDate", name: "Bid Date" },
        { id: "constructionType", name: "Construction Type" },
        { id: "action", name: "Action", action: "isRowActionable" }
    ];

    const dateRef: any = useRef()
    return (
        <>
            <div className="container">
                <div className="row mt-3">
                    <CustomTable headCells={headerCells} rows={props.tableRowData} onSelectRowClick = {handleSelectedRow}/>
                </div>
            </div>
        </>
    );
};

export default BiddingBids;
