export const HeaderCells = [
    { id: "projectName", name: "Project Name" },
    { id: "unitNumber", name: "Unit Number", action: 'number' },
    { id: "budget", name: "Budget" },
    { id: "city", name: "City" },
    { id: "zipCode", name: "Zip Code" },
    // { id: "addressOne", name: "Address One" },
    // { id: "addressTwo", name: "Adddress Two" },
    // { id: "name", name: "Project Est Name" },
    // { id: "phoneNo", name: "Project Est Phone Number" },
    { id: "constructionType", name: "Construction Type" },
    { id: "sector", name: "Sector" },
    { id: "action", name: "Action", action: "bidsAndEdit" },
]