import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../redux/selectors/selectors";

interface Props {
  headCells: any[];
  rows: any[];
  style?: any;
  onSelectRowClick?: (row: any, actionType: string, checked: boolean) => void;
  onDeleteRowClick?: (index: any, type: string) => void;
  onViewRow?: (index: any, type: string) => void;
}

const DocumentTable = ({
  headCells,
  rows,
  onDeleteRowClick,
  onViewRow,
}: Props) => {
  const color = useSelector(selectThemeColor);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: color.lightColor,
      fontSize: 16,
      fontWeight: "600",
      padding: "0.7 rem",
      width: "auto",
      minWidth: 100,
      fontFamily:'SenticosansDTCond Thin'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
    },
  }));

  return (
    <TableContainer key="table-container">
      <Table
        key="table"
        className="table shadow"
        sx={{
          overflowX: "hidden",
          overflowY: "scroll",
        }}
        size="small"
        aria-label="data table"
      >
        <TableHead>
          <TableRow key="row">
            {headCells.map((cell) => (
              <StyledTableCell
                key={cell.id}
                style={{
                  maxWidth: 200,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  textAlign: cell.id === 'action' ? 'center' : 'left',
                }}
              >
                <span>{cell.name}</span>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <StyledTableCell className="ms-2" align="left">{row.documentType}</StyledTableCell>
              {row.type && row.type.length !== 0 && row.type[0] !== "" && (
                <StyledTableCell>
                  <TableBody>
                    {row.type.map((value: any, index: any) => (
                      <TableRow> {value} </TableRow>
                    ))}
                  </TableBody>
                </StyledTableCell>
              )}
              <StyledTableCell style={{ textAlign: "left" }} align="center">
                <TableBody>
                  {row.selectedFiles.map((value: any, index: any) => (
                    <TableRow> {value} </TableRow>
                  ))}
                </TableBody>
              </StyledTableCell>
              <StyledTableCell align="left">
                <TableBody>
                  {row.size.map((value: any, index: any) => (
                    <TableRow> {value} </TableRow>
                  ))}
                </TableBody>
              </StyledTableCell>
              <StyledTableCell >
                {row.size.map((value: any, index: any) => (
                  <div className="d-flex justify-content-center gap-3">
                    {
                      onViewRow &&
                      <FontAwesomeIcon
                        className="pointer mb-1"
                        icon={faEye}
                        size="lg"
                        onClick={() => {
                          onViewRow!(index, row.documentType);
                        }}
                      />
                    }
                    {
                      onDeleteRowClick &&
                      <FontAwesomeIcon
                        className="pointer mb-1"
                        icon={faTrash}
                        size="lg"
                        onClick={() => {
                          onDeleteRowClick!(index, row.documentType);
                        }}
                      />
                    }
                  </div>
                ))}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentTable;
