import GlobalHeader from "../GlobalHeader";
import { CustomTable } from "../DataTable";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { exploreOpprtunitiesheaderCells } from "../../utils/const";
import { useEffect, useState } from "react";
import { getExploreOpportunities } from "../../utils/api";
import { cityNames } from "../../utils/const";

const style = [
  { width: "100%", height: "60vh", overflowX: "hidden", overflowY: "scroll" },
];

const ExploreOpportunities = () => {

  const [exploreOpportunitiesRowData, setExploreOpportunitiesRowData] = useState<any>([]);
  const [city, setCity] = useState<string>("");

  useEffect(() => {
    getExploreOpportunities(" ", "", undefined).then((resp) => {
      const data = resp.data;
      setExploreOpportunitiesRowData(data);
    }).catch((e) => {
      console.log('e error:>> ', e);
    })
  }, []);
 
  const handleSearch = ()=>{
    getExploreOpportunities("",city,undefined)
    .then((resp:any)=>{
      const data = resp.data;
      setExploreOpportunitiesRowData(data);
    }).catch((e) => {
      console.log('e error:>> ', e);
    })
  }

  return (
    <>
      <GlobalHeader title="Explore Opportunities" isLogoVisible />
      <div className="container-fluid">
        <div className="row">
          <div className="col-3">
            <FormControl fullWidth>
              <InputLabel
                id="select-label"
                size="small"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  fontFamily: "SenticosansDTCond Thin",
                }}
              >
                Select City
              </InputLabel>
              <Select
                labelId="select-label"
                id="select"
                label="Select City"
                size="small"
                onChange={(e: any)=>{setCity(e.target.value);}}
              >
                {/* To be used in future */}
                {/* {exploreOpportunitiesRowData && exploreOpportunitiesRowData.map((row: any, index: any) => (
                  <MenuItem key={index} value={row.cityOrLocation}>
                    {row.cityOrLocation}
                  </MenuItem>
                ))} */}
                <MenuItem value=""></MenuItem>
                {cityNames.map(city=>{
                  return(
                    <MenuItem key={city} value={city}>{city}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
          <div className="col-3"></div>
          <div className="col-3"></div>
          <div className="col-3 d-flex justify-content-end">
            <Button
              className="btn col-6 col-md-6 col-sm-6 mt-1"
              style={{ backgroundColor: "gray", color: "white" }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </div>
        </div>
        <div className="row mt-3 explore-opportunity">
          <CustomTable
            headCells={exploreOpprtunitiesheaderCells}
            rows={exploreOpportunitiesRowData}
            style={style}
          />
        </div>
      </div>
    </>
  );
};

export default ExploreOpportunities;
