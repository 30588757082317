import { createAction } from "typesafe-actions";

export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const VIEW_DOCUMENT = "VIEW_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const SET_DOCUMENT = "SET_DOCUMENT";

export const uploadDocument = createAction(
  UPLOAD_DOCUMENT,
  (action) => (payload: any) => action(payload)
);

export const viewDocument = createAction(
  VIEW_DOCUMENT,
  (action) => (payload: any) => action(payload)
);

export const deleteDocument = createAction(
  DELETE_DOCUMENT,
  (action) => (payload: any) => action(payload)
);

export const setDocument = createAction(
    SET_DOCUMENT,
    (action) => (payload: any) => action(payload)
  );
