import OrganisationList from "./OrganisationList/organisationList";
import Utils from "./utils";
import EditOrganization from "./EditOrganization/editOrganization";

const ManageOrganizations = (props: any) => {
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    touched,
    isSubmit,
    onSubmit,
  } = Utils();

  const handleEditSelect = (row: any) => {
    console.log("row on edit org:", row);
    setFieldValue("orgId", row.orgId);
    setFieldValue("orgName", row.org_name);
    setFieldValue("licenseNumber", row.license_num);
    setFieldValue("laborAffiliation", row.laborAffiliation);
    setFieldValue("createdDate", row.created_date);
    setFieldValue("city", row.city);
    setFieldValue("state", row.state);
    setFieldValue("classification", row.classification);
    setFieldValue("address1", row.address1);
    setFieldValue("address2", row.address2);
    setFieldValue("zipCode", row.zipCode);
    setFieldValue("status",row.status);
  };

  return (
    <div className="d-flex" style={{width:"100vw"}}>
      {!props.isBack ? (
        <>
          <OrganisationList
            setBack={props.setBackButton}
            getSubMenu={props.getSubMenu}
            onEditSelect={handleEditSelect}
          />
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between"></div>
          {
            <EditOrganization
              setBackButton={props.setBackButton}
              isBack={props.isBack}
              values={values}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              isSubmit={isSubmit}
              onSubmit={onSubmit}
            />
          }
        </>
      )}
    </div>
  );
};

export default ManageOrganizations;
