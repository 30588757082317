import {
  faAdd,
  faClose,
  faExpand,
  faMinus,
  faRemove,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  styled
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Modal from "react-modal";
import InputField from "../InputField";
import ReactQuill from "react-quill";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectProjectId, selectThemeColor } from "../../redux/selectors/selectors";
import { PreviewModalType } from "../../utils/types";
import { getInvitePreview } from "../../utils/api";


type SendSubsInvitType = {
  inviteType: string;
  subject: string;
  emailScope: string;
  files: {
    name: string;
    files: File[];
  }[];
};

const  DefaultSendSubsInvit = {
    inviteType:"Bid Invite",
    subject:"",
    emailScope:"",
    files:[],
}

const DefaultPreviewModal = {
  recieverOrgList: [],
  sender: "",
  projectName: "",
  projectAdress: "",
  bidDateAndTime: "",
  contactPersonList: [],
  tradeList: [],
};

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  subMailsList: string[];
  handleSubmit: (sendSubsInvit: SendSubsInvitType) => void;
  showAllMails?: boolean;
  handleUpdateNewMails?: (newMail: string) => void;
  isLoading?: boolean;
  previewFormData?: PreviewModalType;
  handleClickOnPreview?: (viewMailPreview : boolean) => void;
  tradeId?: any;
}

const BidInviteModal = ({
  isOpen = false,
  handleClose,
  subMailsList = [],
  handleSubmit,
  showAllMails = false,
  handleUpdateNewMails,
  isLoading = false,
  tradeId = undefined
}: Props) => {
  const [minimize, setMinimize] = useState<boolean>(false);
  const [sendSubsInvit, setSendSubsInvit] =
    useState<SendSubsInvitType>(DefaultSendSubsInvit);
  const [sendMultiMails, setSendMultiMails] = useState<string[]>([]);
  const [addNewMails, setAddNewMails] = useState({
    isSelect: false,
    newMail: "",
  });
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [viewMailPreview, setViewMailPreview] = useState<boolean>(false);
  const [invitePreviewResponse, setInvitePreviewResponse] =
  useState<PreviewModalType>(DefaultPreviewModal);

  const color = useSelector(selectThemeColor);
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleSetAttachMent = (files:  FileList | null | File[]) => {
    let fileArray: {
      name: string;
      files: File[];
    }[] = [];

  if (files) {
    Array.from(files).forEach((file: any) => {
      fileArray.push({
        name: file.name,
        files: Array.from(files),
      });
    });
  }
  return fileArray;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    console.log("files", files);
    if (files) {
      setSendSubsInvit({
        ...sendSubsInvit,
        files: handleSetAttachMent(files)
      });
      setSelectedFiles((prevRows: any) => [...prevRows, ...handleSetAttachMent(files)]);
    }
  };

  const handleFileInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = "";
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setSendSubsInvit({
      ...sendSubsInvit,
      [name]: value,
    });
  };

  const handleRemoveSelectedFile = (index: number) => {
    let removeSelectedFiles : File[] = [...selectedFiles].filter(
      (file: File, fileIndex: number) => fileIndex !== index
    );
    setSelectedFiles(removeSelectedFiles);
    setSendSubsInvit({
      ...sendSubsInvit,
      files: handleSetAttachMent(removeSelectedFiles),
    });
    
  };

  const handleScopeChange = (content: string) => {
    setSendSubsInvit({
      ...sendSubsInvit,
      emailScope: content,
    });
  };

  const handleSelect = (event: SelectChangeEvent<typeof sendMultiMails>) => {
    const { value } = event.target;
    console.log("value", value);
    if (value) {
      setSendMultiMails(typeof value === "string" ? value.split(",") : value);
    }
  };

  const handleNewMailChange = (event: any) => {
    const { value } = event.target;
    if (value) {
      setSendMultiMails(typeof value === "string" ? value.split(",") : value);
      setAddNewMails((prevState: any) => ({
        ...prevState,
        newMail: value,
      }));
    }
  };

  const projectId = useSelector(selectProjectId);

  const handleOnClick = () => {
    if (!viewMailPreview) {
      getInvitePreview("", undefined, {projectId ,tradeId : (tradeId ? tradeId  :  null),subContractorMails:subMailsList})
        .then((resp: any) => {
          console.log('data.contactPersonList', resp.data.contactPersonList)
          if (resp.data !== null) {
            const { data }: any = resp;
            // const trade = `${node.tradeCode} ${node.name}`;
            setInvitePreviewResponse(data);
          }
        })
        .catch(() => {

        });
    }
  };

  return (
    <Modal
      id="viewInvite"
      className="position-fixed m-2"
      isOpen={isOpen}
      //   style={modalStyle}
      ariaHideApp={false}
      //   overlayClassName={overlayClass}
      style={{
        content: {
          position: "fixed",
          top: "auto",
          left: "auto",
          right: minimize ? "10px" : "0",
          bottom: minimize ? "10px" : "0",
          width: minimize ? "500px" : "500px",
          height: minimize ? "45px" : "535px",
          padding: minimize ? "0px" : "0",
          borderRadius: "10px",
          backgroundColor: "white",
          border: "1px solid #ccc",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
          transition: "width 0.3s ease, height 0.3s ease",
        },
        overlay: {
          backgroundColor: minimize ? "transparent" : "rgba(0, 0, 0, 0.7)",
          zIndex: !minimize ? 0 : 1000,
          pointerEvents: !minimize ? "auto" : "none",
          transition: "background-color 0.3s ease, opacity 0.3s ease",
          opacity: 1,
        },
      }}
    >
      <div className="modal-dialog modal-2x" style={{ width: "500px" }}>
        <div className="modal-content justify-content-center">
          <div className="modal-header row gx-0">
            <div className="col-6">
              <h5 className="opacity-70">Sub Contractor Invitations</h5>
            </div>
            <div className="col-4 offset-2">
              {isLoading ? (
                <></>
              ) : (
                <>
                  <FontAwesomeIcon
                    className="col-2 offset-4 pointer"
                    icon={minimize ? faExpand : faMinus}
                    size="xl"
                    color={color.mediumColor}
                    onClick={() => setMinimize(!minimize)}
                  />
                  <FontAwesomeIcon
                    className="col-2 pointer"
                    icon={faClose}
                    size="xl"
                    color={color.mediumColor}
                    onClick={() => {
                      setMinimize(false);
                      setSendSubsInvit(DefaultSendSubsInvit);
                      setSelectedFiles([]);
                      setAddNewMails({ isSelect: false, newMail: "" });
                      setSendMultiMails([]);
                      handleClose();
                      setViewMailPreview(false);
                    }}
                  />
                </>
              )}
            </div>
          </div>
          {!minimize &&
            (viewMailPreview ? (
              <PreviewModal
                previewFormData={invitePreviewResponse}
                fillUpFormData={{
                  subject: sendSubsInvit.subject,
                  scope: sendSubsInvit.emailScope,
                  inviteType:sendSubsInvit.inviteType
                }}
              />
            ) : (
              <div
                className="modal-body"
                style={{ width: "500px", height: "400px", overflowY: "scroll" }}
              >
                <div className="row gx-0">
                  <FormControl className="col-12">
                    <InputLabel
                      id="select-label"
                      size="small"
                      className="m-3"
                    ></InputLabel>
                    <Select
                      labelId="select-label"
                      id="status"
                      name="status"
                      defaultValue="Bid Invite"
                      className="m-3 bg-white"
                      size="small"
                      onChange={(e : any) =>   setSendSubsInvit({
                        ...sendSubsInvit,
                        inviteType: e.target.value,
                      })}
                    >
                      <MenuItem
                        className="d-block ms-2"
                        value=""
                        key={-1}
                      ></MenuItem>
                      {[
                        "Bid Invite",
                        "Addenda",
                        "Second Notice",
                        "Job Walk",
                        "Reminder",
                        "Memo",
                      ].map((status, index) => (
                        <MenuItem
                          className="d-block ms-2"
                          key={index}
                          value={status}
                        >
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="row gx-0">
                  {true ? (
                    <FormControl className="col-12">
                      <InputLabel
                        id="select-label"
                        size="small"
                        className="m-3 bg-light"
                        style={{}}
                      >
                        Invite Mail
                      </InputLabel>
                      <Select
                        multiple={showAllMails}
                        labelId="select-label"
                        id="Status"
                        name="Status"
                        label="Select mail"
                        value={
                          showAllMails
                            ? sendMultiMails.filter((mail) => mail)
                            : subMailsList
                        }
                        className="bg-white m-3"
                        size="small"
                        MenuProps={MenuProps}
                        onChange={handleSelect}
                        input={
                          addNewMails.isSelect ? (
                            <div className="row m-1 gx-0">
                              <InputField
                                className="form-control bg-white col-12"
                                value={addNewMails.newMail}
                                handleChange={handleNewMailChange}
                                handleKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    if (e.target.value) {
                                      setAddNewMails((prevState: any) => ({
                                        ...prevState,
                                        isSelect: false,
                                      }));
                                      handleUpdateNewMails!(
                                        addNewMails.newMail
                                      );
                                    }
                                  }
                                }}
                                endAdornment={
                                  <InputAdornment
                                    className="gap-2"
                                    position="end"
                                  >
                                    <FontAwesomeIcon
                                      id={addNewMails.newMail}
                                      className="pointer"
                                      icon={faAdd}
                                      size="sm"
                                      onClick={(e: any) => {
                                        console.log("first", e.target.id);
                                        if ([e.target.id]) {
                                          setAddNewMails((prevState: any) => ({
                                            ...prevState,
                                            isSelect: false,
                                          }));
                                          handleUpdateNewMails!(
                                            addNewMails.newMail
                                          );
                                        }
                                      }}
                                    />
                                    <FontAwesomeIcon
                                      className="pointer"
                                      icon={faClose}
                                      size="sm"
                                      onClick={() => {
                                        setAddNewMails((prevState: any) => ({
                                          ...prevState,
                                          newMail: "",
                                          isSelect: false,
                                        }));
                                        let filteredSendMultiMails = [
                                          ...sendMultiMails,
                                        ].filter((mail) => mail);
                                        setSendMultiMails(
                                          filteredSendMultiMails
                                        );
                                      }}
                                    />
                                  </InputAdornment>
                                }
                                id={""}
                                name={""}
                                type={""}
                                label={""}
                              />
                            </div>
                          ) : (
                            <OutlinedInput label="Status" />
                          )
                        }
                      >
                        {Array.isArray(subMailsList) &&
                          subMailsList.map((status: any, index: number) => (
                            <MenuItem
                              className="d-block ms-4 size-xl"
                              key={index}
                              value={status}
                            >
                              {status}
                            </MenuItem>
                          ))}
                        {showAllMails && (
                          <MenuItem
                            className="d-flex justify-content-end size-xl bg-white"
                            onClick={() =>
                              setAddNewMails((prevState: any) => ({
                                ...prevState,
                                isSelect: true,
                              }))
                            }
                          >
                            {/* <FontAwesomeIcon icon={faAdd} size="lg" /> */}
                            <Button
                              className="btn  p-0 mb-3 me-1"
                              style={{
                                background: color.darkColor,
                                color: "white",
                                position: "fixed",
                              }}
                            >
                              New
                            </Button>
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  ) : (
                    <div className="row m-1">
                      <InputField
                        className="form-control bg-white col-12"
                        value={addNewMails.newMail}
                        handleChange={handleNewMailChange}
                        handleKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <FontAwesomeIcon
                              icon={faAdd}
                              size="lg"
                              onClick={() => {
                                setAddNewMails((prevState: any) => ({
                                  ...prevState,
                                  isSelect: false,
                                }));
                                handleUpdateNewMails!(addNewMails.newMail);
                              }}
                            />
                          </InputAdornment>
                        }
                        id={""}
                        name={""}
                        type={""}
                        label={""}
                      />
                    </div>
                  )}
                </div>
                <div className="row m-1">
                  <InputField
                    className="form-control bg-white col-12"
                    id="subject"
                    type="text"
                    name="subject"
                    label="subject"
                    handleChange={handleChange}
                  />
                </div>
                <div className="row m-2">
                  <ReactQuill
                    className="col-12"
                    theme="snow"
                    onChange={(content) => {
                      handleScopeChange(content);
                    }}
                    placeholder="Scope....."
                  />
                </div>
                {selectedFiles.length > 0 && (
                  <div
                    className="row m-3"
                    style={{
                      height: "5rem",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      border: `3px dashed ${color.mediumColor}`,
                    }}
                  >
                    <ul className="list-group list-group-numbered m-1">
                      {selectedFiles.map((file, index) => (
                        <li
                          key={index}
                          className="list-group-item d-flex  align-items-center"
                        >
                          <span className="d-flex gap-3">
                            <span>{file.name}</span>
                            <FontAwesomeIcon
                              className="pointer mt-1"
                              icon={faRemove}
                              onClick={() => handleRemoveSelectedFile(index)}
                            />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          {!minimize && (
            <div className="footer">
              <div className="row  gx-0">
                <div className="col-6">
                  {!viewMailPreview && (
                    <Button
                      className="btn border"
                      component="label"
                      role={undefined}
                      variant="text"
                      tabIndex={-1}
                      size="small"
                      style={{ background: "transparent" }}
                    >
                      <AttachFileIcon
                        className="fs-4"
                        style={{ color: color.darkColor }}
                      />
                      Attach files
                      <VisuallyHiddenInput
                        multiple
                        type="file"
                        onChange={handleFileChange}
                        onClick={handleFileInputClick}
                      />
                    </Button>
                  )}
                </div>
                <div
                  className={
                    !viewMailPreview
                      ? " col-4 offset-2 gap-2"
                      : "col-6  d-flex justify-content-end"
                  }
                >
                  <Button
                    className="btn"
                    style={{ background: color.darkColor, color: "#fff" }}
                    onClick={() => {
                      setViewMailPreview(!viewMailPreview);
                      handleOnClick();
                    }}
                  >
                    {viewMailPreview ? "Cancel" : "Preview"}
                  </Button>
                  {!viewMailPreview && (
                    <Button
                      className="btn ms-2"
                      style={{ background: color.darkColor, color: "#fff" }}
                      onClick={() => {
                        handleSubmit(sendSubsInvit);
                        setSendSubsInvit(DefaultSendSubsInvit);
                        setSelectedFiles([]);
                      }}
                    >
                      {isLoading ? (
                        <CircularProgress
                          className="ms-1"
                          color="info"
                          variant="indeterminate"
                          thickness={2}
                          size={26}
                        />
                      ) : (
                        "Send"
                      )}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BidInviteModal;

interface PreviewProps {
  previewFormData: PreviewModalType;
  fillUpFormData:any
}

const PreviewModal = ({ previewFormData, fillUpFormData }: PreviewProps) => {

  const color = useSelector(selectThemeColor);

  return (
    <div
      className="modal-body"
      style={{ width: "500px", height: "400px", overflowY: "scroll" }}
    >
      <div className="header">
        <div className="row gx-0">
          <span className="col-2 offset-1">To:</span>
          <label className="col-7 offset-1">
            {previewFormData.recieverOrgList[0]}
          </label>
        </div>
        <div className="row gx-0">
          <span className="col-2 offset-1">From:</span>
          <label className="col-7 offset-1">
            {localStorage.getItem("orgName")}
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <h3 className="">{fillUpFormData.inviteType} to Bids</h3>
      </div>
      <div className="row gx-0">
        <span className="col-2 offset-1">Project:</span>
        <label className="col-7 offset-1">{previewFormData.projectName}</label>
      </div>
      <div className="row gx-0">
        <span className="col-2 offset-1">Message:</span>
        <label className="col-7 offset-1">1</label>
      </div>
      <div className="row gx-0">
        <span className="col-3 offset-1">Bid Date/Time</span>
        <label className="col-6">{previewFormData.bidDateAndTime}</label>
      </div>
      <div className="row gx-0">
        <span className="col-3 offset-1">Project Address:</span>
        <label className="col-6">{previewFormData.projectAdress}</label>
      </div>
      <div className="row gx-0">
        <span className="col-2 offset-1">Trade:</span>
        <div className="col-8 offset-1">
        {previewFormData.tradeList.map((tradeData) => (
          <label className="d-block size-sm">{tradeData}</label>
        ))}
        </div>
       
      </div>
      <div className="row gx-0">
        <span className="col-3 offset-1">Project Files:</span>
        <Button className="btn border col-3" style={{background: color.darkColor, color:'white'}}>Bidwizard</Button>
      </div>
      <div className="row gx-0">
        <span className="col-3 offset-1">Contact Person:</span>
        <div className="col-6">
        {previewFormData.contactPersonList.length > 0 && 
        previewFormData.contactPersonList.map((person)=>(
          <div className="d-block">
            <label htmlFor="">
              Name : {person.name}
            </label>
            <label htmlFor="">
              Email : {person.email}
            </label>
            <label htmlFor="">
              phoneNo: {person.phoneNo}
            </label>
          </div>
        ))
        }
        </div>
    
        <div className="row gx-0">
          <span className="col-2 offset-1">scope</span>
          <ReactQuill
            className="col-9"
            theme="snow"
            placeholder="Scope....."
            value={fillUpFormData.scope}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};
