import { useEffect, useState } from "react";
import {
  BidStatus,
  cityNames,
  InvitedByOtherHeaderCells,
  PostedByMeHeaderCells,
} from "../../../utils/const";
import { CustomTable } from "../../DataTable";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  getInvitedProjects,
  onChangePostProjectStatus,
  projectSearchByStatus,
  updateBidStatus,
  updateStatusForAllBids,
} from "../../../utils/api";
import Split from "react-split";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../../redux/selectors/selectors";
import InputField from "../../InputField";
import { PostProjectEnum } from "../../../utils/enum";
import { useDispatch } from "react-redux";
import { SearchFilterInvitedType } from "../../../utils/types";
import CustomizedSnackbar from "../../CustomizedSnackbar";

interface Props {
  onSelectProjectRow: (row: any) => void;
  bidId: number;
  onSelectCell: (cell: any) => void;
}

const style = [
  { width: "100%", height: "65vh", overflowY: "scroll", overflowX: "scroll" },
];

const MyBidsTable = ({ onSelectProjectRow, bidId, onSelectCell }: Props) => {
  const color = useSelector(selectThemeColor);

  const onSelectRow = (row: any, actionType: string, checked: boolean) => {
    onSelectProjectRow(row);
  };

  return (
    <div className="overflow-hidden">
      <div
        className="row"
        style={{
          height: "75vh",
        }}
      >
        <Split
          sizes={[50, 50]}
          minSize={[150, 150]}
          expandToMin={false}
          gutterSize={5}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
          className="d-flex h-100 overflow-hidden"
          gutter={(gutter) => {
            const gutterElement = document.createElement("div");
            gutterElement.className = "bg-secondary split-bar";
            return gutterElement;
          }}
        >
          <div className="overflowY-auto border">
            <div className="scrollable-pane">
              <PostedByMeTable
                onSelectRow={onSelectRow}
                onSelectCell={onSelectCell}
              />
            </div>
          </div>
          <div className="overflowY-auto border">
            <div className="scrollable-pane">
              <InvitedByOtherTable
                onSelectRow={onSelectRow}
                onSelectCell={onSelectCell}
                bidId={bidId}
              />
            </div>
          </div>
        </Split>
      </div>
    </div>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface MyBidsProps {
  onSelectRow: (row: any, actionType: string, checked: boolean) => void;
  onSelectCell: (cell: any) => void;
}
const PostedByMeTable = (props: MyBidsProps) => {
  const [postRowData, setPostRowData] = useState<any>([]);
  const [postMultiStatus, setPostMultiStatus] = useState<string[]>([
    PostProjectEnum.ACTIVELY_BIDDING,
  ]);

  const color = useSelector(selectThemeColor);
  const dispatch = useDispatch();

  useEffect(() => {
    const body = {
      postStatus: [PostProjectEnum.ACTIVELY_BIDDING],
    };
    projectSearchByStatus(" ", undefined, body)
      .then((resp) => {
        if (resp.data === null) {
          setPostRowData([]);
        } else {
          const data = resp.data;
          console.log("resp.data", resp.data);
          setPostRowData(resp.data);
        }
      })
      .catch((e) => {
        console.log("e error:>> ", e);
        setPostRowData([]);
      });
  }, []);

  const handleGetPostProjectList = (multiStatus: string[]) => {
    const body = {
      postStatus: multiStatus,
    };
    projectSearchByStatus("", undefined, body)
      .then((response: any) => {
        console.log(response.data);
        if (response.data === null) {
          setPostRowData([]);
        } else {
          setPostRowData(response.data);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const handleChange = (event: SelectChangeEvent<typeof postMultiStatus>) => {
    console.log("event", event);
    const { value } = event.target;
    setPostMultiStatus(typeof value === "string" ? value.split(",") : value);
    handleGetPostProjectList(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handlePostStatusChange = (projectId: number, status: string) => {
    onChangePostProjectStatus("", projectId, { status })
      .then((response) => {
        if (response.status === "OK") {
          handleGetPostProjectList(postMultiStatus);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <div className="d-flex align-items-center mt-3 mb-3">
        <div className="col-6 text-truncate p-1 ms-1">Posted By Me</div>
        <FormControl className="col-5 d-flex justify-content-end">
          <InputLabel
            id="select-label"
            size="small"
            className="my-1"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "14px",
            }}
          >
            Select Status
          </InputLabel>
          <Select
            multiple
            labelId="select-label"
            id="Status"
            name="Status"
            label="Select Status"
            value={postMultiStatus}
            className="bg-white w-auto"
            size="small"
            MenuProps={MenuProps}
            onChange={handleChange}
            input={<OutlinedInput label="Status" />}
          >
            {BidStatus.map((status: any, index: number) => (
              <MenuItem className="d-block ms-3" key={index} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="px-1">
        <CustomTable
          headCells={PostedByMeHeaderCells}
          rows={postRowData}
          style={style}
          onSelectRowClick={props.onSelectRow}
          onClickGetCell={props.onSelectCell}
          handleChangeDropDownStatus={handlePostStatusChange}
        />
      </div>
    </>
  );
};

interface PropsInvited extends MyBidsProps {
  bidId: number;
}

const InvitedByOtherTable = (props: PropsInvited) => {
  const color = useSelector(selectThemeColor);
  const [inviteRowData, setInviteRowData] = useState<any>([]);
  const [showSnackBar, setShowsnackBar] = useState(false);
  const [message, setMessage] = useState("");
  const [refresh, setRefresh] = useState<boolean>(false);
  const [search, setSearch] = useState<SearchFilterInvitedType>({
    projectName: "",
    status: "",
    selectCity: "",
    orgName: "",
  });

  useEffect(() => {
    console.log("render");
    getInvitedProjects(" ", undefined, undefined)
      .then((response) => {
        if (response.data === null) {
          setInviteRowData([]);
        } else {
          const data = response.data;
          console.log("Invited data ", data);
          setInviteRowData(data);
        }
      })
      .catch((e) => {
        console.log("e error:>> ", e);
        setInviteRowData([]);
      });
  }, [refresh]);

  //To be used afterwards
  // const handleChangeInvitedStatus = (projectId: number, newStatus: string) => {
  //   console.log(newStatus);
  //   const status = newStatus.replace(/^"|"$/g, "");
  //   console.log(status);
  //   updateStatusForAllBids("", projectId, status)
  //     .then((response) => {
  //       if (response.status === "OK") {
  //         setRefresh(true);
              // setMessage(response.message);
              // setShowsnackBar(true);
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.log("error :>> ", error);
  //     });
  // };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    console.log("name", name);
    console.log("value", value);
    setSearch({
      ...search,
      [name]: value,
    });
    console.log("search", search);
  };
  const handleSearchByFilterInInvited = () => {
    const { projectName, status, selectCity, orgName } = search;
    console.log("value for filter=> ", search);
    const body = {
      projectName: projectName,
      status: status,
      city: selectCity,
      orgName: orgName.trim(),
    };
    getInvitedProjects("", undefined, body)
      .then((response: any) => {
        console.log(response.data);
        if (response.data === null) {
          setInviteRowData([]);
        } else {
          setInviteRowData(response.data);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="d-flex align-items-center mt-2">
        <div className="col-2 text-truncate p-1">Invited By Others</div>
        <div className="col-10">
          <FormControl className="col-3 p-1 ms-3">
            <InputField
              id="projectName"
              name="projectName"
              type="text"
              className="form-control"
              label="Project Name"
              handleChange={handleChange}
            />
          </FormControl>
{/* //To be used afterwards */}
          {/* <FormControl className="col-2 p-1 ms-1">
            <InputLabel
              id="select-label"
              size="small"
              className="my-1"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "14px",
                fontFamily: "SenticosansDTCond Thin",
              }}
            >
              Select Status
            </InputLabel>
            <Select
              labelId="select-label"
              id="status"
              name="status"
              defaultValue=""
              label="Select Status"
              className="bg-white"
              size="small"
              onChange={handleChange}
            >
              <MenuItem className="d-block ms-2" value="" key={-1}></MenuItem>
              {["Accepted", "Rejected", "May Be", "Invited"].map(
                (status, index) => (
                  <MenuItem className="d-block ms-2" key={index} value={status}>
                    {status}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl> */}
          <FormControl className="col-3 p-1 ms-1">
            <InputLabel
              id="select-label"
              size="small"
              className="my-1"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "14px",
                fontFamily: "SenticosansDTCond Thin",
              }}
            >
              Select City
            </InputLabel>
            <Select
              labelId="select-city"
              id="selectCity"
              name="selectCity"
              defaultValue=""
              label="Select City"
              className="bg-white"
              size="small"
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 200, 
                    overflowY: 'auto',
                  }}
              }}
            >
 <MenuItem className="d-block ms-2" value="" style={{ height: '10px'}}>&nbsp;</MenuItem>  
 {cityNames.map((city, index: number) => (
                <MenuItem className="d-block ms-2" key={index} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className="col-3 p-1 ms-1">
            <InputField
              id="orgName"
              name="orgName"
              type="text"
              className="form-control"
              label="Organization Name"
              handleChange={handleChange}
            />
          </FormControl>
          <Button
            className="btn col-2 mt-1 ms-3"
            style={{ backgroundColor: color.darkColor, color: "white" }}
            onClick={() => handleSearchByFilterInInvited()}
          >
            Search
          </Button>
        </div>
      </div>
      <div>
        <div className="px-1">
          <CustomTable
            headCells={InvitedByOtherHeaderCells}
            rows={inviteRowData}
            style={style}
            onSelectRowClick={props.onSelectRow}
            onClickGetCell={props.onSelectCell}
            //handleChangeDropDownStatus={handleChangeInvitedStatus}
          />
        </div>
        <CustomizedSnackbar
          openSnackBar={showSnackBar}
          message={message}
          handCloseSnackBar={() => setShowsnackBar(false)}
        />
      </div>
    </>
  );
};

export default MyBidsTable;
