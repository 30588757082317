import { useFormik } from "formik";
import { InvitedBidDetailForm } from "../../../utils/types";
import { useState } from "react";

export const DefaultFormData:InvitedBidDetailForm = {
    projectName: "",
    projectNumber: "",
    projectStatus: "",
    projectContact: "",
    buildingSize: "",
    siteSize: "",
    buildingCount: 0,
    storyCount: 0,
    dollarSize: "",
    duration: "",
    onlineAccess: "",
    buildingType: "",
    bidType: "",
    jobWalkDate: "",
    jobWalkTime: "",
    bidDate: "",
    bidTime: "",
    address1: "",
    state: "",
    zipCode: "",
    city: "",
    constructionType: "",
    budget: "",
    sector: "",
    scope: "",
    bidPostingDate: "",
    remainingDays: 0,
  };
  
  interface Props {
    props: any;
    bidSpecification: any;
    projectFormData: any;
    projectDocuments: any;
    tradeList: any;
  }
  
  const Utils = () => {
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const InitialValues: InvitedBidDetailForm = {
      projectName: "",
      projectNumber: "",
      buildingSize: "",
      projectStatus: "",
      projectContact: "",
      siteSize: "",
      buildingCount: 0,
      storyCount: 0,
      dollarSize: "",
      duration: "",
      onlineAccess: "",
      buildingType: "",
      bidType: "",
      jobWalkDate: "",
      jobWalkTime: "",
      bidDate: "",
      bidTime: "",
      address1: "",
      state: "",
      zipCode: "",
      city: "",
      constructionType: "",
      budget: "",
      sector: "",
      scope: "",
      bidPostingDate: "",
      remainingDays: 0,
    };

    const onSubmit = () => { }

  const { values, errors, handleChange, handleSubmit, handleReset, setFieldValue, setFieldError, touched, setTouched, setFieldTouched, isValid } = useFormik({
    initialValues: { ...InitialValues },
    onSubmit
  })

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    touched,
    setTouched,
    setFieldTouched,
    isSubmit,
    isValid,
  }
};

export default Utils;
  