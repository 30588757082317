import { createTheme } from "@mui/material";
import { blue, pink, purple } from "@mui/material/colors";

export const CustomeTheme = createTheme({
  palette: {
    secondary: {
      light: pink[300],
      main: "#19255c",
      dark: pink[700],
    },
  },
  typography: {
    fontFamily: `SenticosansDTCond Thin`,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "15px !important",
          opacity: 0.7,
          fontFamily: `SenticosansDTCond Thin`,
          cursor:'pointer'
        },
      },
    },
    MuiFormControlLabel:{
      styleOverrides:{
        label: {
          fontSize: '0.8rem',
          fontWeight: 500, 
        },
      }
    },
    MuiBadge:{
      styleOverrides:{
        root: {
          opacity:0.5
        },
      }
    },
  },
  
});
