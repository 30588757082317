import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomizedSnackbar from '../CustomizedSnackbar';
import { getUserDetails } from '../../utils/api';
import InputField from '../InputField';

const ProfileDetail = (props: any) => {

  const [message, setMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  useEffect(() => {
      getUserDetails(" ", undefined, undefined)
      .then((resp) => {
        setData(resp.data);
      })
      .catch((error) => {
        console.log("e error:>> ", error);
      })
  }, []);

  console.log('data :>> ', data);

  return (
    <>
      <>
        <div className="overflow-hidden">
          <div className="d-grid" style={{ backgroundColor: "#f3f6f7" }}>
            {
              data ? (
                <div
                className="container w-90 overflow-y-scroll"
                style={{ height: "50vh" }}
              >
                <div className="row justify-content-center align-items-center mt-3">
                  <div className="form-floating col-3 col-md-4 col-sm-4">
                    <div>Organization Name:</div>
                  </div>
                  <div className="form-floating col-9 col-md-4 col-sm-4">
                    <InputField
                      id="constructionType"
                      name="contsructionType"
                      type="text"
                      value={data.organisation}
                      className="form-control"
                      label=""
                      readonly={true}
                    />
                  </div>
                  </div>
                <div className="row justify-content-center align-items-center mt-2">
                  <div className="form-floating col-3 col-md-4 col-sm-4">
                    <div>User Name:</div>
                  </div>
                  <div className="form-floating col-9 col-md-4 col-sm-4">
                    <InputField
                      id="constructionType"
                      name="contsructionType"
                      type="text"
                      value={data.firstName +" "+ data.lastName}
                      className="form-control text-black"
                      label=""
                      readonly={true}
                    />
                  </div>
                </div>
                <div className="row justify-content-start align-items-center mt-3">
                  <div className="form-floating col-3 col-md-4 col-sm-4">
                    <div>E-mail:</div>
                  </div>
                  <div className="form-floating col-9 col-md-4 col-sm-4">
                  <InputField
                      id="constructionType"
                      name="contsructionType"
                      type="text"
                      value={data.email}
                      className="form-control"
                      label=""
                      readonly={true}
                    />                  </div>
                </div>
                <div className="row justify-content-start align-items-center mt-3">
                  <div className="form-floating col-3 col-md-4 col-sm-4">
                    <div>Mobile:</div>
                  </div>
                  <div className="form-floating col-3 col-md-4 col-sm-4">
                  <InputField
                      id="constructionType"
                      name="contsructionType"
                      type="text"
                      value={data.mobileNo.replace(/\D/g, "")
                        .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}
                      className="form-control"
                      label=""
                      readonly={true}
                    />                  
                  </div>
                  <div className="form-floating col-1 col-md-4 col-sm-4">
                    <div>Role:</div>
                  </div>
                  <div className="form-floating col-3 col-md-4 col-sm-4">
                    <InputField
                      id="constructionType"
                      name="contsructionType"
                      type="text"
                      value={data.role}
                      className="form-control"
                      label=""
                      readonly={true}
                    />
                  </div>
                </div>
                <div className='row'>
                </div>
                <div className="row justify-content-start align-items-center mt-3">
                <div className="form-floating col-3 col-md-4 col-sm-4">
                    <div>Status:</div>
                  </div>
                  <div className="form-floating col-3 col-md-4 col-sm-4">
                    <InputField
                      id="constructionType"
                      name="contsructionType"
                      type="text"
                      value={data.status==='A'? "Active" : "InActive"}
                      className="form-control"
                      label=""
                      readonly={true}
                    />
                  </div>
                  </div>
              </div>
              ) : (
                <div></div>
              )
            }
          </div>
          <div className="row offset-5 mt-4">

          </div>
        </div>
        <CustomizedSnackbar
          openSnackBar={isOpen}
          message={message}
          handCloseSnackBar={() => {
            setIsOpen(false);
          }}
        />
      </>
    </>
  )
}

export default ProfileDetail;