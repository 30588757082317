import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { FormControl, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useContext, useEffect, useState } from "react";
import { getDivisionList, saveDivisionAndSpecification, uploadTradesCSV } from "../../utils/api";
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem2, TreeItem2Label } from "@mui/x-tree-view/TreeItem2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faCirclePlus, faAdd, faClose } from "@fortawesome/free-solid-svg-icons";
import InputField from "../InputField";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import CustomizedSnackbar from "../CustomizedSnackbar";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../redux/selectors/selectors";
import Loader from "../Loader";
import { error } from "console";
import Modal from "react-modal";
import { CustomTable } from "../DataTable";
import { submitTradeHeaderCells } from "../../utils/const";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type divisionType = {
  id: any;
  name: string;
  parenttrade: any;
  tradeCode: string;
};

type addDivisionType ={
  addDivisionList:divisionType[];
  editDivisionList:{ id: any;
    name: string;}[];
  deletedIdsList: any[];
}

const defaultDivision = {
  addDivisionList:[],
  editDivisionList:[],
  deletedIdsList:[]

}

type SubmitRow = {
  id:number;
  tradeCode: string;
  name: string;
  actionTaken: string;
  action: string;
}

const ManageDivisions = () => {
  const [nodes, setNodes] = useState<any>();
  const [initialNodes, setinitialNodes] = useState<any>([]);
  const [searchItem, setSearchItem] = useState("");
  const [subDivisionList, setSubDivisionList] = useState<any[]>();
  const [open, setOpen] = React.useState(false);
  const [dialogType, setDialogType] = useState("");
  const [divisionDialog, setDivisionDialog] = useState("");
  const [selectedDivision, setSelectedDivision] = useState<any>();
  const [maxId, setMaxId] = useState<any>();
  const [divisionName, setDivisionName] = useState<string>("");
  const [tradeCode, setTradeCode] = useState<string>("");
  const [saveTrades, setSaveTrades] = useState<addDivisionType>(defaultDivision)
  const [listForTree, setListForTree] = useState<any>([]);
  const [selectedDivAndSpecification, setSelectedDivAndSpecification] = useState<any>();
  const [deletedDivisionList, setDeletedDivisionList] = useState<any>([]);
  const [selectedParentId, setSelectedParentId] = useState<number | null>(null);
  const [message, setmessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [submitRowData, setSubmitRowData] = useState<SubmitRow[]>([]);

  const color = useSelector(selectThemeColor);

  const handleOpen = (type: string, label: any, item: any) => {
    console.log('isLoading', isLoading)
    if(isLoading){
      setOpen(true);
      setDialogType(type);
      setDivisionDialog(label);
      setSelectedDivAndSpecification(item);
    }
  };

  const resetToInitialdNodes = () => setNodes(initialNodes);

  const searchToFilteredNodes = (searchNodes: any, query: string) => {
    if (!query || query === "") {
      resetToInitialdNodes();
    } else {
      const filteredNodes = searchNodes.filter((item: any) => {
        const found: boolean = item.name.toLowerCase().includes(query.toLowerCase()) || item.tradeCode.toLowerCase().includes(query.toLowerCase());
        if (!found && item.children) {
          const childrenFound = searchToFilteredNodes(item.subDivisionList, query);
          return childrenFound;
        }
        return found;
      });
      setNodes(filteredNodes);
    }
  };

  const findDivisionById = (divisions: any, id: any, oprationType: any) => {
    for (const division of divisions) {
      if (division.id === id) {
        if (oprationType === "delete") {
          for (let data of division.subDivisionList) {
            if (
              data.id === selectedDivAndSpecification.id &&
              data.subDivisionList
            ) {
              findDeletedDivisionChildren(data.subDivisionList, id);
            }
          }
        }
        return division;
      } else if (division.subDivisionList) {
        const foundDivision: any = findDivisionById(division.subDivisionList, id, oprationType);
        if (foundDivision) {
          return foundDivision;
        }
      }
    }
    return undefined;
  };

  const findDeletedDivisionChildren = (divisions: any, id: number) => {
    for (const division of divisions) {
      const tempDeletedDivisionList = deletedDivisionList;
      tempDeletedDivisionList.push(division.id);
      setDeletedDivisionList(tempDeletedDivisionList);
      if (division.id === id) {
        return division;
      } else if (division.subDivisionList) {
        const foundDivision: any = findDeletedDivisionChildren(division.subDivisionList, id);
        if (foundDivision) {
          return foundDivision;
        }
      }
    }
    return undefined;
  };

  const handleClose = (type: string | null,isDraft: boolean) => {
    let tradeLists: addDivisionType = defaultDivision;
    setOpen(false);
    switch (type) {
      case "Add":
        debugger
        if (!nodes) {
          setNodes([
            {
              id: maxId + 1,
              name: divisionName,
              parenttrade: null,
              tradeCode: tradeCode,
              subDivisionList: []
            }
          ]);
          setinitialNodes([
            {
              id: maxId + 1,
              name: divisionName,
              parenttrade: null,
              tradeCode: tradeCode,
              subDivisionList: []
            }
          ]);

          setMaxId((prev: any) => prev + 1);
          let addDivisionList = [{
            id: maxId + 1,
            name: divisionName,
            parenttrade: null,
            tradeCode: tradeCode,
            subDivisionList: [],
          }]
          if(isDraft){
            setSaveTrades({
            ...saveTrades,
            addDivisionList,
          });} else {tradeLists.addDivisionList=addDivisionList;}
          break;
        }

        if (selectedDivAndSpecification != null) {
          let nodes2 = nodes;
          const division = findDivisionById(nodes2, selectedDivAndSpecification.id, "add");

          if (division) {
            if (division.subDivisionList === null) {
              division.subDivisionList = [];
            }
            division.subDivisionList.push({
              id: maxId + 1,
              name: divisionName,
              parenttrade: selectedDivAndSpecification.id,
              tradeCode: tradeCode,
              subDivisionList: null
            });
          } else {
            console.error("Division with name" + selectedDivAndSpecification.label + " not found");
          }
          let addDivisionList = [ ...saveTrades.addDivisionList,   {
            id: maxId + 1,
            name: divisionName,
            parenttrade: selectedDivAndSpecification.id,
            subDivisionList: [],
            tradeCode: tradeCode
          }]
          if(isDraft){
            setSubmitRowData(prevList => [
              ...prevList,
               {
                  id: maxId + 1,
                  tradeCode: tradeCode,
                  name: divisionName,
                  actionTaken: "Added",
                  action: "",
               }
            ]);
            setSaveTrades({
            ...saveTrades,
            addDivisionList,
          });} else {tradeLists.addDivisionList=addDivisionList;}
          setMaxId((prev: any) => prev + 1);

          setNodes(nodes2);
          setinitialNodes(nodes2);
          nodes2?.forEach((division: any) => {
            if (division.id === selectedParentId) {
              const data = structuredData(division.subDivisionList);
              setSubDivisionList(data);
            }
          }); 
        } else {
          setNodes([...nodes,
            {
              id: maxId + 1,
              name: divisionName,
              parenttrade: null,
              tradeCode: tradeCode,
              subDivisionList: []
            }
          ]);
          setinitialNodes([...nodes,
            {
              id: maxId + 1,
              name: divisionName,
              parenttrade: null,
              tradeCode: tradeCode,
              subDivisionList: []
            }
          ]);
          let addDivisionList = [  {
            id: maxId + 1,
            name: divisionName,
            parenttrade: null,
            tradeCode: tradeCode,
            subDivisionList: []
          }
          ]
          if(isDraft){
            setSubmitRowData(prevList => [
              ...prevList,
               {
                  id: maxId + 1,
                  tradeCode: tradeCode,
                  name: divisionName,
                  actionTaken: "Added",
                  action: "",
               }
            ]);
            setSaveTrades({
            ...saveTrades,
            addDivisionList,
          });} else {tradeLists.addDivisionList=addDivisionList;}
          setMaxId((prev: any) => prev + 1);
        }
        if(!isDraft){
        saveDivision(tradeLists);
        tradeLists=defaultDivision;
        setmessage("Trade added Successfully!");
        setIsOpen(true);
        } else {
          tradeLists=defaultDivision;
          setmessage("Saved as draft");
          setIsOpen(true);
        }
        break;

      case "Edit":
        debugger
        let nodes3 = nodes;
        const division2 = findDivisionById(nodes3, selectedDivAndSpecification.id, "edit");
        if (division2) {
          division2.name = divisionName;
        } else {
          console.error("Division with name" + divisionName + " not found");
        }
        let editDivisionList = {...saveTrades.editDivisionList}
        for (let division of listForTree) {
          if (division.id === selectedDivAndSpecification.id) {
            division.name = divisionName;
            editDivisionList = [
              {
                id:division.id,
                name: division.name,
              },
            ];
            break;
          }
        }
        setNodes(nodes3);
        setinitialNodes(nodes3);
        nodes3?.forEach((division: any) => {
          if (division.id === selectedParentId) {
            const data = structuredData(division.subDivisionList);
            setSubDivisionList(data);
          }
        });
        if(isDraft){
          setSubmitRowData(prevList => [
            ...prevList,
             {
                id: selectedDivAndSpecification.id,
                tradeCode: selectedDivAndSpecification.tradeCode,
                name: selectedDivAndSpecification.label,
                actionTaken: "Edited",
                action: "",
             }
          ]);
        setSaveTrades({
          ...saveTrades,
          editDivisionList,
        });} else {tradeLists.editDivisionList=editDivisionList}
        if(!isDraft){
          saveDivision(tradeLists);
          tradeLists=defaultDivision;
          setmessage("Trade edited Successfully!");
          setIsOpen(true);
          } else {
            tradeLists=defaultDivision;
            setmessage("Saved as draft");
            setIsOpen(true);
          }
        break;

      case "Delete":
        let nodes4 = nodes;
        if (selectedDivAndSpecification.parenttrade !== null) {
          const division2 = findDivisionById(nodes4, selectedDivAndSpecification.parenttrade, "delete");
          if (division2) {
            division2.subDivisionList = division2.subDivisionList.filter(
              (div: any) => div.id !== selectedDivAndSpecification.id
            );
          } else {
            console.error("Division with name" + selectedDivAndSpecification.label + " not found");
          }
        } else {
          nodes4 = nodes4.filter(
            (div: any) => div.id !== selectedDivAndSpecification.id
          );
        }
        console.log(selectedDivAndSpecification,"sel");
        let listForTree3 = listForTree;
        const tempDeletedDivisionList = deletedDivisionList;
        tempDeletedDivisionList.push(selectedDivAndSpecification.id);
        for (let id of tempDeletedDivisionList) {
          listForTree3 = listForTree3.filter((div: any) => div.id !== id);
        }
        setListForTree(listForTree3);
        setDeletedDivisionList(tempDeletedDivisionList);
        if(isDraft){
          setSubmitRowData(prevList => [
            ...prevList,
             {
                id: selectedDivAndSpecification.id,
                tradeCode: selectedDivAndSpecification.tradeCode,
                name: selectedDivAndSpecification.label,
                actionTaken: "Deleted",
                action: "",
             }
          ]);
        setSaveTrades({
          ...saveTrades,
          deletedIdsList:tempDeletedDivisionList,
        });
        setmessage("Added to Recycle Bin");
        setIsOpen(true);
      } else {
          tradeLists.deletedIdsList=tempDeletedDivisionList
          saveDivision(tradeLists);
          setmessage("Trade deleted Successfully!");
          setIsOpen(true);
        }
        tradeLists=defaultDivision;
        setNodes(nodes4);
        setinitialNodes(nodes4);
        nodes4?.forEach((division: any) => {
          if (division.id === selectedParentId) {
            const data = structuredData(division.subDivisionList);
            setSubDivisionList(data);
          }
        });
        break;

      default:
        break;
    }
    setDivisionName("");
    setTradeCode("");
  };

  useEffect(() => {
    setLoadingLabel("Fetching Trades");
    getDivisionList("",undefined, undefined).then((resp) => {
      setIsLoading(true);
      const data = resp.data.ListForTree;
      const ListForTree = resp.data.DivisionList;
      const MaxId = resp.data.maxId;

      setNodes(data);
      setinitialNodes(data);
      setMaxId(MaxId);
      setListForTree(ListForTree);
    }).catch ((e) => {
      setNodes([]);
      setinitialNodes([]);
      setMaxId(null);
      setListForTree([]);
    }) 
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (nodes && nodes?.length) handleDivisionClick(nodes[0].id);
  }, [nodes, setNodes]);

  const structuredData = (data: any) => {
    return (data && data.map((item: any) => ({
        id: item.id,
        label: item.name,
        parenttrade: item.parenttrade,
        tradeCode: item.tradeCode,
        children: item.subDivisionList
          ? getChildrenList(item.subDivisionList)
          : []
      }))
    );
  };

  const getChildrenList: any = (items: any[]) => {
    return items.map((item) => ({
      id: item.id,
      label: item.name,
      parenttrade: item.parenttrade,
      tradeCode: item.tradeCode,
      children: item.subDivisionList
        ? getChildrenList(item.subDivisionList)
        : []
    }));
  };

  const handleRemove = (id : number, row:any) =>{
    console.log(row,"removed row");
    if(row.tradeAction === "Added"){
      let removedAdded = [...saveTrades.addDivisionList].filter(
        (division : any)=> division.id !== id
      );
      setSaveTrades(
        prevState => ({
          ...prevState,
          addDivisionList : removedAdded
        })
      );
      let rowswithoutRemoved = [...submitRowData].filter((division:any)=> division.tradeCode !== row.tradeCode );
      setSubmitRowData(rowswithoutRemoved);
      if(rowswithoutRemoved.length === 0) { setSubmitModalOpen(false);} 
    }else if(row.tradeAction === "Edited"){
      let removedEdited = [...saveTrades.editDivisionList].filter(
        (division : any)=> division.id !== id
      );
      setSaveTrades(
        prevState => ({
          ...prevState,
          editDivisionList : removedEdited
        })
      );
      let rowswithoutRemoved = [...submitRowData].filter((division:any)=> division.tradeCode !== row.tradeCode );
      setSubmitRowData(rowswithoutRemoved);
      if(rowswithoutRemoved.length === 0) { setSubmitModalOpen(false);}
    } else{
      let removed = [...saveTrades.deletedIdsList].filter(
        (division : any)=> division.id !== id
      );
      setSaveTrades(
        prevState => ({
          ...prevState,
          deletedIdsList : removed
        })
      );
      let rowswithoutRemoved = [...submitRowData].filter((division:any)=> division.tradeCode !== row.tradeCode );
      setSubmitRowData(rowswithoutRemoved);
      if(rowswithoutRemoved.length === 0) { setSubmitModalOpen(false);}
    }  
  }

  const handleClearAll = () =>{
    setSubmitRowData([]);
    setSaveTrades(defaultDivision);
    setSubmitModalOpen(false);
    setLoadingLabel("Fetching Trades");
    getDivisionList("",undefined, undefined).then((resp) => {
      setIsLoading(true);
      const data = resp.data.ListForTree;
      const ListForTree = resp.data.DivisionList;
      const MaxId = resp.data.maxId;

      setNodes(data);
      setinitialNodes(data);
      setMaxId(MaxId);
      setListForTree(ListForTree);
    }).catch ((e) => {
      setNodes([]);
      setinitialNodes([]);
      setMaxId(null);
      setListForTree([]);
    }) 
    setIsLoading(false);
    
  }

  const saveDivision =  (tradeLists:addDivisionType) => {
    console.log(tradeLists,"submit data");
    const saveTrades:addDivisionType ={
      addDivisionList:tradeLists.addDivisionList,
      editDivisionList:tradeLists.editDivisionList,
      deletedIdsList:tradeLists.deletedIdsList,
    }
    saveDivisionAndSpecification("", undefined, saveTrades).then((response:any)=>{
      if(response.status === 'Ok')
      {
        setmessage("Submitted All successfully!");
        setIsOpen(true);
      }
      else{
        setmessage(response.message);
        setIsOpen(true);
      }
    }).catch((error : any)=>{
      setmessage(error.message);
      setIsOpen(true);
    }).finally(()=>{
      // setSaveTrades(defaultDivision);
    });
  };

  const [showClearIcon, setShowClearIcon] = useState("none");

  function CustomLabel(props: any) {
  const { tooltip, labelProps, ...other } = props;

    return (
      <div className="row col-12 border-bottom gx-0 d-flex mt-3">
        <span className="col-2 mt-1 p-0 text-start">{labelProps.tradeCode}</span>
        <div className="col-7 p-0 text-start">
          <TreeItem2Label {...props} />
        </div>
        <div className="col-3 d-flex justify-content-end">
          <FontAwesomeIcon
            className="m-1 p-1 size-sm"
            icon={faAdd}
            id={labelProps.itemId}
            onClick={() => handleOpen("Add", labelProps.label, labelProps)}
            size="1x"
          />
          <FontAwesomeIcon
            className="m-1 p-1 size-sm"
            icon={faEdit}
            id={labelProps.itemId}
            onClick={() => handleOpen("Edit", labelProps.label, labelProps)}
            size="1x"
          />
          <FontAwesomeIcon
            className="m-1 p-1 size-sm"
            id={labelProps.itemId}
            onClick={() => handleOpen("Delete", labelProps.label, labelProps)}
            icon={faTrash}
            size="1x"
          />
        </div>
      </div>
    );
  }

  const CustomTreeItem2 = React.forwardRef(
    (props: any, ref: React.Ref<HTMLLIElement>) => {
      const { itemId, ...other } = props;
      const { publicAPI } = useTreeItem2({itemId, rootRef: ref});
      const itemData = (publicAPI as any).getItem(itemId);
      return (
        <TreeItem2
          {...props}
          className="size-sm"
          ref={ref}
          slots={{ label: CustomLabel }}
          slotProps={{
            label: {
              labelProps: {
                ...other,
                id: itemId,
                itemId,
                parenttrade: itemData.parenttrade,
                tradeCode: itemData.tradeCode,
              }
            }
          }}
        />
      );
    }
  );

  const handleDivisionClick = (id: any) => {
    setSelectedParentId(id);
    nodes?.forEach((division: any) => {
      if (division.id === id) {
        const data = structuredData(division.subDivisionList);
        setSubDivisionList(data);
        setSelectedDivision(division);
      }
    });
  };

  const handleFileChange = (event: any) => {
    setLoadingLabel("Uploading Trades")
    const { files } = event.target;
    const formData = new FormData();
    formData.append(
      "file",
      new Blob(files, { type: files[0].type }),
      files[0].name
    );
    uploadTradesCSV("", undefined, formData)
      .then((response) => {
        setIsLoading(true);
        if (response.status === "OK") {
          getDivisionList("", undefined, undefined)
            .then((resp) => {
              setLoadingLabel("Fetching Trades");
              const data = resp.data.ListForTree;
              const ListForTree = resp.data.DivisionList;
              const MaxId = resp.data.maxId;

              setNodes(data);
              setinitialNodes(data);
              setMaxId(MaxId);
              setListForTree(ListForTree);
            })
            .catch((e) => {
              setNodes([]);
              setinitialNodes([]);
              setMaxId(null);
              setListForTree([]);
            });
        }
      })
      .catch((error) => {
        console.error("Error creating project:", error);
      })
      .finally(() => {
        getDivisionList("", undefined, undefined)
          .then((resp) => {
            setLoadingLabel("Fetching Trades");
            const data = resp.data.ListForTree;
            const ListForTree = resp.data.DivisionList;
            const MaxId = resp.data.maxId;

            setNodes(data);
            setinitialNodes(data);
            setMaxId(MaxId);
            setListForTree(ListForTree);
          })
          .catch((e) => {
            setNodes([]);
            setinitialNodes([]);
            setMaxId(null);
            setListForTree([]);
          });
      });
      setIsLoading(false);
      event.target.value=""
  };

  return (
    <div className="card bg-light d-flex" 
    style={{height:"80vh"}}
    >
      <div className="row mt-4 p-2 d-felx" style={{height:"100vh"}}>
        <div className="col-4">
          <div className="d-flex">
            <FormControl className="search-box col-9">
              <TextField
                size="small"
                variant="outlined"
                disabled={!isLoading}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => {
                  setShowClearIcon(event.target.value === "" ? "none" : "flex");
                  setSearchItem((prev) => event.target.value);
                  searchToFilteredNodes(nodes, event.target.value);
                }}
                value={searchItem}
                placeholder="Search Trade"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      className="pointer"
                      position="end"
                      style={{ display: showClearIcon }}
                      onClick={() => {
                        resetToInitialdNodes();
                        setSearchItem("");
                        setShowClearIcon("none");
                      }}
                    >
                      <ClearIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Tooltip
              title={
                <React.Fragment>
                  <div className="tooltip-title">
                    <h6>Add Division</h6>
                  </div>
                </React.Fragment>
              }
            >
              <FontAwesomeIcon
                className="m-1 p-1"
                icon={faCirclePlus}
                size="2x"
                onClick={() => handleOpen("Add", "New Division", null)}
              />
            </Tooltip>
            <Tooltip
              title={
                <div className="tooltip-title">
                  <h6>Trade CSV</h6>
                </div>
              }
            >
              <Button
                className="size-xs rounded-circle p-0"
                component="label"
                role={undefined}
                variant="text"
                tabIndex={-1}
                size="small"
                style={{ background: "transparent" }}
                disabled={!isLoading}
              >
                <CloudUploadIcon className="fs-1" style={{ color: "black" }} />
                <VisuallyHiddenInput
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                />
              </Button>
            </Tooltip>
          </div>

          <div
            className="list-group mt-2 border border-10 shadow manage-division-list pointer"
            style={{ height: "65vh", overflowY: "scroll" }}
          >
            {isLoading ? (
              nodes &&
              nodes?.length > 0 &&
              nodes.map((division: any, idx: any) => {
                if (division.parenttrade === null) {
                  return (
                    <div
                      className="row d-flex border-bottom gx-0"
                      style={
                        selectedDivision && selectedDivision.id === division.id
                          ? { background: color.lightColor }
                          : { background: "inherit" }
                      }
                      onClick={() => handleDivisionClick(division.id)}
                    >
                      <div className="col-4 size-sm">{division.tradeCode}</div>
                      <div className="col-5 size-sm">{division.name}</div>
                      <div className="col-3 d-flex">
                        <FontAwesomeIcon
                          className="m-1 p-1 pointer"
                          icon={faEdit}
                          id={division.id}
                          size="1x"
                          onClick={() =>
                            handleOpen("Edit", division.name, division)
                          }
                        />
                        <FontAwesomeIcon
                          className="m-1 p-1 pointer"
                          icon={faTrash}
                          id={division.id}
                          size="1x"
                          onClick={() =>
                            handleOpen("Delete", division.name, division)
                          }
                        />
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div
                className="row d-flex border-bottom gx-0"
                style={{ height: "60vh" }}
              >
                <Loader
                  className="d-flex justify-content-center mt-5"
                  label={loadingLabel}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-8 gx-5">
          <div className="d-flex justify-content-between align-items-center mx-2">
            <div>
              <h4 className="size-xl">{`Specifications of ${
                selectedDivision && selectedDivision.name
              }`}</h4>
            </div>
            <div className="d-flex justify-content-end">
              <Tooltip
                title={
                  <React.Fragment>
                    <div className="tooltip-title">
                      <h6 className="size-xl">Add Specification</h6>
                    </div>
                  </React.Fragment>
                }
              >
                <FontAwesomeIcon
                  className="m-1 p-1"
                  icon={faCirclePlus}
                  size="2x"
                  onClick={() =>
                    handleOpen("Add", selectedDivision.name, selectedDivision)
                  }
                />
              </Tooltip>
              <Button
                type="button"
                className="btn"
                style={{ background: color.mediumColor, color: "inherit" }}
                onClick={() => {setSubmitModalOpen(true);
                }}
                disabled={!isLoading}
              >
                Submit All
              </Button>
            </div>
          </div>
          <div
            className="m-2 p-2 border shadow subdivision-list"
            style={{ height: "65vh", overflowY: "scroll" }}
          >
            {subDivisionList && subDivisionList.length > 0 ? (
              <RichTreeView
                className="w-100 d-inline"
                aria-label="customized"
                sx={{
                  overflowX: "hidden",
                  minHeight: 270,
                  flexGrow: 1,
                  maxWidth: 300,
                }}
                items={subDivisionList}
                slots={{
                  item: CustomTreeItem2,
                }}
              />
            ) : (
              <div className="d-flex border-bottom mt-1 p-1 justify-content-between align-items-center">
                <p>No Specifications found</p>
              </div>
            )}
          </div>
        </div>
        <Dialog
          open={open}
          onClose={() => handleClose(null,false)}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            component: "form",
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries((formData as any).entries());
            },
          }}
        >
          <div className="row"> 
          <DialogTitle className="col-10">
            {dialogType === "Add"
              ? `Add Specifications for ${divisionDialog}`
              : ""}
          </DialogTitle>
          <div className="col-1"></div>
            <div className="col-1 pt-2">
            <FontAwesomeIcon
                 className="pointer"
                 icon={faClose}
                 size="2xl"
                 onClick={() =>handleClose(null,false)}
            />
            </div>
          </div>
          <DialogContent className="pt-0 pb-0">
            {dialogType === "Delete" ? (
              <p>Are you sure to delete {divisionDialog}?</p>
            ) : (
              <div className="col-12 mt-2">
                <div className="row p-0">
                  {dialogType.includes("Add") && (
                    <div className="col-6">
                      <InputField
                        id="tradeCode"
                        name="tradeCode"
                        type="text"
                        className="form-control"
                        label="Enter Trade Code"
                        value={tradeCode}
                        handleChange={(e) => setTradeCode(e.target.value)}
                        required
                      />
                    </div>
                  )}
                  <div
                    className={dialogType.includes("Edit") ? "col-12" : "col-6"}
                  >
                    <InputField
                      id="name"
                      name="name"
                      type="text"
                      defaultValue={
                        dialogType.includes("Edit") ? divisionDialog : ""
                      }
                      handleChange={(e) => setDivisionName(e.target.value)}
                      className="form-control"
                      label="Enter Specifications"
                      required
                    />
                  </div>
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions className="d-flex justify-content-center">
            <Button
              className="btn col-3 mb-2 ms-1"
              type="submit"
              onClick={() => {
                if(dialogType === "Add"){
                  if(divisionName.length !== 0 && tradeCode.length !== 0){
                handleClose(dialogType, true);}}
              else{handleClose(dialogType, true);}}}
              style={{ backgroundColor: color.darkColor, color: "white" }}
            >
              {dialogType === "Delete" ? "Add to Bin" : "Save As Draft"}
            </Button>
            <Button
              className="btn col-2 mb-2 ms-3"
              type="submit"
              style={{ backgroundColor: color.darkColor, color: "white" }}
              onClick={() => {
                if(dialogType === "Add"){
                  if(divisionName.length !== 0 && tradeCode.length !== 0){
                handleClose(dialogType, false);}}
              else{handleClose(dialogType, false);}}}
            >
              {dialogType === "Delete" ? "Delete" : "Save"}
            </Button>
            <div className="col-1"></div>
          </DialogActions>
        </Dialog>
        <CustomizedSnackbar
          openSnackBar={isOpen}
          message={message}
          handCloseSnackBar={() => {
            setIsOpen(false);
          }}
        />
        <Modal
        id={"submitModal"}
        isOpen={submitModalOpen}
        className="position-fixed m-2 p-2"
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            height: "400px",
            padding: "0",
            backgroundColor: "white",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
          },
        }}
        >
          <div className="row">
            <div className="col-11"></div>
            <div className="col-1 d-flex justify-content-end pt-2">
            <FontAwesomeIcon
                 className="pointer"
                 icon={faClose}
                 size="2xl"
                 onClick={() =>{
                  setSubmitModalOpen(false);
                }}
            />
            </div>
          </div>
          { saveTrades === defaultDivision && submitRowData.length === 0 ? <div className="d-flex justify-content-center"><span className="fs-1">No Action to Submit!</span></div> : 
          <>
          {saveTrades && submitRowData &&
          
          <div className="conatiner-fluid h-75 p-2 " style={{overflowY: "scroll",
                      overflowX: "hidden",}}>
          
          <div className="row d-flex justify-content-center">
            <div className="h-65 col-11">
              {/* props needed: handle delete on action, saveTrades, deletedTradeInfo 
              rows appending*/}
              <CustomTable 
              headCells={submitTradeHeaderCells} 
              rows={submitRowData}   
              handelRemoveTrade={handleRemove}           
              />
            </div>
          </div>
          <div className="row d-flex justify-content-center">
          <Button
            id="submitTrade"
            className="btn col-3"
            onClick={handleClearAll}
            style={{ backgroundColor: color.darkColor, color: "white" }}
            >
              Clear All
            </Button>
            <Button
            id="submitTrade"
            className="btn col-3 ms-3"
            onClick={()=> {
              saveDivision(saveTrades);
              setSubmitRowData([]);
              setSaveTrades(defaultDivision);
              setSubmitModalOpen(false);
            }}
            style={{ backgroundColor: color.darkColor, color: "white" }}
            >
              Submit All
            </Button>
          </div>
          </div>
          }
          </>
        }
        </Modal>
      </div>
    </div>
  );
};

export default ManageDivisions;
