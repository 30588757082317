import "./App.css";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/LoginForm";
import OnboardRequest from "./components/OnboardingForm";
import ForgetPassword from "./components/ForgotPassword";
import LayOut from "./components/Layout/layout";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import ResetPassword from "./components/PasswordReset";
import ExploreOpportunities from "./components/ExploreOpportunities";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ThemeProvider } from "@mui/material";
import { CustomeTheme } from "./customeTheme";
import VerifyEmail from "./components/OnboardingForm/verifyEmail";
import PrivateRoute from "./components/PrivateRoute/privateRoute";

function App() {
  return (
    <ThemeProvider theme={CustomeTheme}>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route path="/sign-up" element={<OnboardRequest />}></Route>
            <Route path="/forget-password" element={<ForgetPassword />}></Route>
            <Route path="/reset-password" element={<ResetPassword />}></Route>
            <Route path="/explore" element={<ExploreOpportunities />}></Route>
            {/* <Route path="/dashboard" element={<LayOut />}></Route> */}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <LayOut />
                </PrivateRoute>
              }
            />
            <Route path="/verify-email" element={<VerifyEmail />} />
          </Routes>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
