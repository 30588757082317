import { Link,useLocation,useNavigate } from "react-router-dom";
import GlobalHeader from "../GlobalHeader";
import { Button, IconButton, InputAdornment } from "@mui/material";
import InputField from "../InputField";
import Utils from "./utils";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { updatePassword } from "../../utils/api";
import CustomizedSnackbar from "../CustomizedSnackbar";

const ResetPassword = () => {


  const [showPassword, setShowPassword] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("")
  const { values, handleChange, errors, setFieldTouched, touched, isValid } = Utils();

  const location = useLocation();
  const history = useNavigate();

  useEffect(() => {
    if (!location.state) {
      history("/login");
    }
  }, []);

  // const {email} = location.state ? location.state[0].email :  history('/login')

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    console.log('isValid', isValid)
    if(!isValid){
      setFieldTouched("newPassword", true);
      return null;
    }
    event.preventDefault();
    updatePassword("", undefined, {
      email: location.state,
      password: values.confirmNewPassword,
    })
      .then((response) => {
        setIsOpen(true);
        console.log("response", response);
        if (response.status.includes("OK")) {
          setMessage(response.message);
          history("/login");
        } else {
          setMessage(response.message);
        }
      })
      .catch((error: any) => {
        setMessage(error.message);
      });
  };
  
  return (
    <>
      <div
        className="password-setting row  mx-auto"
        style={{ marginTop: "10%" }}>
        <GlobalHeader title=" Reset Password" isLogoVisible={true} />
        <div className="row">
          <form className="form-group shadow">
            <div className="form-floating mt-5">
              <InputField
                id="newPassword"
                name="newPassword"
                type={showPassword ? "text" : "password"}
                value={values.newPassword}
                handleChange={handleChange}
                className="form-control"
                label="New password"
                autoComplete="new-password"
                handleBlur={()=> {setFieldTouched("newPassword", true); setFieldTouched("confirmNewPassword", true);}}
                error={touched.newPassword && errors.newPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                required
              />
            </div>
            <div className="form-floating mt-5">
              <InputField
                id="confirmNewPassword"
                name="confirmNewPassword"
                type="password"
                value={values.confirmNewPassword}
                handleChange={handleChange}
                className="form-control"
                label="Confirm password"
                autoComplete="confirm-password"
                handleBlur={()=> setFieldTouched("confirmNewPassword", true)}
                error={touched.confirmNewPassword && errors.confirmNewPassword}
                required
              />
            </div>
            <div className="row mt-4 col-12 mx-auto">
              <Button
                className="btn btn-bg col-3 mx-auto mb-3"
                onClick={handleSubmit}
              >
                <Link
                  to={""}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Submit
                </Link>
              </Button>
            </div>
          </form>
        </div>
      </div>
      <CustomizedSnackbar openSnackBar={isOpen} message={message} handCloseSnackBar={()=> setIsOpen(false)}/>
    </>
  );
};

export default ResetPassword;
