import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Money, CreditCard, AddHome, SupervisorAccount, ManageAccounts, ViewList, AccountBalance, Public, InsertInvitation } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../../redux/selectors/selectors";

type Menu = {
  id: number;
  name: string;
  icon: IconDefinition;
  subMenu: any[];
}

interface Props {
  toggle: boolean;
  onMenuClick: (name: string) => void
}

const SideBar = ({ toggle, onMenuClick }: Props) => {
  const color = useSelector(selectThemeColor);
  const [activeMenu, setActive] = useState<string>('');
  const [menus, setMenus] = useState<any[]>([]);
  const [openSubMenus, setOpenSubMenus] = useState<any>({});

  const selectMenu = (name: string) => {
    onMenuClick(name);
    setActive(name);
    setOpenSubMenus((prevOpenSubMenus: any) => ({...prevOpenSubMenus, [name]: !prevOpenSubMenus[name]}));
  }

  const getIcon = (name: string) => {
    switch (name) {
      case "Manage Users":
        return <ManageAccounts />;
      case "Project List":
        return <ViewList />;
      case "Manage Trades":
        return <SupervisorAccount />;
      case "Manage Project":
        return <AccountBalance />;
      case "Manage Bids":
        return <CreditCard />;
      case "My Bids":
        return <Money />;
      case "Invited Bids":
        return <InsertInvitation />;
      case "Public Bids":
        return <Public />;
      default:
        return <AddHome />;
    }
  };
  
  const menuFromStorage: {
    id: number;
    name: string;
  }[] = JSON.parse(localStorage.getItem("menus") || "") || [];

  useEffect(() => {
    const updatedMenuItems: any = menuFromStorage.map(menu => {
      switch (menu.name) {
        default:
          return {
            ...menu,
          };
      }
    });
    
    setMenus(updatedMenuItems);
    return selectMenu(updatedMenuItems.length !== 0 &&
      (updatedMenuItems[0].subMenu && updatedMenuItems[0].subMenu.length !== 0
        ? updatedMenuItems[0].subMenu[0].name
        : updatedMenuItems[0].name)
    );
  }, [])

  const toggleSubMenu = (name: string) => {
    setOpenSubMenus((prevOpenSubMenus: any) => ({...prevOpenSubMenus,
      [name]: !prevOpenSubMenus[name]
    }));
  }

  return (
    <div
      style={{
        display: "flex",
        height: "95vh",
        backgroundColor: color.mediumColor,
        opacity: "1",
      }}
      className="mt-3"
    >
      <Sidebar
        collapsed={toggle}
        style={{ backgroundColor: color.mediumColor }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div style={{ flex: 1, marginBottom: "32px" }}>
            <Menu
              menuItemStyles={{
                button: ({ active }) => {
                  return {
                    backgroundColor: active ? color.darkColor : undefined,
                    "&:active": {
                      backgroundColor: color.darkColor,
                    },
                    "&:hover": {
                      backgroundColor: color.darkColor,
                    }
                  };
                }
              }}
            >
              {menus.map((menu: any) => (
                <>
                  {!menu.subMenu && (
                    <MenuItem
                      key={menu.id}
                      active={activeMenu === menu.name}
                      icon={getIcon(menu.name)}
                      onClick={() => selectMenu(menu.name)}
                      rootStyles={{
                        backgroundColor: color.mediumColor,
                      }}
                    >
                      {menu.name}
                    </MenuItem>
                  )}
                  {menu.subMenu && (
                    <SubMenu
                      label={menu.name}
                      icon={getIcon(menu.name)}
                      open={openSubMenus[menu.name]}
                      onClick={() => toggleSubMenu(menu.name)}
                    >
                      {menu.subMenu.map(
                        (subMenuItem: { id: number; name: string }) => (
                          <MenuItem
                            key={subMenuItem.id}
                            active={activeMenu === subMenuItem.name}
                            icon={getIcon(subMenuItem.name)}
                            onClick={() => { selectMenu(subMenuItem.name); }}
                            rootStyles={{
                              backgroundColor: color.lightColor,
                            }}
                          >
                            {subMenuItem.name}
                          </MenuItem>
                        )
                      )}
                    </SubMenu>
                  )}
                </>
              ))}
            </Menu>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default SideBar;