import { createAction } from 'typesafe-actions';
export const ADD_BASIC_FORM_DETAILS = "ADD_BASIC_FORM_DETAILS";
export const ADD_DOCUMENTS_DETAILS = "ADD_DOCUMENTS_DETAILS";
export const ADD_SELECTED_TRADES_DETAILS = "ADD_SELECTED_TRADES_DETAILS";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_PROJECT_ID = "SET_PROJECT_ID";
export const SET_POST_PROJECT_STATUS = "SET_POST_PROJECT_STATUS";
export const SET_BID_INVITATION_MAIL_LIST = "SET_BID_INVITATION_MAIL_LIST";
export const SET_PROJECT_BID_TRADE_ID = "SET_PROJECT_BID_TRADE_ID";




export const setBasicFormDetails = createAction(
    ADD_BASIC_FORM_DETAILS,
    (action) => (payload: any) => action(payload)
);
export const setDocumentDetails = createAction(
    ADD_DOCUMENTS_DETAILS,
    (action) => (payload: any) => action(payload)
);
export const setSelectedTradesDetails = createAction(
    ADD_SELECTED_TRADES_DETAILS,
    (action) => (payload: any[]) => action(payload)
);
export const setActiveStepAction = createAction(
    SET_ACTIVE_STEP,
    (action) => (payload: any) => action(payload)
);

export const setProjectId = createAction(
    SET_PROJECT_ID,
    (action) => (payload: any) => action(payload)
);

export const setPostProjectRespStatus = createAction(
    SET_POST_PROJECT_STATUS,
    (action) => (payload: any) => action(payload)
);

export const setBidInvitationMailList = createAction(
    SET_BID_INVITATION_MAIL_LIST,
    (action) => (payload: any) => action(payload)
);

export const setProjectBidTradeId = createAction(
    SET_PROJECT_BID_TRADE_ID,
    (action) => (payload: any) => action(payload)
);