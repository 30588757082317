import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import InputField from "../../InputField";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../../redux/selectors/selectors";
import { addUser, updateUser } from "../../../utils/api";
import CustomizedSnackbar from "../../CustomizedSnackbar";
import { SubmenuEnum } from "../../../utils/enum";

const CreateUser = (props: any) => {
  const color = useSelector(selectThemeColor);

  const [message, setMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isEdit = props.activeMenu === SubmenuEnum.EDIT_USER ? true : false;
  // const userId = props.values.id;

  console.log("activemenu", props.activeMenu);

  const handleSave =  () => {
    if (!isEdit) {
      console.log("values", props.values);
      const body = {
        firstName: props.values.firstName,
        lastName: props.values.lastName,
        email: props.values.email,
        mobileNo: props.values.phoneNo,
        status: props.values.isActive ? `A` : `P`,
      };
      console.log("body", body);
       addUser("", undefined, body)
        .then((response) => {
          console.log("response for new user:", response);
          debugger;
          if (response.status === "OK") {
            setIsOpen(true);
            setMessage("User added successfully!");
            props.setBackButton(false);
          } else {
            setMessage(response.message);
            setIsOpen(true);
          }
        })
        .catch((error: any) => {
          setMessage(error.message);
          setIsOpen(true);
        });
    } else {
      console.log("values", props.values);
      const status= props.values.isActive ? "A" : "R";
      console.log("status",status);
      if (props.values.id) {
        const userId = props.values.id;
        updateUser("", {userId, status}, undefined)
          .then((response) => {
            console.log("edit user:", response);
            if (response.status === "OK") {
              setIsOpen(true);
              setMessage("Status updated successfully!");
              props.setBackButton(false);
            } else {
              setMessage(response.message);
              setIsOpen(true);
            }
          })
          .catch((error: any) => {
            setMessage(error.message);
            setIsOpen(true);
          });
      }
    }
  };

  return (
    <>
      <div className="overflow-hidden">
        <div className="d-grid shadow" style={{ backgroundColor: "#F8F9FA" }}>
          <div
            className="container w-45 overflow-y-scroll"
            style={{ height: "57vh" }}
          >
            <div className="row justify-content-center mt-8">
              <div className="form-floating col-4 col-md-4 col-sm-4">
                <InputField
                  id="firstName"
                  name="firstName"
                  type="text"
                  value={props.values.firstName}
                  handleChange={props.handleChange}
                  className="form-control"
                  label="First Name"
                  error={
                    props.isSubmit &&
                    props.touched.firstName &&
                    props.errors.firstName
                  }
                  required={!isEdit}
                  readonly={isEdit}
                />
              </div>
              <div className="form-floating col-4 col-md-4 col-sm-4">
                <InputField
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={props.values.lastName}
                  handleChange={props.handleChange}
                  className="form-control"
                  label="Last Name"
                  error={
                    props.isSubmit &&
                    props.touched.lastName &&
                    props.errors.lastName
                  }
                  required={!isEdit}
                  readonly={isEdit}
                />
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="form-floating col-4 col-md-4 col-sm-4">
                <InputField
                  id="phoneNo"
                  name="phoneNo"
                  type="text"
                  value={props.values.phoneNo
                    .replace(/\D/g, "")
                    .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}
                  maxLength={10}
                  handleChange={props.handleChange}
                  className="form-control"
                  label="Phone Number"
                  error={
                    props.isSubmit &&
                    props.touched.phoneNo &&
                    props.errors.phoneNo
                  }
                  required={!isEdit}
                  readonly={isEdit}
                />
              </div>
              <div className="form-floating col-4 col-md-4 col-sm-4">
                <InputField
                  id="email"
                  name="email"
                  type="text"
                  value={props.values.email}
                  handleChange={props.handleChange}
                  className="form-control"
                  label="Email"
                  error={
                    props.isSubmit && props.touched.email && props.errors.email
                  }
                  required={!isEdit}
                  readonly={isEdit}
                />
              </div>
            </div>
            <div className="">
              <div
                className="d-flex align-items-center justify-content-end"
                style={{ marginRight: "17%" }}
              >
                <Checkbox
                  className=""
                  checked={props.values.isActive}
                  style={{ color: color.darkColor, width: "15px" }}
                  size="medium"
                  key="yes"
                  onChange={(e) => {
                    props.setFieldValue("isActive", !props.values.isActive);
                  }}
                />
                {props.isSubmit &&
                  props.touched.isActive &&
                  props.errors.isActive && (
                    <span className="text-danger">{props.errors.isActive}</span>
                  )}
                <div className="ms-2">Is Active</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row offset-5 mt-4">
          <Button
            className="btn col-3 col-md-3 col-sm-3 p-2"
            onClick={(e) => {
              e.preventDefault();
              handleSave();
            }}
            style={{ backgroundColor: color.darkColor, color: "white" }}
          >
            Submit
          </Button>
        </div>
      </div>
      <CustomizedSnackbar
        openSnackBar={isOpen}
        message={message}
        handCloseSnackBar={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
};

export default CreateUser;
