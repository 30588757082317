import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { faDashboard, faDownload, faEye, faMinimize, faMinus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, styled, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../redux/selectors/selectors";
import Modal from "react-modal";
import { FilePreview } from "../FilePreview";
import { useEffect, useState } from "react";

const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-80%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
    opacity: "1",
    background: "#f8f9fa",
  },
};
interface Props {
  rows: any[];
  headers: any[];
  style?: any;
  handleDelete?: (projectDocId: number) => void;
  isEdit:boolean;
}

const ProjectDocumentTable = ({
  rows,
  headers,
  style,
  handleDelete,
  isEdit
}: Props) => {
  const defaultFile = new File(["file content"], "example.txt", {
    type: "text/plain",
  });

  const [modalIsOpen, setIsOpen] = useState(false);
  const [fileType, setFileType] = useState<string>("");
  const [viewFile, setViewFile] = useState<File>(defaultFile);
  const [csvHeaders, setCsvHeaders] = useState<any[]>([]);
  const [csvRows, setCsvRows] = useState<any[]>([]);

  const color = useSelector(selectThemeColor);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: color.mediumColor,
      color: "#fff",
      fontSize: 16,
      fontWeight: "600",
      padding: "0.7 rem",
      width: "auto",
      minWidth: 100,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
    },
  }));

  const base64ToBlob = (base64: string, mime: any) => {
    const sliceSize = 512;
    const byteCharacters = atob(base64);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex++) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; offset++, i++) {
        bytes[i] = byteCharacters.charCodeAt(offset);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: mime });
  };

  const parseCSV = (text: string) => {
    const rows = text.split("\n");
    const newHeaders: string[] = rows[0].split(",");
    setCsvHeaders(structuredHeaders(newHeaders));

    const newData: any = rows.slice(1).map((row) => {
      const values = row.split(",");
      return newHeaders.reduce((acc: any, header: string, index: number) => {
      acc[header] = values[index];
      return acc;
      }, {});
      });
    
      const filteredData = newData.filter((row: any) => 
      structuredHeaders(newHeaders).some((header: any) => row[header.id])
      );
      
      setCsvRows(() => [...filteredData]);
  };

  const structuredHeaders = (header: any) => {
    const regex = /_/g;
    return (
      header &&
      header.map((cell: any, index: any) => ({
        id: cell,
        name: cell,
      }))
    );
  };

  const handleView = (
    fileDataBase64: any,
    docName: string,
    docType: string
  ) => {
    let blob = new Blob();
    console.log('docName', docName)
    if (docName.split(".").pop() === "pdf") {
      blob = base64ToBlob(fileDataBase64, "application/pdf");
      const file = new File([blob], docName, { type: blob.type });
      setViewFile(file);
      setIsOpen(true);
    } else if (docName.split(".").pop() === "csv") {
      blob = base64ToBlob(fileDataBase64, "application/csv");
      const file = new File([blob], docName, { type: blob.type });
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const text = e.target.result;
          parseCSV(text);
        };

        reader.readAsText(file);
        setViewFile(file);
        setIsOpen(true);
      }

    }else if (docName.split(".").pop() === ".docx") {
      blob = base64ToBlob(fileDataBase64, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
      const file = new File([blob], docName, { type: blob.type });
      setViewFile(file);
      setFileType('application/vnd.openxmlformats-officedocument.wordprocessingml.document');
      setIsOpen(true);
    } else {
      blob = base64ToBlob(fileDataBase64, "image/jpeg");
      const file = new File([blob], docName, { type: blob.type });
      setViewFile(file);
      setFileType("image/jpeg");
      setIsOpen(true);
    }
  };
  const handleDownload = (fileDataBase64: any, docName: string) => {
    try {
      const binaryString = atob(fileDataBase64);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([bytes], { type: "application/octet-stream" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = docName;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const getTableCell = (cell: any, row: any, index: number) => {
    switch (cell.action) {
      case "viewAndDelete": {
        return (
          <StyledTableCell
            sx={{ cursor: "pointer" }}
            key={cell.id}
            align="center"
          >
            <div className="opacity-50">
              <Tooltip
                title={
                  <>
                    <div className="tooltip-title">
                      <span>View</span>
                    </div>
                  </>
                }
              >
                <FontAwesomeIcon
                  icon={faEye}
                  size="lg"
                  onClick={() =>
                    handleView(
                      row["fileDataBase64"],
                      row["docName"],
                      row["docType"]
                    )
                  }
                />
              </Tooltip>
              <Tooltip
                title={
                  <>
                    <div className="tooltip-title">
                      <span>Download</span>
                    </div>
                  </>
                }
              >
                <FontAwesomeIcon
                  className="ms-2"
                  icon={faDownload}
                  size="lg"
                  onClick={() =>
                    handleDownload(row["fileDataBase64"], row["docName"])
                  }
                />
              </Tooltip>
            {isEdit ?
              <Tooltip
                title={
                  <>
                    <div className="tooltip-title">
                      <span>Delete</span>
                    </div>
                  </>
                }
              >
                <FontAwesomeIcon
                  className="ms-2"
                  icon={faTrash}
                  size="lg"
                  onClick={() => handleDelete!(row["projectDocId"])}
                />
              </Tooltip>
              :
              <></>
            }
            </div>
          </StyledTableCell>
        );
      }
      case "size": {
        return (
          <StyledTableCell
            sx={{ cursor: "pointer" }}
            key={cell.id}
            align="center"
          >
            {row[cell.id]}
          </StyledTableCell>
        );
      }
      case "title": {
        return (
          <StyledTableCell
            sx={{ cursor: "pointer" }}
            key={index}
            align="center"
            style={{
              maxWidth: 200,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {row[cell.id].split(".")[0]}
          </StyledTableCell>
        );
      }
      default: {
        return (
          <StyledTableCell
            style={{
              maxWidth: 200, // Set the maximum width of the cell
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            padding="normal"
            key={cell.id}
            align="center"
          >
            {row[cell.id] ? row[cell.id]  : <FontAwesomeIcon icon={faMinus} />}
          </StyledTableCell>
        );
      }
    }
  };
  return (
    <>
      <TableContainer key="table-container" sx={style}>
        <Table
          key="table"
          className="table shadow"
          sx={{
            overflowX: "hidden",
            overflowY: "scroll",
          }}
          size="small"
          aria-label="data table"
          stickyHeader={true}
        >
          <TableHead key="table-head">
            <TableRow key="table-row" style={{ zIndex: 1 }}>
              {headers.map((cell, index) => (
                <StyledTableCell
                  key={index}
                  style={{
                    width: 300,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  {<span>{cell.name}</span>}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody key="body">
            {rows.length > 0 ? (
              rows.map((row, rowIndex) => (
                <TableRow hover key={rowIndex} sx={{ borderBottom: "1" }}>
                  {headers.map((cell: any) =>
                    getTableCell(cell, row, rowIndex)
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow key={"-1"}>
                <TableCell
                  colSpan={6}
                  align="center"
                  style={{ textAlign: "center" }}
                >
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        id="viewDocument"
        isOpen={modalIsOpen}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-2x">
          <div className="modal-content">
            <div className="modal-header mb-2">
              <div className="mx-auto fw-bold size-xl">View Document</div>
              <Button
                type="button"
                className="btn-close"
                data-mdb-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></Button>
            </div>
            <div
              className="modal-body mt-4 overflow-hidden"
              style={{ width: "50vw", height: "50vh" }}
            >
              <div className="container">
                <div key="preview">
                  <FilePreview
                    file={viewFile}
                    headers={csvHeaders}
                    rows={csvRows}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProjectDocumentTable;
