import React, { useContext } from 'react'
import { CustomTable } from '../../../DataTable';
import { useSelector } from 'react-redux';
import { selectThemeColor } from '../../../../redux/selectors/selectors';

const PublicBids = (props:any) => {
    const color = useSelector(selectThemeColor);

    const handleSelectedRow = (row: any, type: string, check: boolean) => {
        if (type === "edit") handleOpenModal(row);
        props.bidProps.setBackButton(true);
        props.getSubMenu(" / Public Bids")
    }

    const handleOpenModal = (data: any) => {
        props.setSelectedRow(data)
    };
    const constructionTypeList = [
        { id: 1, value: 'New Construction' },
        { id: 2, value: 'Improvements' },
        { id: 3, value: 'Renovation' }
    ];

    const headerCells = [
        { id: "projectName", name: "Project Name" },
        { id: "division", name: "Trade" },
        { id: "specification", name: "Specification" },
        { id: "city", name: "City" },
        { id: "bidDate", name: "Bid Date" },
        { id: "constructionType", name: "Construction Type" },
        { id: "action", name: "Action", action: "isRowActionable" }
    ];

    return (
        <>
            <div className="container">
                <div className="row mt-3">
                    <CustomTable headCells={headerCells} rows={props.tableRowData} onSelectRowClick = {handleSelectedRow}/>
                </div>
            </div>
        </>
    );
}

export default PublicBids