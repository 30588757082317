import { useCallback, useEffect, useState } from "react";
import { CustomTable } from "../../DataTable";
import InputField from "../../InputField";
import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { getAllSelectedTrades, getBidsExploreOpportunities, putPublicBidRequestAccess, updateOrgTrades } from "../../../utils/api";
import { selectProjectId, selectThemeColor } from "../../../redux/selectors/selectors";
import { useSelector } from "react-redux";
import { cityNames } from "../../../utils/const";
import { SearchFilterBidOppType } from "../../../utils/types";
import { useDispatch } from "react-redux";
import { setProjectId } from "../../../redux/actions/projectFormActions";
import PubicBidsOppTradeModal from "../../PubicBidsOppTradeModal/pubicBidsOppTradeModal";

const DefaultSearchFiltered = {
  projectTitle: "",
  dueDateFrom: "",
  dueDateTo: "",
  email: "",
  city: "",
}

const BidExploreOpportunities = () => {
  const [exploreOpportunitiesRowData, setExploreOpportunitiesRowData] =
    useState<any>([]);
  const [search, setSearch] = useState<SearchFilterBidOppType>(DefaultSearchFiltered);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [allTradesRows, setAllTradesRows] = useState<any>([]);

  const dispatch = useDispatch();
  const color = useSelector(selectThemeColor);
  const projectId = useSelector(selectProjectId);

  const headCells = [
    { id: "projectName", name: "Project Title", action: "" },
    { id: "bidNumber", name: "Bid#", action: "" },
    { id: "bidDate", name: "Due Date", action: "date" },
    { id: "jobWalkDate", name: "Job Date & Time", action: "date" },
    { id: "invitedBy", name: "By GC", action: "" },
    { id: "city", name: "City", action: "" },
    { id: "Request Access", name: "Action", action: "button" },
  ];

  const style = [
    { width: "100%", height: "65vh", overflowX: "scroll", overflowY: "scroll" },
  ];

  useEffect(() => {
    console.log("============BidExploreOpportunities Rendering================")
    fetchGetBidsExpApi();
  }, []);

  const fetchApiGetAllProjectTrades = useCallback((projectId: any) : any => {
    getAllSelectedTrades("", projectId, undefined)
      .then((resp) => {
        if (resp.data.AllTrades.length > 0) {
          debugger
          setAllTradesRows(resp.data.AllTrades);
          const isAtleastOneTradeThere = resp.data.AllTrades.filter((data : any)=> data.isAdded === 'Y').length > 0;
          setIsOpenModal(!isAtleastOneTradeThere);
          if (isAtleastOneTradeThere) {
            putPublicBidRequestAccess("", projectId, undefined)
              .then((resp) => {
                if (resp.status === "OK") {
                  fetchGetBidsExpApi();
                }
              })
              .catch(() => {});
          }
          return isAtleastOneTradeThere
        }
      })
      .catch(() => {
        return false;
      });
  }, []);

  const fetchGetBidsExpApi = useCallback(() => {
    getBidsExploreOpportunities(" ", undefined, undefined)
      .then((resp) => {
        if (resp.data === null || resp.data === new Map()) {
          setExploreOpportunitiesRowData([]);
        } else {
          setExploreOpportunitiesRowData(resp.data);
        }
      })
      .catch((e: any) => {
        console.log("e error:>> ", e);
      });
  }, []);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handlePutRequestAccess = (
    row: any,
    actionType: string,
    checked: boolean
  ) => {

    fetchApiGetAllProjectTrades(row.projectId);
    dispatch(setProjectId(row.projectId));
  };

  const handleSubmitNotAddedTrades = (data : any) =>{
    updateOrgTrades("", projectId, data)
      .then((resp) => {
        setIsOpenModal(false);
        dispatch(setProjectId(undefined));
        putPublicBidRequestAccess("", projectId, undefined)
        .then((resp) => {
          if (resp.status === "OK") {
            fetchGetBidsExpApi();
          }
        })
        .catch(() => {});
      })
  }

  return (
    <>
      <div className="row gx-0">
        <div className="row mt-2">
          <div className="col-2 col-md-2 col-sm-2">
            <InputField
              id="projectTitle"
              name="projectTitle"
              type="text"
              className="form-control"
              label="Project Title"
              value={search.projectTitle}
              handleChange={handleChange}
            />
          </div>
          <div className="col-2 col-md-2 col-sm-2">
            <InputField
              id="dueDateFrom"
              name="dueDateFrom"
              type="date"
              className="form-control"
              label="Due Date (From)"
              shrink
              value={search.dueDateFrom}
              handleChange={handleChange}
            />
          </div>
          <div className="col-2 col-md-2 col-sm-2">
            <InputField
              id="dueDateTo"
              name="dueDateTo"
              type="date"
              className="form-control"
              label="Due Date (To)"
              shrink
              value={search.dueDateTo}
              handleChange={handleChange}
            />
          </div>
          <div className="col-2 col-md-2 col-sm-2">
            <InputField
              id="byGC"
              name="email"
              type="text"
              className="form-control"
              label="By GC"
              value={search.email}
              handleChange={handleChange}
            />
          </div>
          <div className="col-2 col-md-2 col-sm-2">
            <FormControl className="col-11">
              <InputLabel
                id="citySelect"
                style={{
                  top: "-25%",
                }}
              >
                Select City
              </InputLabel>
              <Select
                labelId="select-city"
                id="selectCity"
                name="city"
                defaultValue=""
                label="Select City"
                className="bg-white"
                size="small"
                value={search.city}
                onChange={handleChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                      overflowY: "auto",
                    },
                  },
                }}
              >
                <MenuItem className="d-block ms-2" value="" key={-1}></MenuItem>
                {cityNames.map((city, index: number) => (
                  <MenuItem className="d-block ms-2" key={index} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-2 col-md-2 col-sm-2">
            <Button
              className="btn"
              style={{ background: color.mediumColor, color: "#fff" }}
              onClick={() => {
                setSearch({
                  projectTitle: "",
                  dueDateFrom: "",
                  dueDateTo: "",
                  email: "",
                  city: "",
                });
              }}
            >
              Clear
            </Button>
            <Button
              className="btn ms-2"
              style={{ background: color.mediumColor, color: "#fff" }}
            >
              Search
            </Button>
          </div>
        </div>
        <div className="row">
          <CustomTable
            headCells={headCells}
            rows={exploreOpportunitiesRowData}
            onSelectRowClick={handlePutRequestAccess}
            style={style}
          />
        </div>
      </div>
      <PubicBidsOppTradeModal
        openModal={isOpenModal}
        handleClose={() => setIsOpenModal(false)}
        handleSubmitNotAddedTrades={handleSubmitNotAddedTrades}
        allTradesRows={allTradesRows}
      />
    </>
  );
};

export default BidExploreOpportunities;
