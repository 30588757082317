import { useState } from "react";
import MyBidsTable from "./MyBidsTable/myBidsTable";
import { Button } from "@material-ui/core";
import BidExploreOpportunities from "./BidExploreOpportunities/bidExploreOpportunities";
import { SubmenuEnum } from "../../utils/enum";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveStepAction,
  setBasicFormDetails,
  setDocumentDetails,
  setPostProjectRespStatus,
  setProjectId,
  setSelectedTradesDetails,
} from "../../redux/actions/projectFormActions";
import { selectThemeColor } from "../../redux/selectors/selectors";
import ProjectBids from "./ProjectBids/ProjectBids";
import InvitedBidDetailForm from "./InvitedBidDetailForm/invitedBidDetailForm";

const MyBidsInternal = (props: any) => {
  const [selectRows, setSelectRows] = useState<any>([]);
  const [userActivity, setUserActivity] = useState<string>("");
  const [activeMenu, setActiveMenu] = useState(SubmenuEnum.MY_BIDS);
  const [bidId, setBidId] = useState(0);

  const color = useSelector(selectThemeColor);
  const dispatch = useDispatch();

  const handleSelectRow = (row: any) => {
    props.setBackButton(true);
    props.getSubMenu(
      " / " +
        (row.postedByMeProjectName
          ? row.postedByMeProjectName
          : row.invitedProjectName)
    );
    setBidId(row.bidId);
    setSelectRows(row);
    dispatch(setProjectId(row.projectId));
  };

  const handleMyBids = () => {
    props.setBackButton(true);
    props.getSubMenu(SubmenuEnum.MY_BIDS);
    setActiveMenu(SubmenuEnum.MY_BIDS);
    setUserActivity(" ");
  };

  const handlePostBids = () =>{
    dispatch(setActiveStepAction(0));
    dispatch(setSelectedTradesDetails([]));
    dispatch(setDocumentDetails([]));
    dispatch(setBasicFormDetails({}));
    props.setBackButton(true);
    setActiveMenu(SubmenuEnum.POST_BIDS);
    setSelectRows([]);
    dispatch(setProjectId(undefined));
    dispatch(setPostProjectRespStatus(""));
  }

  const handleBidOpportClick = () => {
    props.getSubMenu(SubmenuEnum.BID_OPPORTUNITIES_NETWORK);
    setActiveMenu(SubmenuEnum.BID_OPPORTUNITIES_NETWORK);
    props.setBackButton(true);
  };

  const handleOnSelectCell = (cell: any) => {
    if (cell.length === 0) {
      setActiveMenu(SubmenuEnum.EDIT_PROJECT);
    } else {
      setActiveMenu(SubmenuEnum.INVITED_BIDS);
    }
  };

  const renderSubMenu = () => {
    switch (activeMenu) {
      case SubmenuEnum.BID_OPPORTUNITIES_NETWORK:
        return <BidExploreOpportunities />;
      case SubmenuEnum.MY_BIDS:
        return (
          <MyBidsTable
            onSelectProjectRow={handleSelectRow}
            bidId={bidId}
            onSelectCell={handleOnSelectCell}
          />
        );
      case SubmenuEnum.EDIT_PROJECT:
      case SubmenuEnum.POST_BIDS:
        return (
          <ProjectBids
            selectRows={selectRows}
            userActivity={userActivity}
            setBackButton={props.setBackButton}
            activeMenu={activeMenu}
          />
        );
      case SubmenuEnum.INVITED_BIDS:
        return (
          <InvitedBidDetailForm
            selectRows={selectRows}
            userActivity={userActivity}
            setBackButton={props.setBackButton}
            activeMenu={activeMenu}
            bidId={bidId}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="row gx-0 mt-2 mb-2">
        <Button
          className="btn col-2 offset-2  mb-1"
          style={{
            backgroundColor: color.darkColor,
            color: "white",
            opacity:
              activeMenu === SubmenuEnum.MY_BIDS ||
              activeMenu === SubmenuEnum.EDIT_PROJECT ||
              activeMenu === SubmenuEnum.INVITED_BIDS
                ? 1
                : 0.7,
          }}
          onClick={handleMyBids}
        >
          My Bids
        </Button>
        <Button
          className="btn col-2 offset-2 ms-4 mb-1"
          style={{
            backgroundColor: color.darkColor,
            color: "white",
            opacity:
              activeMenu === SubmenuEnum.BID_OPPORTUNITIES_NETWORK ? 1 : 0.7,
          }}
          onClick={handleBidOpportClick}
        >
          Bid Opportunities Network
        </Button>
        <Button
          className="btn col-1 ms-3 mb-1"
          style={{
            backgroundColor: color.darkColor,
            color: "white",
            opacity: activeMenu === SubmenuEnum.POST_BIDS ? 1 : 0.7,
          }}
          onClick={handlePostBids}
        >
          Post Bid
        </Button>
      </div>
      <div className="overflow-hidden w-100" style={{ height: "80vh" }}>
        {!props.isBack ? (
          <MyBidsTable
            onSelectProjectRow={handleSelectRow}
            onSelectCell={handleOnSelectCell}
            bidId={bidId}
          />
        ) : (
          renderSubMenu()
        )}
      </div>
    </>
  );
};

export default MyBidsInternal;
