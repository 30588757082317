import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Snackbar } from '@mui/material'
import React from 'react'

interface Props{
    openSnackBar: boolean;
    message:string
    handCloseSnackBar?: () => void;
    duration?:number | null | undefined

}

const CustomizedSnackbar = ({openSnackBar,message,handCloseSnackBar,duration = 3000} : Props) => {

    const action = (
      <React.Fragment>
        <FontAwesomeIcon
          className="pointer"
          icon={faClose}
          size="xl"
          onClick={handCloseSnackBar}
        />
      </React.Fragment>
    );
    
  return (
    <Snackbar
    open={openSnackBar}
    autoHideDuration={duration}
    onClose={handCloseSnackBar}
    message={message}
    action={action}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
  />
  )
}
export default CustomizedSnackbar
