import { useState } from "react";
import { Link } from "react-router-dom";
import GlobalHeader from "../GlobalHeader/globalHeader";
import { Button, Typography } from "@mui/material";
import InputField from "../InputField";
import { resentEmailVarification } from "../../utils/api";
import CustomizedSnackbar from "../CustomizedSnackbar";

const ForgotPassword = () => {
  
  const [email, setEmail] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [showSnackBar, setShowsnackBar] = useState(false);
  const [message, setMessage] = useState("");
  const [resentLink, setResetLink] = useState<boolean>(false);

  const handleResentEmail = () => {
    resentEmailVarification("",email,undefined)
    .then((response)=>{
      setShowsnackBar(true);
      setMessage(response.message);
    })
    .catch((error : any)=>{
      setShowsnackBar(true);
      setMessage(error.message);
    });
    setOpen(true);
    setResetLink(true);
  };

  return (
    <>
      <div className="password-setting row mx-auto" style={{ marginTop: "10%" }}>
        <GlobalHeader
          title={!open ? "Forgot password?" : ""}
          isLogoVisible={true}
        />
        {open ? (
          <>
            <div className="container mt-3 shadow">
              <div className="row">
                <p className="col-12 col-sm-12 col-md-12 col-lg-12 opacity-75 size-lg">
                  If an account exists for{" "}
                  <span className="text-primary opacity-100">{email} </span>, we
                  have sent instructions for resetting your password. Didn’t
                  receive the email? Double-check the email address or request
                  to resend the instructions.
                </p>
              </div>
              <div className="row">
                <Button className="btn btn-bg col-3 col-sm-3 col-md-3 col-lg-3 ms-2  mt-3 py-2">
                  <Link className="text-white text-decoration-none"
                    to={"/login"}
                  >
                    Back to Sign in
                  </Link>
                </Button>
              </div>
              <div className="row">
                <Link
                  className="col-5 col-sm-5 col-md-5 col-lg-5 gx-3 mt-2 mt-3 fs-6"
                  to={"/forget-password"}
                  onClick={handleResentEmail}
                  style={{fontFamily:'SenticosansDTCond Thin'}}
                >
                  Resend the instruction again
                </Link>
              </div>
            </div>
          </>
        ) : (
          <div className="container shadow bg-light">
            <div className="row">
              <Typography
                className="col-12 col-sm-12 col-md-12 col-lg-12 opacity-50 mt-2 d-flex justify-content-center"
                paragraph={true}
                fontSize={"18px"}
                style={{fontFamily:'SenticosansDTCond Thin'}}
              >
                Enter the email address you registered,and we'll send you a
                reset password instruction via email.
              </Typography>
            </div>
            <div className="row mt-3 gx-0">
              <form className="form-group">
                <div className="col-8 col-sm-8 col-md-8 col-lg-8 mx-auto">
                  <InputField
                    id="email"
                    name="email"
                    value={email}
                    type="text"
                    handleChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    label="Email"
                    required
                  />
                </div>
                <div className="row mt-3">
                  <Button
                    className="btn btn-bg col-3 col-sm-3 col-md-3 col-lg-3 mx-auto mt-3 mb-3"
                    onClick={(e) => {
                      e.preventDefault();
                      handleResentEmail();
                    }}
                    disabled={email === "" ? true : false}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <CustomizedSnackbar openSnackBar={showSnackBar} message={message} handCloseSnackBar={()=> setShowsnackBar(false)} />
    </>
  );
};

export default ForgotPassword;
