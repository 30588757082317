import { faClose, faExpand, faMinus, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress, styled } from '@mui/material';
import Modal from "react-modal";
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { askQuestionEmail } from '../../../utils/api';
import { selectProjectId, selectThemeColor } from '../../../redux/selectors/selectors';
import InputField from '../../InputField';
import AttachFileIcon from "@mui/icons-material/AttachFile";

interface Props{
    isOpen:boolean;
    handleClose: () => void;
    handleSubmit: (sendQueMailType : SendQueMailType) => void;
    isLoading: boolean;
}

type SendQueMailType = {
  subject: string;
  emailBody: string;
  files: {
    name: string;
    files: File[];
  }[];
};

const AskQuestionsModal = ({
  isOpen = false,
  handleClose,
  handleSubmit,
  isLoading = false,
}: Props) => {
  const [minimize, setMinimize] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [sendQueMail, setSendQueMail] = useState<SendQueMailType>({
    subject: "",
    emailBody: "",
    files: [],
  });

  const color = useSelector(selectThemeColor);
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setSendQueMail({
      ...sendQueMail,
      [name]: value,
    });
  };

  const handleSetAttachMent = (files: FileList | null | File[]) => {
    let fileArray: {
      name: string;
      files: File[];
    }[] = [];

    if (files) {
      Array.from(files).forEach((file: any) => {
        fileArray.push({
          name: file.name,
          files: Array.from(files),
        });
      });
    }
    return fileArray;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const files = event.target.files;
    console.log("files", files);
    if (files) {
      setSendQueMail({
        ...sendQueMail,
        files: handleSetAttachMent(files),
      });
      setSelectedFiles((prevRows: any) => [...prevRows, ...handleSetAttachMent(files)]);
    }
  };

  const handleFileInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = "";
  };

  //ask question mail send related functions:
  const handleRemoveSelectedFile = (index: number) => {
    let removeSelectedFiles = [...selectedFiles].filter(
      (file: File, fileIndex : number) => fileIndex !== index
    );
    setSelectedFiles(removeSelectedFiles);
    setSendQueMail({
      ...sendQueMail,
      files: handleSetAttachMent(removeSelectedFiles),
    });
  };

  return (
    <Modal
      id="viewInvite"
      className="position-fixed m-2"
      isOpen={isOpen}
      ariaHideApp={false}
      style={{
        content: {
          position: "fixed",
          top: "auto",
          left: "auto",
          right: minimize ? "10px" : "0",
          bottom: minimize ? "10px" : "0",
          width: minimize ? "500px" : "500px",
          height: minimize ? "45px" : "535px",
          padding: minimize ? "0px" : "0",
          borderRadius: "10px",
          backgroundColor: "white",
          border: "1px solid #ccc",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
          transition: "width 0.3s ease, height 0.3s ease",
        },
        overlay: {
          backgroundColor: minimize ? "transparent" : "rgba(0, 0, 0, 0.7)",
          zIndex: !minimize ? 0 : 1000,
          pointerEvents: !minimize ? "auto" : "none",
          transition: "background-color 0.3s ease, opacity 0.3s ease",
          opacity: 1,
        },
      }}
    >
      <div className="modal-dialog modal-2x" style={{ width: "500px" }}>
        <div className="modal-content justify-content-center">
          <div className="modal-header row gx-0">
            <div className="col-6"></div>
            <div className="col-4 offset-2">
              <FontAwesomeIcon
                className="col-2 offset-4 pointer"
                icon={minimize ? faExpand : faMinus}
                size="xl"
                color={color.mediumColor}
                onClick={() => setMinimize(!minimize)}
              />
              <FontAwesomeIcon
                className="col-2 pointer"
                icon={faClose}
                size="xl"
                color={color.mediumColor}
                onClick={() => {
                  setMinimize(false);
                  setSelectedFiles([]);
                  handleClose();
                }}
              />
            </div>
          </div>
          {!minimize && (
            <div
              className="modal-body"
              style={{ width: "500px", height: "400px", overflowY: "scroll" }}
            >
              <div className="row m-1">
                <InputField
                  className="form-control bg-white col-12"
                  id="subject"
                  type="text"
                  name="subject"
                  label="subject"
                  handleChange={handleChange}
                />
              </div>
              <div className="row m-1 mt-3">
                <InputField
                  id="emailBody"
                  name="emailBody"
                  type="text"
                  className="col-12"
                  label="Add Text Here"
                  multiline
                  rows={12}
                  handleChange={handleChange}
                />
              </div>
              {selectedFiles.length > 0 && (
                <div
                  className="row m-3"
                  style={{
                    height: "5rem",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    border: `3px dashed ${color.mediumColor}`,
                  }}
                >
                  <ul className="list-group list-group-numbered">
                    {selectedFiles.map((file, index) => (
                      <li
                        key={index}
                        className="list-group-item d-flex align-items-center m-1"
                      >
                        <span className="d-flex gap-3">
                          <span>{file.name}</span>
                          <FontAwesomeIcon
                            className="pointer mt-1"
                            icon={faRemove}
                            onClick={() => handleRemoveSelectedFile(index)}
                          />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
          {!minimize && (
            <div className="footer">
              <div className="row  gx-0">
                <div className="col-5 ms-2">
                  <Button
                    className="btn border"
                    component="label"
                    role={undefined}
                    variant="text"
                    tabIndex={-1}
                    size="small"
                    style={{ background: "transparent" }}
                  >
                    <AttachFileIcon
                      className="fs-4"
                      style={{ color: color.darkColor }}
                    />
                    Attach files
                    <VisuallyHiddenInput
                      multiple
                      type="file"
                      onChange={handleFileChange}
                      onClick={handleFileInputClick}
                    />
                  </Button>
                </div>
                <div className="col-3 offset-3 gap-2">
                  <Button
                    className="btn ms-5"
                    style={{ background: color.darkColor, color: "#fff" }}
                    onClick={() => handleSubmit(sendQueMail)}
                  >
                    {isLoading ? (
                      <CircularProgress
                        className="ms-1"
                        color="info"
                        variant="indeterminate"
                        thickness={2}
                        size={26}
                      />
                    ) : (
                      "Send"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AskQuestionsModal;
