import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useContext, useEffect, useState } from "react";
import BiddingBids from './BidsTypes/biddingBids';
import CloseBids from './BidsTypes/closeBids';
import MayBeBids from './BidsTypes/mayBeBids';
import InvitedBids from './BidsTypes/invitedBids';
import RejectedBids from './BidsTypes/rejectedBids';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FilePreview } from "../../FilePreview";
import { ProjectDocumentList } from "../../../utils/const";
import UseSelectDocRow from "../../../hooks/useSelectDocRow";
import { DocumentTable } from "../../DataTable";
import { ChatMessageBox, FloatingChatIcon } from "../../ChatBox";
import "./myBids.css";
import InputField from "../../InputField";
import PublicBids from "./BidsTypes/publicBids";
import { Label } from '@mui/icons-material';
import { getBidsDetails } from '../../../utils/api';
import { useSelector } from 'react-redux';
import { selectThemeColor } from '../../../redux/selectors/selectors';


const style = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-80%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
    opacity: "1",
    background: "#f8f9fa",
  },
};

const RowData = [
  {
    documentType: "Soil Report",
    fileList: [{}],
    type: [],
    selectedFiles: ["SoilReport-01-06-24.png"],
    size: ["373.12"],
  },
  {
    documentType: "Drawing",
    fileList: [{}],
    selectedFiles: [
      "Drawing-15-06-24.png",
    ],
    size: ["389.08"],
  },
  {
    documentType: "Plans",
    fileList: [{}, {}],
    type: [],
    selectedFiles: [
      "Plans-14-08-24.png",
      "Screenshot 2024-05-31 at 12.56.55 PM.png",
    ],
    size: ["603.03", "257.77"],
  },
];

const MyBids = (props: any) => {
  const docSelectedHeaderCells = [
    { id: "documentFor", name: "Document Type", action: "type" },
    { id: "selectedFiles", name: "Document", action: "option" },
    { id: "size", name: "Size (kb)", action: "" },
    { id: "action", name: "Action", action: "view" },
  ];

  const [tabValue, setTabValue] = useState('inProgress');
  const [expanded, setExpanded] = useState<string | false>("");
  const [selectedPrivacyType, setSelectedPrivacyType] = useState<string>("");
  const [isOpenChatMessage, setIsOpenChatMessage] = useState(false);

  const [selectedRow, setSelectedRow] = useState<any>({});

  const [tableRowData, setTableRowData] = useState<any>({});

  useEffect(() => {
    getBidsDetails("", tabValue, undefined).then((resp) => {
      const data = resp.data;
      setTableRowData(data);
    }).catch((e) => {
      console.log('e :>> ', e);
    })
  }, [tabValue])


  const [selectedDocument, setSelectedDocument] = useState('');

  const {
    selectedDocRows,
    handleUpdateDocRows,
    handleView,
    viewFiles,
    indexImage,
    handleDeleteRow,
  } = UseSelectDocRow({ selectedDocument, selectedPrivacyType });
  const [modalIsOpen, setIsOpen] = useState(false);

  const color = useSelector(selectThemeColor);

  const handleChange = (event: any, newValue: string) => {
    setTabValue(newValue);
  };
  const handleExpandeChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      handleUpdateDocRows(Array.from(files));
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const [alertMessage, setAlertMessage] = useState("");
  const [showSnackBar, setShowsnackBar] = useState(false);
  const action = (
    <>
      <FontAwesomeIcon
        className="pointer"
        icon={faClose}
        size="xl"
        onClick={() => setShowsnackBar(false)}
      />
    </>
  );

  const projectDocumentList = ProjectDocumentList;
  const handleSelectChange = (event: any) => {
    setSelectedDocument(event.target.value);
  };
  const handleViewClick = (viewIndex: number, type: string) => {
    handleView(viewIndex, type);
    setIsOpen(true);
  };

  const viewAccordianBidsSection = (
    <div className="col-11">
      <div
        className="form-floating row justify-content-center mx-3 p-1 border shadow"
        style={{
          maxHeight: "70vh",
          overflow: "hidden",
          backgroundColor: "#f6f7f7",
        }}
      >
        <div
          className="mt-1"
          style={{
            maxHeight: "45vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Accordion
            expanded={expanded === "1"}
            onChange={handleExpandeChange("1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              style={{
                background: color.lightColor,
                justifyContent: "space-between",
              }}
            >
              <div>Bid Details</div>

              <div></div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="col-12 mx-auto">
                <h3 className="d-flex justify-content-center size-2xl">
                  <span className="opacity-70 me-1">Title</span>:{" "}
                  {selectedRow.projectName}
                </h3>
              </div>

              <div className="row">
                <div className="row justify-content-center ms-2 mt-2">
                  <div className="col-md-5 col-sm-10 mt-1 my-2">
                    <InputField
                      id="bidDate"
                      name="bidDate"
                      type="text"
                      value={selectedRow.bidDate}
                      className="form-control"
                      label="Bid Date"
                      readonly={true}
                    />
                  </div>
                  <div className="col-md-5 col-sm-10 mt-1 my-2">
                    <InputField
                      id="sector"
                      name="sector"
                      type="text"
                      value={selectedRow.sector}
                      className="form-control"
                      label="Sector"
                      readonly={true}
                    />
                  </div>
                  <div className="col-md-5 col-sm-10 mt-1 my-2">
                    <InputField
                      id="constructionType"
                      name="contsructionType"
                      type="text"
                      value={selectedRow.constructionType}
                      className="form-control"
                      label="Construction Type"
                      readonly={true}
                    />
                  </div>
                  <div className="col-md-5 col-sm-10 mt-1 my-2"></div>
                </div>
              </div>

              <div className="row">
                <div className="row justify-content-center ms-2 mt-3">
                  <div className="col-md-5 col-sm-10 mt-1 my-2">
                    <InputField
                      id="addressOne"
                      name="addressOne"
                      type="text"
                      value={selectedRow.address1}
                      className="form-control"
                      label="Address 1"
                      readonly={true}
                    />
                  </div>
                  <div className="form-floating col-md-5 col-sm-10 mt-1 my-2">
                    <InputField
                      id="addressTwo"
                      name="addressTwo"
                      type="text"
                      value={selectedRow.address2}
                      className="form-control"
                      label="Address 2"
                      readonly={true}
                    />
                  </div>
                </div>

                <div className="row justify-content-center ms-2 mt-3">
                  <div className="col-md-5 col-sm-10 mt-1 my-2">
                    <InputField
                      id="state"
                      name="state"
                      type="text"
                      value={selectedRow.state}
                      className="form-control"
                      label="State"
                      readonly={true}
                    />
                  </div>
                  <div className="col-md-5 col-sm-10 mt-1 my-2">
                    <InputField
                      id="city"
                      name="city"
                      type="text"
                      value={selectedRow.city}
                      className="form-control"
                      label="City"
                      readonly={true}
                    />
                  </div>
                </div>
                <div className="row justify-content-center ms-2 mt-3">
                  <div className="form-floating col-md-5 col-sm-10 mt-1 my-2">
                    <InputField
                      id="zipCode"
                      name="zipCode"
                      type="text"
                      value={selectedRow.zipCode}
                      className="form-control"
                      label="Zip Code"
                      readonly={true}
                    />
                  </div>

                  <div className="form-floating col-md-5 col-sm-10 mt-1 my-2"></div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div
          className="mt-1"
          style={{
            maxHeight: "45vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Accordion
            expanded={expanded === "2"}
            onChange={handleExpandeChange("2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ background: color.lightColor }}
            >
              Documents
            </AccordionSummary>
            <AccordionDetails>
              <div className="form-floating row justify-content-center p-1 mt-3">
                <div className="row justify-content-center ms-2 mt-3 gx-4">
                  <div className="col-10 col-md-10 col-sm-10 p-0">
                    <DocumentTable
                      headCells={docSelectedHeaderCells}
                      rows={RowData}
                      onViewRow={() => null}
                    />
                  </div>
                </div>

                <div className="col-10 mt-4 p-0">
                  <h4 className="opacity-70 p-0 size-xl">Upload Documents :</h4>
                </div>

                <div className="row align-items-center g-0">
                  <div className="form-floating col-5 col-md-5 col-sm-5 offset-1">
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-label"
                        size="small"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "14px",
                          fontFamily: "SenticosansDTCond Thin",
                        }}
                      >
                        Select Document
                      </InputLabel>
                      <Select
                        className="bg-white"
                        labelId="select-label"
                        id="documentName"
                        name="documentName"
                        label="Select Document"
                        size="small"
                        onChange={handleSelectChange}
                      >
                        <MenuItem value="" key={0}></MenuItem>
                        {projectDocumentList.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-4 col-md-4 col-sm-4 p-2 offset-1">
                    <input
                      type="file"
                      multiple
                      className="form-control"
                      id="inputGroupFile02"
                      name="selectedFiles"
                      onChange={handleFileChange}
                      disabled={!selectedDocument}
                    />
                  </div>
                </div>
                {selectedDocRows.length > 0 && (
                  <div className="row justify-content-center mt-3 gx-4">
                    <div className="col-10 col-md-10 col-sm-10 ms-4 p-0">
                      <div className="row  mt-3">
                        <div className="col-12 col-md-12 col-sm-12 p-0">
                          <DocumentTable
                            headCells={docSelectedHeaderCells}
                            rows={selectedDocRows}
                            onViewRow={handleViewClick}
                            onDeleteRowClick={handleDeleteRow}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <Modal
                  id="viewDocument"
                  isOpen={modalIsOpen}
                  style={style}
                  ariaHideApp={false}
                >
                  <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                      <div className="modal-header mb-2">
                        <div className="mx-auto fw-bold">View Document</div>
                        <Button
                          type="button"
                          className="btn-close"
                          data-mdb-dismiss="modal"
                          aria-label="Close"
                          onClick={handleClose}
                        ></Button>
                      </div>
                      <div
                        className="modal-body mt-4"
                        style={{ maxWidth: "700px", maxHeight: "700px" }}
                      >
                        <div className="row">
                          <div key={indexImage}>
                            {/* <FilePreview
                              file={[viewFiles[0]?.files[indexImage]][0]}
                              {...viewFiles[0]?.files}
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div
          className="mt-1"
          style={{
            maxHeight: "45vh",
          }}
        >
          <div
            style={{
              maxHeight: "60vh",
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <Accordion
              expanded={expanded === "3"}
              onChange={handleExpandeChange("3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{ background: color.lightColor }}
              >
                Q&A
              </AccordionSummary>
              <AccordionDetails>
                <div className="row">
                  <ChatMessageBox
                    receiverName={selectedRow.projectOwnerName}
                    handleClose={() => setIsOpenChatMessage(false)}
                    sender={"self"}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <div className="row justify-content-center mt-1">
          <Button
            className="btn bids-button col-2"
            style={{
              backgroundColor: color.darkColor,
              color: "white",
              width: '200px',
            }}
            onClick={() => {
              setAlertMessage("Bid submit successfully");
              setShowsnackBar(true);
            }}
          >
            Submit
          </Button>
        </div>
        {false &&
          (isOpenChatMessage ? (
            <ChatMessageBox
              receiverName={selectedRow.projectOwnerName}
              handleClose={() => setIsOpenChatMessage(false)}
              sender={"Self"}
            />
          ) : (
            <FloatingChatIcon onToggleChat={() => setIsOpenChatMessage(true)} />
          ))}
      </div>
    </div>
  );

  const viewBidsSection = (
    <div className="col-11">
      <div
        className="form-floating row justify-content-center mx-3 p-1 border shadow"
        style={{
          maxHeight: "68vh",
          overflowY: "scroll",
          overflowX: "hidden",
          backgroundColor: "#f6f7f7",
        }}
      >
        <div className="col-12 mt-3 mx-auto">
          <h3 className="d-flex justify-content-center size-2xl">
            <span className="opacity-70 me-1">Title</span>:{" "}
            {selectedRow.projectName}
          </h3>
        </div>
        <div className="row justify-content-center ms-2 mt-3">
          <div className="form-floating col-md-10 col-sm-10">
            <InputField
              id="orgName"
              name="orgName"
              type="text"
              value={selectedRow.projectOwnerName}
              className="form-control"
              label="Organization Name"
              readonly={true}
            />
          </div>
        </div>
        <div className="col-10 mt-3 p-0">
          <h5 className="opacity-70 p-0 size-xl">Bid Information :</h5>
        </div>
        <div className="row">
          <div className="row justify-content-center ms-2 mt-3">
            <div className="form-floating col-md-5 col-sm-10 mt-1 my-2">
              <InputField
                id="bidDate"
                name="bidDate"
                type="text"
                value={selectedRow.bidDate}
                className="form-control"
                label="Bid Date"
                readonly={true}
              />
            </div>
            <div className="form-floating col-md-5 col-sm-10 mt-1 my-2">
              <InputField
                id="sector"
                name="sector"
                type="text"
                value={selectedRow.sector}
                className="form-control"
                label="Sector"
                readonly={true}
              />
            </div>
            <div className="form-floating col-md-5 col-sm-10 mt-1 my-2">
              <InputField
                id="constructionType"
                name="contsructionType"
                type="text"
                value={selectedRow.constructionType}
                className="form-control"
                label="Construction Type"
                readonly={true}
              />
            </div>
            <div className="form-floating col-md-5 col-sm-10 mt-1 my-2"></div>
          </div>
        </div>

        <div className="col-10 mt-3 p-0">
          <h5 className="opacity-70 p-0 size-xl">Address Information :</h5>
        </div>
        <div className="row">
          <div className="row justify-content-center ms-2 mt-3">
            <div className="form-floating col-md-5 col-sm-10 mt-1 my-2">
              <InputField
                id="addressOne"
                name="addressOne"
                type="text"
                value={selectedRow.address1}
                className="form-control"
                label="Address 1"
                readonly={true}
              />
            </div>
            <div className="form-floating col-md-5 col-sm-10 mt-1 my-2">
              <InputField
                id="addressTwo"
                name="addressTwo"
                type="text"
                value={selectedRow.address2}
                className="form-control"
                label="Address 2"
                readonly={true}
              />
            </div>
          </div>

          <div className="row justify-content-center ms-2 mt-3">
            <div className="col-md-5 col-sm-10 mt-1 my-2">
              <InputField
                id="state"
                name="state"
                type="text"
                value={selectedRow.state}
                className="form-control"
                label="State"
                readonly={true}
              />
            </div>
            <div className="form-floating col-md-5 col-sm-10 mt-1 my-2">
              <InputField
                id="city"
                name="city"
                type="text"
                value={selectedRow.city}
                className="form-control"
                label="City"
                readonly={true}
              />
            </div>
          </div>

          <div className="row justify-content-center ms-2 mt-3">
            <div className="form-floating col-md-5 col-sm-10 mt-1 my-2">
              <InputField
                id="zipCode"
                name="zipCode"
                type="text"
                value={selectedRow.zipCode}
                className="form-control"
                label="Zip Code"
                readonly={true}
              />
            </div>

            <div className="form-floating col-md-5 col-sm-10 mt-1 my-2"></div>
          </div>
        </div>
      </div>
      {tabValue === "inProgress" && (
        <div className="row justify-content-center mt-3">
          <Button
            className="btn bids-button col-2 mt-1"
            style={{
              backgroundColor: color.darkColor,
              color: "white",
              width: '200px',
            }}
            onClick={() => {
              setAlertMessage("Bid submit successfully");
              setShowsnackBar(true);
            }}
          >
            Submit
          </Button>
        </div>
      )}
      {tabValue === "invited" && (
        <div className="row justify-content-center mt-3">
          <Button
            className="btn bids-button col-3  mt-1"
            style={{
              backgroundColor: color.darkColor,
              color: "white",
              width: '200px',
            }}
            onClick={() => {
              setAlertMessage("Bid accepted successfully");
              setShowsnackBar(true);
            }}
          >
            Accept Invitation
          </Button>
          <Button
            className="btn bids-button col-3  mt-1"
            style={{
              backgroundColor: color.darkColor,
              color: "white",
              width: '200px',
            }}
            onClick={() => {
              setAlertMessage("Bid rejected successfully");
              setShowsnackBar(true);
            }}
          >
            Reject Invitation
          </Button>
          <Button
            className="btn bids-button col-2  mt-1"
            style={{
              backgroundColor: color.darkColor,
              color: "white",
              width: '200px',
            }}
            onClick={() => {
              setAlertMessage("Bid successfully added in may be");
              setShowsnackBar(true);
            }}
          >
            May Be
          </Button>
        </div>
      )}
      {tabValue === "mayBe" && (
        <div className="row justify-content-center mt-3">
          <Button
            className="btn bids-button col-3  mt-1"
            style={{
              backgroundColor: color.darkColor,
              color: "white",
              width: '200px',
            }}
            onClick={() => {
              setAlertMessage("Bid accepted successfully");
              setShowsnackBar(true);
            }}
          >
            Accept Invitation
          </Button>
          <Button
            className="btn bids-button col-3  mt-1"
            style={{
              backgroundColor: color.darkColor,
              color: "white",
              width: '200px',
            }}
            onClick={() => {
              setAlertMessage("Bid rejected successfully");
              setShowsnackBar(true);
            }}
          >
            Reject Invitation
          </Button>
        </div>
      )}
    </div>
  );

  const tabVlues = [
    { label: "In Progress", value: "inProgress" },
    { label: "Invited", value: "invited" },
    { label: "May Be", value: "mayBe" },
    { label: "Rejected", value: "rejected" },
    { label: "Closed", value: "closed" },
    { Label: "Public Bids", value: 'publicBids' }
  ];

  return (
    <div className="d-flex justify-content-center">
      {props.isBack ? (
        (tabValue === "inProgress") ? viewAccordianBidsSection : viewBidsSection
      ) : (
        <Box sx={{ width: "97%", typography: "body2" }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label=""
                sx={{
                  color: "#e9f6dc",
                  borderRadius: "4px",
                  "& .MuiTabs-indicator": {
                    backgroundColor: "black",
                  },
                }}
              >
                {
                  tabVlues.map((tab) => {
                    return (
                      <Tab
                        label={tab.label}
                        value={tab.value}
                        sx={{
                          textTransform: "capitalize",
                          color: "grey",
                          "&.Mui-selected": {
                            color: "black",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    )
                  })
                }
              </TabList>
            </Box>
            <TabPanel className="p-0 pt-3" value="inProgress">
              <BiddingBids
                setSelectedRow={setSelectedRow}
                tableRowData={tableRowData}
                bidProps={props}
                setBack={props.setBackButton}
                getSubMenu={props.getSubMenu}
              />
            </TabPanel>
            <TabPanel className="p-0 pt-3" value="invited">
              <InvitedBids
                setSelectedRow={setSelectedRow}
                tableRowData={tableRowData}
                bidProps={props}
                setBack={props.setBackButton}
                getSubMenu={props.getSubMenu}
              />
            </TabPanel>
            <TabPanel className="p-0 pt-3" value="mayBe">
              <MayBeBids
                setSelectedRow={setSelectedRow}
                tableRowData={tableRowData}
                bidProps={props}
                setBack={props.setBackButton}
                getSubMenu={props.getSubMenu}
              />
            </TabPanel>
            <TabPanel className="p-0 pt-3" value="rejected">
              <RejectedBids
                setSelectedRow={setSelectedRow}
                tableRowData={tableRowData}
                bidProps={props}
                setBack={props.setBackButton}
                getSubMenu={props.getSubMenu}
              />
            </TabPanel>
            <TabPanel className="p-0 pt-3" value="closed">
              <CloseBids
                setSelectedRow={setSelectedRow}
                tableRowData={tableRowData}
                bidProps={props}
                setBack={props.setBackButton}
                getSubMenu={props.getSubMenu}
              />
            </TabPanel>
            <TabPanel className="p-0 pt-3" value="publicBids">
              <PublicBids
                setSelectedRow={setSelectedRow}
                tableRowData={tableRowData}
                bidProps={props}
                setBack={props.setBackButton}
                getSubMenu={props.getSubMenu}
              />
            </TabPanel>
          </TabContext>
        </Box>
      )}
      <Snackbar
        open={showSnackBar}
        autoHideDuration={3000}
        onClose={() => setShowsnackBar(false)}
        message={alertMessage}
        action={action}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      />
    </div>
  );
};

export default MyBids;
