import { useContext, useState } from "react";
import CreateUser from "./CreateUser/createUser";
import UsersList from "./UsersList";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import Utils from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { selectThemeColor } from "../../redux/selectors/selectors";
import { SubmenuEnum } from "../../utils/enum";

const ManageUser = (props: any) => {
  const color = useSelector(selectThemeColor);
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    touched,
    isSubmit,
    onSubmit,
  } = Utils();


  const [activeMenu, setActiveMenu] = useState(SubmenuEnum.ADD_USER);

  const handleEditSelect = (row: any) => {
    props.getSubMenu(SubmenuEnum.EDIT_USER);
    setActiveMenu(SubmenuEnum.EDIT_USER);
    setFieldValue("id",row.id);
    setFieldValue("firstName", row.first_name.split(" ")[0]);
    setFieldValue("lastName", row.first_name.split(" ")[1]);
    setFieldValue("email", row.email);
    setFieldValue("phoneNo", row.phone_number);
    setFieldValue("isActive", row.status === "A" ? true : false);
    console.log("row values", row);
  };
  const dispatch = useDispatch();

  return (
    <>
      {!props.isBack ? (
        <>
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              className="btn mb-2"
              style={{ backgroundColor: color.darkColor, color: "white" }}
              onClick={() => {
                props.setBackButton(true);
                props.getSubMenu(SubmenuEnum.ADD_USER);
                setActiveMenu(SubmenuEnum.ADD_USER);
                const fields = ["firstName", "lastName", "email", "phoneNo"];
                fields.forEach((field) => setFieldValue(field, ""));
                setFieldValue("isActive", false);
              }}
            >
              <FontAwesomeIcon className="mx-1" icon={faUserPlus} size="sm" />
              <span>Add User</span>
            </Button>
          </div>
          <UsersList
            setBack={props.setBackButton}
            getSubMenu={props.getSubMenu}
            onEditSelect={handleEditSelect}
          />
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between m-2"></div>
          <CreateUser
            setBackButton={props.setBackButton}
            isBack={props.isBack}
            values={values}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            isSubmit={isSubmit}
            onSubmit={onSubmit}
            activeMenu={activeMenu}
          />
        </>
      )}
    </>
  );
};

export default ManageUser;
