import * as Yup from "yup";
import { useFormik } from "formik";
import { OnboardFormState } from "../../utils/types";

const Utils = (props: any) => {

  const ValidationSchema = () => {
    switch (props) {
      case 0:
        return Yup.object().shape({
          orgName: Yup.string()
            .required("Required!")
            .trim(),
          name: Yup.string()
            .required("Required!")
            .trim(),
          email: Yup.string().email("Invalid email").required("Required!").trim(),
          phoneNumber: Yup.string()
            .matches(
              /^((\\+[1-9]{1,4}[ \-]*)|(\\([0-9]{2,3}\\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/,
              "Phone number is not valid"
            ).trim()
            .required("Required!"),
          password: Yup.string()
            .required("Required!")
            .min(
              8,
              "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special eg. Unnipatt@123"
            )
            .matches(/[a-z]+/, "Atleast One lowercase character")
            .matches(/[A-Z]+/, "Atleast One uppercase character")
            .matches(/[@$!%*#?&]+/, "Atleast One special character")
            .matches(/\d+/, "Atleast One number")
            .matches(/^\S*$/, "White Spaces are not allowed"),
          confirmPassword: Yup.string()
            .required("Required!")
            .oneOf([Yup.ref("password")], "Passwords must match"),
          addressOne: Yup.string().trim(),
          addressTwo: Yup.string().trim(),
          state: Yup.string().required("Required!"),
          city: Yup.string().required("Required!").trim(),
          zip: Yup.string()
            .required("Required!")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(5, "Must be exactly 5 digits")
            .max(5, "Must be exactly 5 digits"),
          taxNo: Yup.string().matches(/^[a-zA-Z0-9]*$/, "Invalid Field"),
          licenseNo: Yup.string().matches(/^[a-zA-Z0-9]*$/, "Invalid Field"),
        });
      case 1:
        return Yup.object().shape({
          laborAffiation: Yup.string().required("Required!"),
          classifications: Yup.array()
          .min(1, 'At least one classifications is required')
        });
      case 2:
        return Yup.object().shape({
          tradeList: Yup.array()
          .min(1, 'At least one trade is required')
          .required("Required!")
        });
      default:
        return Yup.object().shape({});
    }
  };

  const decryptPassword = (encryptPasswordString : string) =>{
    let passwordInBytes = converStringToBytes(encryptPasswordString);
    let bytesRandomPositionChange = randomRevPosGenerator(passwordInBytes);
    let decryptPassword = converBytesToString(bytesRandomPositionChange);
  };

  const randomRevPosGenerator = (bytes: any)=>{
    console.log("bytes", bytes);
    if (bytes) {
      const bytesArray: any = new Uint8Array(bytes);
      bytes.forEach((byte: any, index : number) => {
        if(index === 2){
          bytesArray[index]=(bytes[index+1])
         }
      });
      return bytesArray.buffer;
    }
    return;
  };

  const encryptPassword = (password : string) =>{
    let passwordInBytes = converStringToBytes(password);
    let bytesRandomPositionChange = randomPosGenerator(passwordInBytes)
    let encryptPassword = converBytesToString(bytesRandomPositionChange);
    console.log('encryptPassword', encryptPassword)
    decryptPassword(encryptPassword)
    return encryptPassword;
  };

  const DefaultOnboardFormData: OnboardFormState = {
    orgName: "",
    password: "",
    confirmPassword: "",
    name: "",
    email: "",
    phoneNumber: "",
    addressOne: "",
    addressTwo: "",
    state: "",
    zip: "",
    licenseNo: "",
    dirDate: null,
    classifications: [],
    projectEstName: "",
    projectEstEmail: "",
    projectEstPhoneNo: "",
    laborAffiation: "",
    taxNo: "",
    tradeList: [],
    city: "",
    dirNumber: null
  };

  const converStringToBytes = (password : string) =>{
    const encoder = new TextEncoder();
    return encoder.encode( password);
  };

  const randomPosGenerator = (bytes: any) => {
    if (bytes) {
      const bytesArray: any = new Uint8Array(bytes);
      bytes.forEach((byte: any, index : number) => {
        if(index === 2){
          bytesArray[index + 1]=(bytes[index])
         }
        
      });
    
      return bytesArray.buffer;
    }
    return;
  };

  const converBytesToString = (newPasswordInBytes : any)=>{
    let newList : any = [];
    let newPassword = new TextDecoder();
    return newPassword.decode(newPasswordInBytes);
  }
  
  const onSubmit = async () => {};

  const { values, errors, handleChange, handleSubmit, handleReset, setFieldValue, setFieldError, touched, setTouched, setFieldTouched, isValid, setErrors } = useFormik({
    initialValues: DefaultOnboardFormData,
    validationSchema: ValidationSchema,
    onSubmit,
    validateOnMount: true
  })

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    touched,
    setTouched,
    setFieldTouched,
    isValid,
    onSubmit,
    setErrors,
  };
}
export default Utils;


