// const BASE_URL = process.env.REACT_APP_BASE_URL
const BASE_URL = 'https://unipattbe.cloudjiffy.net';

export const GET_USA_STATE_AND_CITY =  'https://api.zippopotam.us/us/';

export const URLS = {
    GET_USERS_OF_ORGANISATION_LIST : (orgId: any) => `${BASE_URL}/organization/getUsersOfOrganization?orgId=${orgId}`,
    GET_ORGANISATION_LIST : () => `${BASE_URL}/organization/getOrganizations`,
    GET_DIVISION_LIST: () => `${BASE_URL}/division/getDivisionList`,
    SEND_VERIFICATION_LINK: () => `${BASE_URL}/onboard/request/sendVerificationLink`,
    VERIFY_TOKEN: (token: any) =>  `${BASE_URL}/onboard/request/verifyToken?token=${token}`, 

    SIGN_UP_URL : () => `${BASE_URL}/onboard/request/signUp`,
    GET_EXPERTIES_AREA_IN_URL: () => `${BASE_URL}/onboard/request/getExpertiesArea`,
    LOG_IN_URL: () => `${BASE_URL}/login/auth`,
    SAVE_DIVISION_AND_SPECIFICATION: () => `${BASE_URL}/division/save`,
    GET_BIDS_DETAIL: (status: string) => `${BASE_URL}/bids/${status}`,
    GET_PUBLISHED_BIDS: (projectId: string) => `${BASE_URL}/bids/published/${projectId}`,
    GET_PROJECT_LIST: (status: string) => `${BASE_URL}/project/`,
    GET_ORG_CLASSIFICATION: () => `${BASE_URL}/public/getClassification`,
    RESENT_EMAIL_VERIFICATION: (email:string) => `${BASE_URL}/public/forgetPassword/getTempPass?email=${email}`,
    UPDATE_PASSWORD: () => `${BASE_URL}/onboard/request/updatePassword`,
    GET_EXLORE_OPPORTUNITIES: (city : any) => `${BASE_URL}/public/explore/getPublicBids?city=${city}`,
    GET_BIDS_EXLORE_OPPORTUNITIES: () => `${BASE_URL}/public/exploreOpportunities`,
    GET_MY_BIDS_INTERNAL: () => `${BASE_URL}/project/getProjects`,
    CREATE_PROJECT: () => `${BASE_URL}/project/createUpdate`,
    SAVE_NEW_USER: () => `${BASE_URL}/user/addUser`,
    GET_USERS_ADDED_BY_ME: ()=> `${BASE_URL}/user/getUsersAddedByMe`,
    GET_PROJECT_DETIALS: (projectId: any)=> `${BASE_URL}/project/getProjectsDetails?projectId=${projectId}`,
    GET_PROJECT_CONTACT: ()=> `${BASE_URL}/project/getProjectContact`,
    UPLOAD_PROJECT_DOCUMENT: ()=> `${BASE_URL}/project/upload`,
    DELETE_PROJECT_DOCUMENT: (projectDocId: any)=> `${BASE_URL}/project/delete?projectDocId=${projectDocId}`,
    GET_PROJECT_DOCUMENT: ({projectId, docType}: any)=> `${BASE_URL}/project/getProjectDocs?projectId=${projectId}&docType=${docType}`,
    GET_PROJECT_SUBLIST: (projectId: any)=> `${BASE_URL}/project/getSubListForProject?projectId=${projectId}`,

    UPLOAD_TRADES_CSV: (projectId: any)=> `${BASE_URL}/document/trades/upload`,
    GET_USER_DETAIL: () => `${BASE_URL}/user/getUserDetail`,
    UPDATE_ORGANIZATION: ({orgId:orgId,status:status} : any)=> `${BASE_URL}/organization/updateOrganization?orgId=${orgId}&status=${status}`,
    UPDATE_USER: ({userId:userId,status:status} : any)=> `${BASE_URL}/user/updateUser?userId=${userId}&status=${status}`,
    SERACH_USER_BY_FILTERS: ()=> `${BASE_URL}/user/getUserBySearchFilter`,
    SAVE_PROJECT_TRADES: (projectId : any)=> `${BASE_URL}/project/tradeSave?projectId=${projectId}`,
    GET_PROJECT_TRADES: (projectId : any)=> `${BASE_URL}/project/getTrades?projectId=${projectId}`,
    SERACH_ORGANIZATION_BY_FILTER: (search:any)=> `${BASE_URL}/organization/getOrganizationsBySearch?search=${search}`,
    GET_PROJECT_BY_STATUS: ()=> `${BASE_URL}/project/getProjectByStatus`,
    SEND_INVITE: ()=> `${BASE_URL}/project/sendInvite`,
    UPDATE_BID_STATUS:() => `${BASE_URL}/project/updateBidStatus`,
    GET_INVITED_PROJECT_LIST: ()=> `${BASE_URL}/project/getInvitedProjects`,
    ON_CHANGE_PROJECT_STATUS: (projectId: any)=> `${BASE_URL}/project/change/status?projectId=${projectId}`,
    GET_PROJECT_BIDS: (projectId:any) => `${BASE_URL}/projectBid/getProjectBids?projectId=${projectId}`,
    GET_ALL_SUBS_MAIL_LIST_FOR_PROJECT: (projectId: any) =>
    `${BASE_URL}/project/getAllSubListForProject?projectId=${projectId}`,
    SEND_QUESTION_MAIL: () =>`${BASE_URL}/communication/question`,
    UPDATE_STATUS_FOR_ALL_BIDS: (projectId:any) => `${BASE_URL}/projectBid/updateStatusForAll?projectId=${projectId}`,
    GET_COUNT_OF_QUESTIONS_FROM_SUBS: (projectId: any) => `${BASE_URL}/communication/getCountOfQuestionsFromSubs?projectId=${projectId}`,
    GET_INVITE_PREVIEW: () =>`${BASE_URL}/projectBid/getPreview`,
    GET_ALL_SELECTED_TRADES: (projectId : any) =>`${BASE_URL}/project/getAllSelectedTradesForProject?projectId=${projectId}`,
    UPDATE_ORG_TRADES: (projectId : any) =>`${BASE_URL}/project/updateOrgTrade?projectId=${projectId}`,
    PUT_PUBLIC_BID_REQUEST: (projectId : any) =>`${BASE_URL}/subContractor/getReqestAccess?projectId=${projectId}`,
    GET_SUB_REQUEST_LIST: ({projectId,status} : any) =>`${BASE_URL}/project/getSubsRequest?projectId=${projectId}&status=${status}`,
    UPDATE_SUB_REQ_STATUS: ({status,subRequestId} : any) =>`${BASE_URL}/subContractor/updateRequest?status=${status}&subRequestId=${subRequestId}`,
    GET_COUNT_OF_REQUEST_FROM_SUBS: (projectId: any) => `${BASE_URL}/communication/getSubsRequestCount?projectId=${projectId}`,

};

export enum HttpMethod {
    Delete = 'DELETE',
    Get = 'GET',
    Patch = 'PATCH',
    Post = 'POST',
    Put = 'PUT'
};

const creatFetchOption = <Body, AdditionalHeaders>(method:any, body:Body, additionalHeader: AdditionalHeaders) => {

    const accessToken = localStorage.getItem("accessToken");

    const setContentType = body instanceof FormData ? null : { 'Content-Type' : 'application/json' };

    const headers: RequestInit['headers'] = {
        Accepts: 'application/json',
        ...setContentType,
        ...(additionalHeader || null),
    };

    if (accessToken) {
        headers.authorization = `Bearer ${accessToken}`;
    };

    const fetchOptions: RequestInit = {
        method,
        headers,
    };

    if (body) {
        fetchOptions.body = body instanceof FormData ? body : JSON.stringify(body);
    };

    return fetchOptions;

};

function api<ResponseData, Body = void | any, AdditionalHeaders = void>(){
    return <Params = void>(method:any, urlParam:((params: Params) => string) | (() => string), additionalHeader ?: any) => async (functionalName: any, params: Params, body: Body) => {
        const url = urlParam(params);

        let Additionalheader = additionalHeader || {};

        if (functionalName === 'multipart'){
            Additionalheader["Content-Type"] = "multipart/form-data";
            Additionalheader["mode"] =  'no-cors';
            Additionalheader["boundary"] =' add-random-characters';
        };

    return await fetch(url, creatFetchOption(method, body, Additionalheader))
        .then((data) => {
        if (!data.ok) {
            console.error("API FAILURE");
        }
        return data.json();
        })
        .then((json) => {
            console.log('json :>> ', json.payload);
            if(json !==null){
                return {
                    status: json.status,
                    message: json.message,
                    data:json.payload
                }
            } else{
                return {
                    status: "Internal Error",
                    message: json.message,
                    data: json.payload,
                };
            }
        })
    }
};

export const sendVerificationLink = api()(
    HttpMethod.Post,
    URLS.SEND_VERIFICATION_LINK
);

export const verifyToken = api()(
    HttpMethod.Get,
    URLS.VERIFY_TOKEN
);

export const getOrgUserList = api()(
    HttpMethod.Get,
    URLS.GET_USERS_OF_ORGANISATION_LIST,
)

export const getOrgList = api()(
    HttpMethod.Get,
    URLS.GET_ORGANISATION_LIST
)

export const getDivisionList = api()(
    HttpMethod.Get,
    URLS.GET_DIVISION_LIST
)

export const signUp = api()(
    HttpMethod.Post,
    URLS.SIGN_UP_URL
)

export const getExpertiseArea = api()(
    HttpMethod.Get,
    URLS.GET_EXPERTIES_AREA_IN_URL
)

export const logIn = api()(
    HttpMethod.Post,
    URLS.LOG_IN_URL
)

export const saveDivisionAndSpecification = api()(
    HttpMethod.Post,
    URLS.SAVE_DIVISION_AND_SPECIFICATION
)

export const getBidsDetails = api()(
    HttpMethod.Get,
    URLS.GET_BIDS_DETAIL
)

export const getPublishedBids = api()(
    HttpMethod.Get,
    URLS.GET_PUBLISHED_BIDS
)

export const getProjectList = api()(
    HttpMethod.Get,
    URLS.GET_PROJECT_LIST
) 

export const getOrgClassification = api()(
  HttpMethod.Get,
  URLS.GET_ORG_CLASSIFICATION
)

export const resentEmailVarification = api()(
  HttpMethod.Get,
  URLS.RESENT_EMAIL_VERIFICATION
);
export const updatePassword = api()(
  HttpMethod.Post,
  URLS.UPDATE_PASSWORD
);

export const getExploreOpportunities = api()(
    HttpMethod.Get,
    URLS.GET_EXLORE_OPPORTUNITIES
)

export const getBidsExploreOpportunities = api()(
    HttpMethod.Get,
    URLS.GET_EXLORE_OPPORTUNITIES
)

export const getMyBidsInternal = api()(
    HttpMethod.Get,
    URLS.GET_MY_BIDS_INTERNAL
)

export const createProject = api()(
    HttpMethod.Post,
    URLS.CREATE_PROJECT
)

export const addUser = api()(
    HttpMethod.Post,
    URLS.SAVE_NEW_USER
)

export const getUsersAddedByMe = api()(
    HttpMethod.Get,
    URLS.GET_USERS_ADDED_BY_ME
);

export const getProjectDetails = api()(
    HttpMethod.Get,
    URLS.GET_PROJECT_DETIALS
);

export const getProjectContact = api()(
    HttpMethod.Get,
    URLS.GET_PROJECT_CONTACT
);

export const uploadProjectDocument = api()(
    HttpMethod.Post,
    URLS.UPLOAD_PROJECT_DOCUMENT
);

export const deleteProjectDocument = api()(
    HttpMethod.Delete,
    URLS.DELETE_PROJECT_DOCUMENT
);
export const getProjectDocuments = api()(
    HttpMethod.Get,
    URLS.GET_PROJECT_DOCUMENT
);

export const getSubListForProject = api()(
    HttpMethod.Get,
    URLS.GET_PROJECT_SUBLIST
);

export const uploadTradesCSV = api()(
    HttpMethod.Post,
    URLS.UPLOAD_TRADES_CSV
);

export const getUserDetails = api()(
    HttpMethod.Get,
    URLS.GET_USER_DETAIL
);

export const updateOrganization = api()(
    HttpMethod.Post,
    URLS.UPDATE_ORGANIZATION
)

export const updateUser = api()(
    HttpMethod.Post,
    URLS.UPDATE_USER
)

export const userSearch = api()(
    HttpMethod.Post,
    URLS.SERACH_USER_BY_FILTERS
)

export const orgSearch = api()(
    HttpMethod.Get,
    URLS.SERACH_ORGANIZATION_BY_FILTER
)

export const saveProjectTrades = api()(
    HttpMethod.Post,
    URLS.SAVE_PROJECT_TRADES
)

export const getProjectTrads = api()(
    HttpMethod.Get,
    URLS.GET_PROJECT_TRADES
)

export const projectSearchByStatus = api()(
    HttpMethod.Post,
    URLS.GET_PROJECT_BY_STATUS
)

export const sendInviteSubContractor = api()(
    HttpMethod.Post,
    URLS.SEND_INVITE
)

export const updateBidStatus = api()(
    HttpMethod.Post,
    URLS.UPDATE_BID_STATUS
)

export const getInvitedProjects = api()(
    HttpMethod.Post,
    URLS.GET_INVITED_PROJECT_LIST
)
export const onChangePostProjectStatus = api()(
    HttpMethod.Post,
    URLS.ON_CHANGE_PROJECT_STATUS
)

export const getProjectBids = api()(
    HttpMethod.Get,
    URLS.GET_PROJECT_BIDS
)


export const getAllSubsMailListForProject = api()(
    HttpMethod.Get,
    URLS.GET_ALL_SUBS_MAIL_LIST_FOR_PROJECT
)

export const askQuestionEmail = api()(
    HttpMethod.Post,
    URLS.SEND_QUESTION_MAIL
)

export const updateStatusForAllBids = api()(
    HttpMethod.Post,
    URLS.UPDATE_STATUS_FOR_ALL_BIDS
)

export const getCountOfQueFromSubs = api()(
    HttpMethod.Get,
    URLS.GET_COUNT_OF_QUESTIONS_FROM_SUBS
)
export const getInvitePreview = api()(
    HttpMethod.Post,
    URLS.GET_INVITE_PREVIEW
)

export const getAllSelectedTrades= api()(
    HttpMethod.Get,
    URLS.GET_ALL_SELECTED_TRADES
) 
export const updateOrgTrades= api()(
    HttpMethod.Post,
    URLS.UPDATE_ORG_TRADES
) 

export const putPublicBidRequestAccess= api()(
    HttpMethod.Put,
    URLS.PUT_PUBLIC_BID_REQUEST
) 

export const getSubRequestList = api()(
    HttpMethod.Post,
    URLS.GET_SUB_REQUEST_LIST
) 

export const updateSubReqStatus = api()(
    HttpMethod.Put,
    URLS.UPDATE_SUB_REQ_STATUS
) 

export const getCountOfSubReq = api()(
    HttpMethod.Get,
    URLS.GET_COUNT_OF_REQUEST_FROM_SUBS
)