import { useEffect, useState } from "react";
import Modal from "react-modal";
import { Button, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React from "react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GlobalHeader from "../GlobalHeader";
import { useNavigate } from "react-router-dom";
import BasicDetails from "./FormSections/basicDetails";
import ClassiFicationDetails from "./FormSections/classiFicationDetails";
import Utils from "./utils";
import { OnboardFormSection } from "../../utils/const";
import SelectDivisionAndSpecification from "../SelectDivisionAndSpecification";
import CustomizedSnackbar from "../CustomizedSnackbar";
import { sendVerificationLink, signUp } from "../../utils/api";
import Loader from "../Loader";
import { useDispatch } from "react-redux";
import { setActiveStepAction } from "../../redux/actions/actions";

const style = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
    opacity: "1",
    background: "#f8f9fa",
  },
};

const OnboardRequest = ({ stepActive }: any) => {
  console.log("stepActive :>> ", stepActive);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState("");
  const [tradeResponse, setTradeResponse] = useState<any>([])

  const dispatch = useDispatch();

  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    handleSubmit,
    setFieldError,
    setFieldTouched,
    touched,
    setTouched,
    isValid,
    setErrors,
  } = Utils(stepActive);

  console.log("stepActive values :>> ", values);

  const navigate = useNavigate();
  const totalPage = OnboardFormSection;

  const createChildTradesStructure = (list: any, tempList: any) => {
    let tempTradeMap: any = tempList;
    list.forEach((value: any) => {
      tempTradeMap.push(value.id);
      const { id, children } = value;
      if (children && children.length > 0) {
        createChildTradesStructure(children, tempTradeMap);
      }
    });
    return tempTradeMap;
  };

  const handleFormSubmit = () => {
    dispatch(setActiveStepAction(stepActive + 1));
    const {
      orgName,
      confirmPassword,
      name,
      email,
      phoneNumber,
      addressOne,
      addressTwo,
      state,
      zip,
      licenseNo,
      taxNo,
      city,
      laborAffiation,
      classifications,
      tradeList,
      dirNumber,
      dirDate,
    } = values;

    const classiFicationDetailsList = classifications
      .filter((value) => value.isSelected === true)
      .map((item) => item.id);

    let tempTradeList: any = [];
    let tradeResponseList: any = [];
    tradeList.forEach((value: any) => {
      const { id, children } = value;
      if (children && children.length > 0) {
        tempTradeList = [
          ...tradeResponseList,
          ...createChildTradesStructure(children, [id])
        ]}else{
          tempTradeList = [
            ...tradeResponseList,
             id 
          ];
      }
      tradeResponseList = [...tempTradeList]
    });

    const body = {
      orgName,
      // password:encryptPassword(confirmPassword),
      password: confirmPassword,
      email,
      name,
      phoneNumber,
      addressOne,
      addressTwo,
      state,
      zip,
      city,
      licenseNo,
      taxNo,
      laborAffiation: laborAffiation,
      dirNumber,
      dirDate,
      classifications: classiFicationDetailsList,
      tradeIds: tradeResponseList,
    };

    signUp("", undefined, body)
      .then(async (response) => {
        if (response.status.includes("OK")) {
          setOpenSuccessModal(true);
          setOpenSnackBar(false);
          const verificationResponse = await sendVerificationLink(
            "",
            undefined,
            body
          );

          if (verificationResponse.status.includes("OK")) {
            console.log("Verification link sent successfully.");
          } else {
            console.log(
              "Failed to send verification link:",
              verificationResponse.message
            );
          }
        } else {
          setMessage(response.message);
          setOpenSnackBar(true);
          setOpenSuccessModal(false);
          dispatch(setActiveStepAction(0));
          setFieldValue("tradeList", []);
        }
      })
      .catch((error: any) => {
        setMessage(error.message);
        setOpenSnackBar(true);
        setOpenSuccessModal(false);
        dispatch(setActiveStepAction(2));
        setFieldValue("tradeList", []);
        setTradeResponse([]);
      });
  };

  const setTouchedField = () => {
    switch (stepActive) {
      case 0:
        setTouched({
          name: true,
          orgName: true,
          email: true,
          phoneNumber: true,
          password: true,
          confirmPassword: true,
          state: true,
          city: true,
          zip: true,
          addressOne: true,
          addressTwo: true,
          taxNo: true,
          licenseNo: true,
        });
        break;
      case 1:
        setTouched({
          laborAffiation: true,
        });
        setFieldTouched("classifications", true);
        break;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="container w-75 overflow-hidden fixedSize">
        <GlobalHeader title="Sign-Up" isLogoVisible />
        <Stepper activeStep={stepActive} alternativeLabel>
          {totalPage.map((page, index) => (
            <Step key={page.id}>
              <StepLabel
                StepIconProps={{
                  style: {
                    color: index === stepActive ? "darkgrey" : "lightgrey",
                  },
                }}
              >
                {page.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="d-grid shadow mt-1 bg-light">
          <form
            className="form-group py-2"
            onSubmit={handleSubmit}
            style={{
              height: "64vh",
              overflowX: "hidden",
              overflowY: "scroll",
              scrollbarWidth: "thin",
            }}
          >
            <ConditionalComponent
              formikProps={{
                values,
                errors,
                setFieldValue,
                setFieldError,
                setErrors,
                setFieldTouched,
                setTouched,
                touched,
                handleChange,
              }}
              activeStep={stepActive}
            />
          </form>
        </div>
        <div className="row d-flex justify-content-center footer gx-0 gy-0">
          <div className="d-flex col-6 justify-content-end">
            <Button
              className="btn btn-bg col-3 col-md-3 col-sm-3  mb-4 mt-3"
              onClick={(e) => {
                e.preventDefault();
                dispatch(setActiveStepAction(stepActive - 1));
                setTouched({
                  laborAffiation: false,
                });
                setTimeout(() => {
                  setErrors({});
                }, 0);
              }}
              disabled={stepActive === 0}
            >
              Back
            </Button>
          </div>
          <div className="d-flex col-6 justify-content-start">
            <Button
              className="btn btn-bg col-3 col-md-3 col-sm-3  mb-4 mt-3 ms-5"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                if (stepActive === 2 && isValid) {
                  handleFormSubmit();
                }
                if (stepActive < 2) {
                  console.log("erros", errors);
                  if (isValid) {
                    dispatch(setActiveStepAction(stepActive + 1));
                  } else {
                    setTouchedField();
                  }
                }
                if (stepActive > 1) {
                  handleSubmit();
                }
              }}
            >
              {stepActive > 1 ? "Submit" : "Continue"}
            </Button>
          </div>
        </div>
      </div>

      <Modal
        id="succes"
        isOpen={openSuccessModal}
        style={style}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <div className="mx-auto">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="bg-succes pointer"
                  style={{ color: "green" }}
                  size="4x"
                />{" "}
              </div>
            </div>
            <div
              className="modal-body mt-5"
              style={{ maxWidth: "400px", height: "150px" }}
            >
              <div className="row">
                <Typography className="gx-0 d-flex justify-content-center opacity-50 fs-5">
                  Sign-up will be completed once you verify your email address.
                  <br />A verification link has been sent to your email.
                </Typography>
              </div>
              <div className="row">
                <Button
                  className="btn btn-bg col-6 mx-auto gx-5 mt-5"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenSuccessModal(false);
                    dispatch(setActiveStepAction(0));
                    navigate("/login");
                  }}
                >
                  Back To Sign In
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <CustomizedSnackbar
        openSnackBar={openSnackBar}
        message={message}
        handCloseSnackBar={() => setOpenSnackBar(false)}
      />
    </>
  );
};

interface ChildProps {
  activeStep: number;
  formikProps: any;
}

const ConditionalComponent: React.FC<ChildProps> = ({
  activeStep,
  formikProps,
}: ChildProps) => {
  switch (activeStep) {
    case 0:
      return <BasicDetails key={activeStep + 0} formikProps={formikProps} />;
    case 1:
      return (
        <ClassiFicationDetails key={activeStep + 1} formikProps={formikProps} />
      );
    case 2:
      return (
        <SelectDivisionAndSpecification
          key={activeStep + 2}
          formikProps={formikProps}
        />
      );
    default:
      return <Loader className="d-flex justify-content-center mt-5 py-5" />;
  }
};

export default OnboardRequest;
