import { useCallback, useState } from "react";

interface Props {
  selectedDocument: string;
  selectedPrivacyType: string;
  projectDocuments?:any;
}
type DocumentRow = {
  docType: string;
  files: File[];
  privacyType: string[];
  docName: string[];
  size: string[];
};

const UseSelectDocRow = ({ selectedDocument, selectedPrivacyType,projectDocuments }: Props) => {  

  const [selectedDocRows, setSelectedDocRows] = useState<DocumentRow[]>([]);
  const [viewFiles, setViewFiles] = useState<
    { docType: string; files: File[] }[]
  >([]);
  const [indexImage, setIndexImage] = useState<number>(0);

  const updateNewDocRows = useCallback(
    (file: File, type: string) => {
      setSelectedDocRows((currentList) =>
        currentList.map((item) =>
          item.docType === type
            ? {
                ...item,
                privacyType: [...item.privacyType, selectedPrivacyType],
                files: [...item.files, file],
                docName: [...item.docName, file.name],
                size: [...item.size, (file.size / 1024).toFixed(2)],
              }
            : item
        )
      );
    },
    [selectedPrivacyType]
  );

  const handleUpdateDocRows = useCallback(
    (files: File[]) => {
      const isTypeExist = selectedDocRows.some(
        (item) => item.docType === selectedDocument
      );

      if (isTypeExist) {
        files.forEach((file) => {
          updateNewDocRows(file, selectedDocument);
        });
      } else {
        files.forEach((file, index) => {
          if (index < 1) {
            setSelectedDocRows((prevRows) => [
              ...prevRows,
              {
                docType: selectedDocument,
                files: [file],
                privacyType: [selectedPrivacyType],
                docName: [file.name],
                size: [(file.size / 1024).toFixed(2)],
              },
            ]);
          } else {
            updateNewDocRows(file, selectedDocument);
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDocument, selectedDocRows, updateNewDocRows]
  );

  const handleView = useCallback(
    (viewIndex: number, type: string) => {
      setIndexImage(viewIndex);
      setViewFiles(
        selectedDocRows.filter((value : DocumentRow) => value.docType === type)
      );
    },
    [selectedDocRows]
  );

  const handleDeleteRow = useCallback(
    (deleteIndex: number, type: string) => {
      setSelectedDocRows((currentList : DocumentRow[]) =>
        currentList.reduce((acc: DocumentRow[] | [], item: DocumentRow) => {
          if (item.docType === type) {
            const newType: string[] = item.privacyType.filter((_: any, index: number) => index !== deleteIndex);
            if (newType.length === 0) {
              return acc;
            } else {
              return [
                ...acc,
                {
                  ...item,
                  privacyType: newType,
                  files: item.files.filter(
                    (_: any, index: number) => index !== deleteIndex
                  ),
                  docName: item.docName.filter(
                    (_: any, index: number) => index !== deleteIndex
                  ),
                  size: item.size.filter((_: any, index: number) => index !== deleteIndex),
                },
              ];
            }
          } else {
            return [...acc, item];
          }
        }, [])
      );
    },
    [setSelectedDocRows]
  );

  return {
    selectedDocRows,
    handleUpdateDocRows,
    handleView,
    viewFiles,
    indexImage,
    handleDeleteRow,
  };
};

export default UseSelectDocRow;
