import { useEffect, useState } from "react";
import { CustomTable } from "../../DataTable";
import { HeaderCells } from "../utils";
import { getProjectList } from "../../../utils/api";

const style = [{ width: "100%", height: '70vh', overflowX: "scroll", overflowY: 'scroll' }];

interface Props {
    onEditSelect: (row: any) => void;
    onBidsSelect: (row: any) => void;
}

const ProjectList = ({ onEditSelect, onBidsSelect }: Props) => {

    const [rowData, setRowData] = useState<any>([]);

    const handleSelectedRow = (row: any, type: string, check: boolean) => {
        if (type === "bids") {
            onBidsSelect(row);
        } else {
            onEditSelect(row);
        }
    };

    useEffect(() => {
        getProjectList(" ", " ", undefined).then((resp) => {
            const data = resp.data;
            setRowData(rowData);
        }).catch((e) => {
            console.log('e :>> ', e);
        })
    }, [])


    return (
        <>
            <div className="container px-4">
                {/* <div className="row">
                    <div className="col-3">
                        <FormControl fullWidth>
                            <InputLabel id="select-label">Select City</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                // value={selectedCity}
                                // onChange={handleChange}
                                label="Select City"
                            >
                                <MenuItem value="">
                                </MenuItem>
                                {rows.map((row, index) => (
                                    <MenuItem key={index} value={row.city}>
                                        {row.city}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-3">
                        <FormControl fullWidth>
                            <InputLabel id="select-label">Select Sector</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                // value={selectedCity}
                                // onChange={handleChange}
                                label="Select Sector"
                            >
                                <MenuItem value="">
                                </MenuItem>
                                {sectorList.map((row, index) => (
                                    <MenuItem key={index} value={row.id}>
                                        {row.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-3">
                        <FormControl fullWidth>
                            <InputLabel id="select-label">Select Contruction Type</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                // value={selectedCity}
                                // onChange={handleChange}
                                label="Select Contruction Type"
                            >
                                <MenuItem value="">
                                </MenuItem>
                                {constructionTypeList.map((row, index) => (
                                    <MenuItem key={index} value={row.id}>
                                        {row.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-3">
                        <div className="row d-flex justify-content-start footer gx-0 gy-0">
                            <div className="d-flex col-6">
                                <Button className="btn col-12 col-md-12 col-sm-12 mt-1" style={{ backgroundColor: color.darkColor, color: 'white' }}> Search </Button>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row mt-3">
                    <CustomTable headCells={HeaderCells} rows={rowData} style={style} onSelectRowClick={handleSelectedRow} />
                </div>
            </div>
        </>
    );
};

export default ProjectList;
