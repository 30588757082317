import { useCallback } from "react";

const UseTreeSelectionFunction = () => {

  const getNewResultResp = useCallback(
    (key: number, obj: any[]) => getNewResult(key, obj),
    []
  );

  return {
    getNewResultResp,
  };
};

export default UseTreeSelectionFunction;

const getNewResult = (key: any, tempNodes: any): any => {
  const unselectedNodeKey = key;
  let temp = tempNodes;
  const selectedNode = getSelectedNode(temp, unselectedNodeKey);
  const listWithoutSelectedNode = temp.filter(
    (node: any) => !selectedNode.includes(node)
  );

  let newResult = unSelectedNode(unselectedNodeKey, selectedNode, [null]);
  if (newResult === null || newResult.length === 0) {
    newResult = listWithoutSelectedNode;
  } else {
    newResult = listWithoutSelectedNode.concat(newResult);
  }
  return newResult;
};

const getSelectedNode = (nodes: any[], keyToRemove: any): any => {
  const tempResult: any[] = [];
  for (let i = nodes.length - 1; i >= 0; i--) {
    const node = nodes[i];
    if (
      node.id === keyToRemove ||
      (node.children &&
        node.children.some((child: { id: any }) => child.id === keyToRemove))
    ) {
      tempResult.push(node);
    } else if (node.children) {
      const children = getSelectedNode(node.children, keyToRemove);
      if (children.length > 0) {
        tempResult.push(node);
      }
    }
  }

  return tempResult;
};

const unSelectedNode = (
  selectedNodeKey: any,
  currentSelectedNodes: any[],
  selectedNodes: any[]
): any => {
  const selectedNodesList = currentSelectedNodes.map((a) => ({ ...a }));
  let currentNode = selectedNodesList[0];
  let found = false;

  for (let i = 0; i < currentSelectedNodes.length && !found; i++) {
    currentNode = selectedNodesList[i];
    if (currentNode.id === selectedNodeKey) {
      found = true;
      return selectedNodesList.filter((node) => node.id !== selectedNodeKey);
    }
    if (currentNode.children.length > 0) {
      const res = unSelectedNode(
        selectedNodeKey,
        currentNode.children,
        selectedNodesList
      );
      if (res != null) {
        found = true;
        currentNode.children = [...res];
        if (currentNode.children.length === 0) {
          let parentIndex = selectedNodesList.findIndex(
            (node) => node.children
          );

          for (let j = 0; j < selectedNodesList.length; j++) {
            if (selectedNodesList[j].id === currentNode.id) {
              parentIndex = j;
              break;
            }
          }
          if (parentIndex !== -1) {
            selectedNodesList.splice(parentIndex, 1);
            if (selectedNodesList.length === 0) {
              return [];
            }
            return selectedNodesList;
          }
        }
        return selectedNodesList;
      }
    }
  }
  return null;
};
