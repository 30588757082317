export const  HeaderCells = [
    { id: "orgName", name: "Organization Name", action: "number" },
    { id: "name", name: "Name", action: "" },
    { id: "email", name: "Email", action: "" },
    { id: "phoneNo", name: "Phone Number", action: "" },
    { id: "status", name: "Status", action: ""},
    { id: "action", name: "Action", action: "delete" },
    { id: "view", name: "Action", action: "view" },

  ];

  export const  NewHeaderCells = [
    { id: "orgName", name: "Organization Name", action: "number" },
    { id: "name", name: "Name", action: "" },
    { id: "email", name: "Email", action: "openModal" },
    { id: "phoneNo", name: "Phone Number", action: "" },
  ];

  export const SelectHeaderCells = [
    { id: "orgName", name: "Organization Name", action: "" },
    { id: "name", name: "Name", action: "" },
    { id: "email", name: "Email", action: "openModal" },
    { id: "phoneNo", name: "Phone Number", action: "" },
  ];

  export const DocHeaderCells = [
    { id: "documentFor", name: "Document Type", action: "type" },
    { id: "type", name: "Type", action: "" },
    { id: "selectedFiles", name: "Document", action: "option" },
    { id: "size", name: "Size (kb)", action: "" },
    { id: "action", name: "Action", action: "viewAndDelete" },
  ];

  export const BidCells = [
    { id: "documentFor", name: "Document Type", action: "type" },
    { id: "type", name: "Type", action: "" },
    { id: "selectedFiles", name: "Document", action: "option" },
    { id: "size", name: "Size (kb)", action: "" },
    { id: "action", name: "Action", action: "viewAndDelete" },
  ];

  export const  BidsTableHeaderCells = [
    { id: "response", name: "Response", action: "number" },
    { id: "status", name: "Status", action: "" },
    { id: "bid", name: "Bid $", action: "" },
    { id: "questions", name: "Question", action: "" },
    { id: "orgName", name: "Organization", action: ""},
    { id: "firstName", name: "SC Name", action: "" },
    { id: "mobileNo", name: "Phone", action: "phone" },
    { id: "email", name: "Email", action: "click" },
    { id: "city", name: "City", action: "" },
    { id: "state", name: "State", action: "" },
    { id: "classification", name: "Classification", action: "" },
    { id: "laborAffiliation", name: "Labor Affiliation", action: "" },
    { id: "bidPercentage", name: "Bid Percent", action: "" },
  ];

  export const SubDivTable = [
    {
      responses: "Y",
      status: "In Progress",
      bid: "$111114.41",
      questions: "1 New",
      companyName: "Fiveclub",
      contactName: "Jenifer Ebdon",
      phone: "503-744-7016",
      cell: "816-267-7319",
      email: "jebdon0@cam.ac.uk",
      city: "Portland",
      state: "OR",
      classifie: "MBE",
      laborAff: "Non Union",
      bidPercent: "14%",
      id: 16,
    },
    {
      responses: "Y",
      status: "Invited",
      bid: "$996143.70",
      questions: "2 New",
      companyName: "Zoonder",
      contactName: "Cam Male",
      phone: "773-615-8179",
      cell: "215-655-9368",
      email: "cmale1@amazon.co.uk",
      city: "Chicago",
      state: "IL",
      classifie: "MBE",
      laborAff: "Union",
      bidPercent: "3%",
      id: 30,
    },
    {
      responses: "Y",
      status: "Accepted",
      bid: "$885895.58",
      questions: "2 New",
      companyName: "Trupe",
      contactName: "Raquela McCrea",
      phone: "309-929-2436",
      cell: "352-182-4410",
      email: "rmccrea2@addtoany.com",
      city: "Peoria",
      state: "IL",
      classifie: "MBE",
      laborAff: "Union",
      bidPercent: "66%",
      id: 10,
    },
  ];