import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomTable } from "../DataTable";
import Loader from "../Loader";

const style = [
  { width: "100%", height: "60vh", overflowX: "hidden", overflowY: "scroll" },
];
interface Props {
  file: File;
  headers?: any[];
  rows?: any[];
}

const FilePreview = ({ file, headers=[], rows=[], ...props }: Props) => {

  if(!file) return <Loader className=""/>
  
  try {
    switch (file.type) {
      case "application/pdf":
        return (
          <embed
            src={URL.createObjectURL(file)}
            type="application/pdf"
            width="725px"
            height="575px"
          />
        );
      case "image/png":
      case "image/jpeg":
        return (
          <img
          className="h-100 w-100"
            src={URL.createObjectURL(file)}
            alt="modalImage"
            // style={{ maxWidth: "575px", maxHeight: "575px" }}
            onError={(e) => {
              const img = e.target as HTMLImageElement;
              img.src = "image-not-found.png";
            }}
          />
        );
      case "application/csv":
        return (
          <div
            className="container d-flex justify-content-center w-100"
          >
            <div className="mt-5 h-100">
              <CustomTable headCells={headers} rows={rows} style={style} />
            </div>
          </div>
        );
     
      default:
        return (
          <div style={{ width: "275px", height: "150px" }}>
            <div className="row mt-2 me-1">
              <FontAwesomeIcon
                className="text-danger"
                icon={faWarning}
                color="warning"
                size="4x"
              ></FontAwesomeIcon>
            </div>
            <div className="row col-12 mt-2 ms-1">
              <p className="opacity-50 fs-5">
                <span className="d-flex justify-content-center gx-5">
                  Invalid file.
                </span>
                <span className="d-flex justify-content-center">
                  Please select a valid file.
                </span>
              </p>
            </div>
          </div>
        );
    }
  } catch (error) {
    return (
      <img
        alt="modalImage"
        style={{ maxWidth: "575px", maxHeight: "575px" }}
        onError={(e) => {
          const img = e.target as HTMLImageElement;
          img.src = "image-not-found.png";
        }}
      />
    );
  }
};

export default FilePreview;
