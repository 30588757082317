import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  styled,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useContext, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { InviteSubContState, PreviewModalType } from "../../../utils/types";
import { selectPostProjectStatus, selectThemeColor } from "../../../redux/selectors/selectors";
import InputField from "../../InputField";
import {
  BidsTableHeaderCells,
  NewHeaderCells,
} from "../../ManageProject/NewProjectForm/CreateBidOppForm/tableData";
import { CustomTable } from "../../DataTable";
import { PostProjectEnum, ResponseEnum } from "../../../utils/enum";
import { sendInviteSubContractor } from "../../../utils/api";
import CustomizedSnackbar from "../../CustomizedSnackbar";
import { useDispatch } from "react-redux";
import { setProjectBidTradeId } from "../../../redux/actions/projectFormActions";
import BidInviteModal from "../../BidInviteModal/bidInviteModal";

const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "auto",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
    opacity: "1",
    background: "#f8f9fa",
    zIndex: "0",
  },
};

const defaultFormData: InviteSubContState = {
  orgName: "",
  name: "",
  phoneNo: "",
  email: "",
};
interface Props {
  headerCells: any;
  node?: any;
  index?: any;
  isEdit: any;
  isView: boolean;
  handleSelectedRow?: (row: any) => void;
  refreshSubList?: () => void;
  projectId:any;
}

const defaultBidStatusType = {
  invited: 0,
  viewed: 0,
  bidding:0,
  rejected: 0,
  mayBe: 0,
  noResponse: 0,
  bidsReceive: 0,
}

const SubListDropdown = ({
  node,
  index,
  isEdit,
  projectId,
  refreshSubList
}: Props) => {
  const color = useSelector(selectThemeColor);
  const [expanded, setExpanded] = useState("1");
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [inviteSubConFormData, setInviteSubConFormData] =
    useState<InviteSubContState>(defaultFormData);
  const [newInviteRow, setNewInviteRow] = useState<any>([]);
  const [counterObject, setCounterObject] = useState(defaultBidStatusType);
  const [rows, setRows] = useState(node.length === 0 ? [] : node.subsList);
  const [subContractorMails, setSubContractorMails] = useState<string[]>([]);
  const [message, setMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openBidInviteModal, setBidOpenInviteModal] = useState<boolean>(false);


  const dispatch =  useDispatch()

  useEffect(() => {
    rows.forEach((element: any) => {
      switch (element.status) {
        case "Viewed":
          setCounterObject((prevStatus: any) => ({
            ...prevStatus,
            viewed: rows.filter(
              (value: { status: string }) => value.status === "Viewed"
            ).length,
          }));
          break;
        case "Invited":
          setCounterObject((prevStatus: any) => ({
            ...prevStatus,
            invited: rows.filter(
              (value: { status: string }) => value.status === "Invited"
            ).length,
          }));
          break;
        case "Rejected":
          setCounterObject((prevStatus: any) => ({
            ...prevStatus,
            rejected: rows.filter(
              (value: { status: string }) => value.status === "Rejected"
            ).length,
          }));
          break;
        case "May Be":
          setCounterObject((prevStatus: any) => ({
            ...prevStatus,
            mayBe: rows.filter(
              (value: { status: string }) => value.status === "May Be"
            ).length,
          }));
          break;
        default:
          setCounterObject((prevStatus: any) => ({
            ...prevStatus,
            noResponse: rows.filter(
              (value: { response: string; status: string }) =>
                value.response === "N" || value.response === null
            ).length,
          }));
          break;
      }
    });
  },[rows]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 12,
      fontWeight: "400",
      padding: "0.7 rem",
      width: 300,
      wordWrap: "break-word",
      overflowX: "scroll",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
    },
  }));

  const handleClose =  () => setBidOpenInviteModal(false)

  const handleChange = useMemo(
    () => (e: any) => {
      const { name, value } = e.target;
      //   }
      setInviteSubConFormData({
        ...inviteSubConFormData,
        [name]: value,
      });
    },
    [inviteSubConFormData]
  );

  const createInvitedSubConRow = () => {
    if (inviteSubConFormData) {
      const newRows: any = {
        orgName: inviteSubConFormData.orgName,
        firstName: inviteSubConFormData.name,
        mobileNo: inviteSubConFormData.phoneNo,
        email: inviteSubConFormData.email,
        response: "",
        status:"",

      };
      setNewInviteRow([...newInviteRow, newRows]);
      setRows([...rows, newRows]);
    }
  };

  const handleCheck = (row: any, checked: boolean) => {
    const { email } = row;
    let mailList = [...subContractorMails];
    const index = subContractorMails.indexOf(email);
    if (index !== -1) {
      mailList = mailList.filter(
        (mail) => !mail.toLowerCase().includes(email.toLowerCase())
      );
    } else {
      mailList = [...mailList, email];
    }
    setSubContractorMails(Array.from(new Set(mailList)));

  };

  const handleCheckAll = (rows : any) =>{
    let mailList = rows.map(
      (list: { email: any }) => list.email
    );
    setSubContractorMails(Array.from(new Set(mailList)));
  }

  const handleExpandChange =
    (index: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? index.toString() : "");
      dispatch(setProjectBidTradeId(node.id));
      setBidOpenInviteModal(false)
    };

    const handleSubmit = ({subject , emailScope, files, inviteType} : any) => {
      setIsLoading(true);
      const formData = new FormData();
      const body = { projectId, projectTradeId: node.id, subContractorMails, subject,emailBody: emailScope,inviteType };
      formData.append('emailRequest', JSON.stringify(body));
      files.forEach((file : any) => {
        formData.append(
          "emailAttachments",
          new Blob([file], { type: file.files[0].type }),
          file.files[0].name
        );
      });
    
      if (subContractorMails.length > 0) {
        sendInviteSubContractor("", undefined, formData)
          .then((response) => {
            setIsOpen(true);
            setIsLoading(false);
            if(response.status === "OK"){
              setMessage(response.message + " " + subContractorMails[0]);
              refreshSubList!();
              handleClose();
            }else if(response.status === 'FAIL'){
              setMessage("Invite has already been sent.");
            }
          })
          .catch((error: any) => {
            setIsOpen(true);
            setMessage("Invite has already been sent.");
            setIsLoading(false);
            handleClose();
          });
      } else {
        setIsOpen(true);
        setIsLoading(false);
        setMessage("Please Check invite mail");
        handleClose();
      }
    };

    const handleEmailClick = (row: any, actionType: String, check: boolean) => {
        setSubContractorMails([row.email]);
        setBidOpenInviteModal(true);
    };

  return (
    <>
      <div className="mt-3">
        <Accordion
          key={index}
          expanded={expanded.includes("panel1-header")}
          aria-controls="panel1-content"
          id="panel1-header"
          onChange={handleExpandChange("panel1-header")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-header"
            id="panel1-header"
          >
            <TableHead key="table-head">
              <TableRow key="row" style={{ zIndex: 1 }}>
                <StyledTableCell key="addSubs">
                  <Button
                    className="btn"
                    style={{
                      backgroundColor: color.darkColor,
                      color: "white",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      setOpenInviteModal(true);
                    }}
                  >
                    Add Sub
                  </Button>
                </StyledTableCell>
                <StyledTableCell key="name">
                  <div
                    className="d-block"
                    style={{
                      width: 320,
                    }}
                  >
                      <span className="row text-primary me-1">
                        {node.tradeCode}
                      </span>
                      <span className="row"> {node.name}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="invited">
                  <div className="d-flex flex-column">
                    <span>Invited</span>
                    <span>
                      {!isEdit ? `(${counterObject.invited})` : "(0)"}
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="viewed">
                  <div className="d-flex flex-column">
                    <span>Viewed</span>
                    <span>{!isEdit ? `(${counterObject.viewed})` : "(0)"}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="bidding">
                  <div className="d-flex flex-column">
                    <span>Bidding</span>
                    <span>
                      {!isEdit ? `(${counterObject.bidding})` : "(0)"}
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="rejected">
                  <div className="d-flex flex-column">
                    <span>Rejected</span>
                    <span>
                      {!isEdit ? `(${counterObject.rejected})` : "(0)"}
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="mayBe">
                  <div className="d-flex flex-column">
                    <span>May Be</span>
                    <span>{!isEdit ? `(${counterObject.mayBe})` : "(0)"}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="noRepsonse">
                  <div className="d-flex flex-column">
                    <span>No Response</span>
                    <span>
                      {!isEdit ? `(${counterObject.noResponse})` : "(0)"}
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="bidsReceived">
                  <div className="d-flex flex-column">
                    <span>Bids Received</span>
                    <span>
                      {!isEdit ? `(${counterObject.bidsReceive})` : "(0)"}
                    </span>
                  </div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </AccordionSummary>
          <AccordionDetails>
            {isEdit ? (
              <>
                <div className="row gx-0 mt-2">
                  <div className="col-10 col-md-10 col-sm-10">
                    <InputField
                      id="bidDescription"
                      name="bidDescription"
                      type="text"
                      // value={values.projectName}
                      // handleChange={handleChange}
                      className="form-control"
                      label="Bid Description"
                      // error={touched.projectName && errors.projectName}
                      multiline={true}
                      required
                    />
                  </div>
                  <div className="col-2 col-md-2 col-sm-2 mt-3 gx-0">
                    <label className="form-label mt-2 me-1">Public Bid</label>
                    <Checkbox
                      style={{ color: color.darkColor, width: "30px" }}
                      size="medium"
                      key="yes"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-end">
                  <div className=""></div>
                </div>
                <CustomTable
                  headCells={BidsTableHeaderCells}
                  rows={rows}
                  handleCheck={handleCheck}
                  handleCheckAll={handleCheckAll}
                  onSelectRowClick={handleEmailClick}
                  //   style={inviteModalStyle}
                  onDeleteRowClick={() => null}
                />
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
      <Modal
        id="addInviteSubContractor"
        isOpen={openInviteModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-">
          <div
            className="modal-content"
            style={{ height: "380px", width: "450px" }}
          >
            <div className="modal-header border-bottom justify-content-center">
              <div className="">
                <h4>Invite Sub Contractor</h4>
              </div>
            </div>
            <div
              className="d-grid shadow mt-3"
              style={{ backgroundColor: "#f3f6f7" }}
            >
              <div className="container">
                <div className="form-floating mt-3 mb-3">
                  <InputField
                    id="orgName"
                    name="orgName"
                    type="text"
                    value={inviteSubConFormData.orgName}
                    handleChange={handleChange}
                    className="form-control"
                    label="Organization Name"
                    // error={props.isSubmit && props.touched.firstName && props.errors.firstName}
                    required
                  />
                </div>
                <div className="form-floating mt-3 mb-3">
                  <InputField
                    id="name"
                    name="name"
                    type="text"
                    value={inviteSubConFormData.name}
                    handleChange={handleChange}
                    className="form-control"
                    label="Name"
                    // error={props.isSubmit && props.touched.lastName && props.errors.lastName}
                    required
                  />
                </div>
                <div className="form-floating mt-3 mb-3">
                  <InputField
                    id="phoneNo"
                    name="phoneNo"
                    type="text"
                    value={inviteSubConFormData.phoneNo}
                    handleChange={handleChange}
                    className="form-control"
                    label="Phone Number"
                    // error={props.isSubmit && props.touched.phoneNo && props.errors.phoneNo}
                    required
                  />
                </div>
                <div className="form-floating mt-3 mb-3">
                  <InputField
                    id="email"
                    name="email"
                    type="email"
                    value={inviteSubConFormData.email}
                    handleChange={handleChange}
                    className="form-control"
                    label="Email"
                    // error={props.isSubmit && props.touched.email && props.errors.email}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-around mt-4 mb-4">
              <div className="row mt-2 mb-2">
                <Button
                  className="btn col-3 col-md-3 col-sm-3 p-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenInviteModal(false);
                  }}
                  style={{
                    backgroundColor: color.darkColor,
                    color: "white",
                    width: "150px",
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className="row mt-2 mb-2">
                <Button
                  className="btn col-3 col-md-3 col-sm-3 p-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenInviteModal(false);
                    createInvitedSubConRow();
                    setInviteSubConFormData(defaultFormData);
                  }}
                  style={{
                    backgroundColor: color.darkColor,
                    color: "white",
                    width: "150px",
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <BidInviteModal
        isOpen={openBidInviteModal}
        handleClose={handleClose}
        subMailsList={subContractorMails}
        handleSubmit={handleSubmit}
        isLoading= {isLoading}
        tradeId = {node.id}
      />
      <CustomizedSnackbar
        openSnackBar={isOpen}
        message={message}
        handCloseSnackBar={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
};

export default SubListDropdown;
