import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProjectList from "./MyProjectList/projectList";
import NewProjectForm from "./NewProjectForm";
import { faFileCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { ProjectFormState } from "../../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStepAction } from "../../redux/actions/projectFormActions";
import { selectThemeColor } from "../../redux/selectors/selectors";

const ManageProject = (props: any) => {
  const color = useSelector(selectThemeColor);
  const [row, setRow] = useState<ProjectFormState | []>([]);
  const [isViewBids, setIsViewBids] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.isBack) {
    }
  }, [props.isBack]);
  
  const handleEditSelect = (row: any) => {
    props.setBackButton(true);
    props.getSubMenu(" / Edit Project");
    dispatch(setActiveStepAction(0));
    setIsViewBids(false);
    setRow(row);
  };

  const handleBidsSelect = (row: any) => {
    dispatch(setActiveStepAction(3));
    props.setBackButton(true);
    props.getSubMenu(" / View Project");
    setRow(row);
    setIsViewBids(true);
  }

  return (
    <div className="container">
        <ProjectList onEditSelect={handleEditSelect} onBidsSelect={handleBidsSelect} />
    </div>
  );
};

export default ManageProject;
