import { useContext, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { CustomTable } from "../../DataTable";
import InputField from "../../InputField";
import { getUsersAddedByMe, userSearch } from "../../../utils/api";
import { getUserHeaderCells } from "../../../utils/const";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../../redux/selectors/selectors";

type Row = {
  id: any;
  first_name: string;
  email: string;
  phone_number: string;
  status: string;
};

type Values = {
  email: string;
  phoneNo: string;
  status: string;
};

const UsersList = (props: any) => {
  const [rows, setRows] = useState<Row[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<Values>({
    email: "",
    phoneNo: "",
    status: "",
  });

  const color = useSelector(selectThemeColor);

  const style = [
    { width: "100%", height: "60vh", overflowX: "hidden", overflowY: "hidden" },
  ];

  useEffect(() => {
    getUsersAddedByMe("", undefined, undefined)
      .then((response: any) => {
        const data = usersOfOrgList(response.data);
        setRows(data);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }, []);

  const usersOfOrgList = (data: any[]) => {
    return (
      data &&
      data.map((item: any) => ({
        id: item.id,
        first_name: item.firstName + " " + item.lastName,
        email: item.email,
        phone_number: item.mobileNo.replace(/\D/g, "")
        .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
        status: item.status,
      }))
    );
  };

  const handleChange = (event: any) => {
    const { name, value } = event?.target;
    console.log("name", name);
    console.log("value", value);
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleSearch = () => {
    const { email, phoneNo, status } = search;
    console.log("serach", search);
    const body = {
      email: email.replace(/[^a-zA-Z0-9@.]/g, "").trim(),        
      mobileNo: phoneNo.replace(/[^0-9]/g, "").trim(),
      status: status.replace(/[^a-zA-Z0-9]/g, "").trim(),
    };

if(body.email==="" && body.mobileNo==="" && body.status==="")
    {
      getUsersAddedByMe("", undefined, undefined)
      .then((response: any) => {
        const data = usersOfOrgList(response.data);
        setRows(data);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
    }
    else{
    userSearch("", undefined, body)
      .then((response) => {
        console.log("userSearch", response);
        if (response.status === "OK") {
          const data = usersOfOrgList(response.data);
          setRows(data);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
      });
    }
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-3 col-md-3 col-sm-3">
            <InputField
              id="email"
              name="email"
              type="email"
              className="form-control"
              label="Email"
              handleChange={handleChange}
              value={search.email}
            />
          </div>
          <div className="col-3 col-md-3 col-sm-3">
            <InputField
              id="phoneNo"
              name="phoneNo"
              type="text"
              className="form-control"
              label="Phone No."
              handleChange={handleChange}
              value={search.phoneNo.replace(/\D/g, "")
                .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}
                maxLength={10}
            />
          </div>
          <div className="col-3 col-md-3 col-sm-3">
            <FormControl fullWidth>
              <InputLabel
                id="select-label"
                size="small"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  fontFamily: "SenticosansDTCond Thin",
                }}
              >
                Select Status
              </InputLabel>
              <Select
                labelId="select-label"
                id="select"
                name="status"
                onChange={handleChange}
                label="Select Status"
                size="small"
              >
                <MenuItem value=""></MenuItem>
                <MenuItem key="Active" value="Active">
                  Active
                </MenuItem>
                <MenuItem key="Inactive" value="Inactive">
                  Inactive
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-3 col-md-3 col-sm-3">
            <Button
              className="btn col-5 col-md-5 col-sm-5 ms-5"
              style={{ backgroundColor: color.darkColor, color: "white" }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </div>
        </div>
        <div className="row mt-3">
          <CustomTable
            headCells={getUserHeaderCells}
            rows={rows}
            style={style}
            onSelectRowClick={(row: any) => {
              props.setBack(true);
              props.getSubMenu("/ Edit User");
              props.onEditSelect(row);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default UsersList;
