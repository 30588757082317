import { Avatar, Button } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../redux/selectors/selectors";

type Message = {
  text: string;
  sender: string;
};
interface Props {
  receiverName: string;
  handleClose: () => void;
  sender: string;
}

const ChatMessageBox = ({ receiverName, handleClose, sender }: Props) => {
  const color = useSelector(selectThemeColor);
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  const sendMessage = () => {
    if (!inputValue.trim()) return;
    setMessages([...messages, { text: inputValue, sender }]);
    setInputValue("");
  };

  const stringToColor = (string: string) => {
    let hashCode = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hashCode = string.charCodeAt(i) + ((hashCode << 5) - hashCode);
    }

    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hashCode >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const stringAvatar = (colorName: string) => {
    if (!colorName) {
      return {
        sx: {
          bgcolor: color.lightColor,
        },
        children: `NA`,
      };
    }
    return {
      sx: {
        bgcolor: stringToColor(colorName),
      },
      children: `${colorName[0][0]}`,
    };
  };
  
  return (
    <div
      className="floating-chat chatbox-outerdiv"
      
    >
      <div className="bg-light">
        <div className="row gx-0" style={{ height: "25vh" }}>
          <ul
            className="messages"
            style={{ height: "25vh", overflowX: "hidden", overflowY: "scroll" }}
          >
            <li className="mt-3">
              <div className="btn col-12 mx-auto opacity-50">Today</div>
            </li>
            <div className="row gx-0">
              <div className="col-1 d-flex justify-content-end">
                <Avatar
                  className="size-sm"
                  {...stringAvatar(receiverName || "D")}
                  sizes="small"
                />
              </div>
              <li className="sender-message col-10">
                <div className="sender-message">
                  What is the expected start date and duration of the project?
                </div>
              </li>
            </div>

            <div className="row gx-0 mt-4">
              <li
                className="receiver-message col-10 ms-5 gx-0"
                style={{ background: color.mediumColor }}
              >
                <div
                  className="receiver-message col-12"
                  style={{ background: color.mediumColor }}
                >
                  The project is slated to commence on 03/09/2024, pending
                  finalization of permits and approvals. It is estimated to span
                  approximately 4 months from the commencement date to
                  substantial completion. This timeline includes all phases of
                  construction, from site preparation and foundation work to the
                  installation of structural elements, interior finishes, and
                  final landscaping. Throughout the project, regular progress
                  updates and milestone assessments will be conducted to ensure
                  adherence to the schedule and address any unforeseen
                  challenges that may arise. Timely completion is essential to
                  meeting client expectations and maintaining operational
                  efficiency.
                </div>
              </li>
              <div className="col-1 d-flex justify-content-end mt-3">
                <Avatar
                  className="size-sm"
                  {...stringAvatar("Me" || "D")}
                  sizes="small"
                />
              </div>
            </div>
            {messages.map((message, index) => (
              <div className="row gx-0 mt-4">
                <div className="col-1 d-flex justify-content-end">
                  <Avatar
                    className="size-sm"
                    {...stringAvatar(
                      message.sender === "self" ? "Me" : receiverName
                    )}
                    sizes="small"
                  />
                </div>
                <li
                  key={index}
                  className={
                    message.sender === "self"
                      ? "sender col-10"
                      : "receiver col-10"
                  }
                >
                  <div
                    className={
                      message.sender === "self"
                        ? "sender-message"
                        : "receiver-message"
                    }
                  >
                    {message.text}
                  </div>
                </li>
              </div>
            ))}
          </ul>
        </div>

        <div className="footer row d-flex justify-content-between p-2 gx-0">
          <input
            className="form-control col-9"
            type="text"
            placeholder="Enter Message"
            value={inputValue}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter" && inputValue) sendMessage();
            }}
            onChange={(e: any) => {
              setInputValue(e.target.value);
            }}
          />
          <Button
            id="sendMessage"
            className="col-2"
            type="submit"
            onClick={sendMessage}
            disabled={!inputValue || inputValue === ""}
            style={{ background: color.darkColor, color: "white" }}
          >
            <span style={{ fontWeight: "bold" }}>Send</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChatMessageBox;
