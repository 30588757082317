export const LaborAffilication = [
  "Union",
  "Non Union",
  "Prevailing Wage",
  "None of the above",
];

export const NewProjectSteps = [
  { label: "Basic Project Details", id: 0 },
  { label: "Documents", id: 1 },
  { label: "Trades", id: 2 },
  // { label: "Bids", id: 3 },
  // { label: "Finalizing Subcontrator", id: 2 },
];

export const ConstructionTypeList = [
  "Tenant Improvements (TI)",
  "Renovation/Remodel",
  "Site Improvement",
  "New Construction",
];

export const ProjectDocumentList = [
  { id: 1, value: "Plans" },
  { id: 2, value: "Specifications" },
  { id: 3, value: "Soil Report" },
  { id: 4, value: "Drawing" },
  { id: 5, value: "Pictures" },
  { id: 6, value: "Revisions" },
  { id: 7, value: "Others" },
];

export const RequirementList = [
  { id: 1, name: "DVBE Good Faith Efforts" },
  { id: 1, name: "Project Labor Agreement (PLA)" },
  { id: 1, name: "Community Workforce Agreement (CWA)" },
  { id: 1, name: "Skilled And Trained Workforce Requirements (STW)" },
];

export const OnboardFormSection = [
  { label: "Basic Details", id: 0 },
  { label: "Classifications & Other Details", id: 1 },
  { label: "Trades", id: 2 },
];

export const PostedByMeHeaderCells = [
  { id: "bidDate", name: "Bid Date", action: "" },
  { id: "projectName", name: "Project Name", action: "click" },
  { id: "city", name: "City", action: "" },
  { id: "status", name: "Status", action: "dropDown" },
];

export const InvitedByOtherHeaderCells = [
  { id: "bidDate", name: "Bid Date & Time", action: "" },
  { id: "jobWalkDate", name: "Job Date & Time", action: "" },
  { id: "projectName", name: "Project Name", action: "click" },
  { id: "city", name: "City", action: "" },
  { id: "invitedBy", name: "Invited By", action: "" },
  //To be used afterwards
  //{ id: "status", name: "Status", action: "inviteDropDown" },
];

export const exploreOpprtunitiesheaderCells = [
  { id: "projectName", name: "Project Title", action: "" },
  { id: "bid", name: "Bid#", action: "" },
  { id: "bidDate", name: "Due Date", action: "date" },
  { id: "jobWalkDate", name: "Job Date & Time", action: "date" },
  { id: "invitedBy", name: "By GC", action: "" },
  { id: "city", name: "City", action: "" },
];

export const getUserHeaderCells = [
  { id: "first_name", name: "Name", action: "" },
  { id: "email", name: "Email", action: "" },
  { id: "phone_number", name: "Phone Number", action: "" },
  { id: "status", name: "Status", action: "" },
  { id: "action", name: "Action", action: "edit" },
];

export const exploreProjectSelectedRow = {
  id: 995563,
  projectName: "Lucy Street Construction, Grand Lake, CO",
  bidDate: "11-06-2023",
  budget: 86091,
  projectOwnerName: "US Department of Agriculture (USDA) DC",
  city: "New York",
  sector: "Private",
  division: "Fire Protection",
  specification: "Concrete : Material Processing and Handling Equipment",
  constructionType: "New Construction",
  projectVisibility: "Private",
  unitNumber: 1,
  address1: "1070 Killdeer Pass",
  address2: "6118 Steensland Road",
  state: "New York",
  zipcode: "8607",
  name: "Hobey",
  email: "hneale0@hhs.gov",
  phoneNo: "+07-5577071324",
  zipCode: "317743",
  discription: "Project description",
};

export const editOrganizationHeaderCells = [
  { id: "first_name", name: "Name", action: "" },
  { id: "email", name: "Email", action: "" },
  { id: "phone_number", name: "Phone Number", action: "" },
  { id: "status", name: "Status", action: "" },
];

export const organizationListHeaderCells = [
  { id: "org_name", name: "Organization Name", action: "" },
  { id: "license_num", name: "License Number", action: "" },
  { id: "laborAffiliation", name: "Labor Affiliation", action: "" },
  { id: "created_date", name: "Registration Date", action: "" },
  { id: "action", name: "Action", action: "edit" },
];

export const ProjectDocumentHeaders = [
  { id: "docName", name: "Title", action: "title" },
  { id: "docName", name: "File Name", action: "" },
  { id: "size", name: "Size", action: "size" },
  { id: "bidDate", name: "Date", action: "" },
  { id: "action", name: "Action", action: "viewAndDelete" },
];

export const ProjectAddendaHeader = [
  { id: "addendaDate", name: "Date", action: "date" },
  { id: "addendaNumber", name: "Number", action: "" },
  { id: "addendaType", name: "Name", action: "" },
  { id: "docName", name: "File Name", action: "" },
  { id: "", name: "Action", action: "viewAndDelete" },
];

export const ProjectOthersHeader = [
  { id: "docName", name: "File Name", action: "" },
  { id: "size", name: "Size", action: "size" },
  { id: "", name: "Action", action: "viewAndDelete" },
];


export const BidStatus = [
  "Actively Bidding",
  "Post Bid",
  "Under Construction",
  "Closed",
  "Draft"
];

export const BuildingType = [
  "Automotive",
  "Aviation/Hangars",
  "Civil-Site Development",
  "Country Clubs-Hotels-Recreation",
  "Health Care-Medical",
  "Health/Fitness Center",
  "High-tech-R&D-Labs",
  "Industrial-Commercial",
  "Institutional-Schools",
  "Interiors-Tenant Improvements",
  "Municipal-Government-Civic",
  "Office Buildings",
  "Parking Structures",
  "Places of Worship",
  "Residential",
  "Restaurant",
  "Retail Centers",
  "Self-Storage",
  "Special Projects",
];

export const BidType = [
  "Competitive",
  "Construction Management (CM)",
  "Negotiated",
  "Prevailing Wage",
];

export const ProjectContact = ["Estimator", "Project Manager"];

export const InvitedByOthersData = [
  {
    bidDatetime: "2024-07-30 13:30 PM",
    jobWalkDatetime: "2024-07-30 12:27 PM",
    invitedProjectName: "qwertyuik",
    city: "New York City",
    invitedBy: "XYZTEST",
    action: "-",
  },
];

export const buttonGroupProject = [
  { label: "Bid Information", value: "bidInfo", headers: "" },
  { label: "Documents", value: "Document", headers: ProjectDocumentHeaders },
  { label: "Addenda / Files", value: "Addenda", headers: ProjectAddendaHeader },
  { label: "Q&A", value: "qAndA", headers: ProjectOthersHeader },
  { label: "Photos", value: "Photo", headers: ProjectOthersHeader },
];

export const btnGrpForBidStatus = [
  {label: "Accept", value: "Accepted" },
  {label: "Reject", value: "Rejected" },
  {label: "MayBe", value: "May Be" } ];

export const InvitedTradesHeaders = [
  {id: "tradeName", name: "Trade Name", action: "tradeName"},
  {id: "tradeCode", name: "Trade Code", action: ""},
  {id: "bidStatus", name: "Action", action: "bidStatusUpdate"},
];

export const submitTradeHeaderCells = [
  {id: "name", name: "Trade Name", action: ""},
  {id: "tradeCode", name: "Trade Code", action: ""},
  {id: "actionTaken", name: "Action Taken", action: ""},
  {id: "action", name: "Action", action: "removeTrade"},
];

export const cityNames = [
  "New York City","Los Angeles","Chicago","Austell","Houston","Phoenix","Philadelphia",
  "San Antonio","San Diego","Dallas","San Jose","Austin","Jacksonville","Fort Worth",
  "Columbus","Indianapolis","Charlotte","San Francisco","Seattle","Denver","Oklahoma City",
  "Nashville","El Paso","Washington","Boston","Las Vegas","Portland","Detroit","Louisville",
  "Memphis","Baltimore","Milwaukee","Albuquerque","Fresno","Tucson","Sacramento","Mesa","Kansas City",
  "Atlanta","Omaha","Colorado Springs","Raleigh","Virginia Beach","Long Beach","Miami","Oakland",
  "Minneapolis","Tulsa","Bakersfield","Wichita","Arlington","Aurora","Tampa","New Orleans","Cleveland",
  "Anaheim","Honolulu","Henderson","Stockton","Lexington","Corpus Christi","Riverside","Santa Ana",
  "Orlando","Irvine","Cincinnati","Newark","Saint Paul","Pittsburgh","Greensboro","St. Louis","Lincoln",
  "Plano","Anchorage","Durham","Jersey City","Chandler","Chula Vista","Buffalo","North Las Vegas","Gilbert",
  "Madison","Reno","Toledo","Fort Wayne","Lubbock","St. Petersburg","Laredo","Irving","Chesapeake",
  "Winston–Salem","Glendale","Scottsdale","Garland","Boise","Norfolk","Spokane","Fremont","Richmond",
  "Santa Clarita","San Bernardino","Baton Rouge","Hialeah","Tacoma","Modesto","Port St. Lucie","Huntsville",
  "Des Moines","Moreno Valley","Fontana","Frisco","Rochester","Yonkers","Fayetteville","Worcester","Columbus",
  "Cape Coral","McKinney","Little Rock","Oxnard","Amarillo","Augusta","Salt Lake City","Montgomery","Birmingham",
  "Grand Rapids","Grand Prairie","Overland Park","Tallahassee","Huntington Beach","Sioux Falls","Peoria",
  "Knoxville","Glendale","Vancouver","Providence","Akron","Brownsville","Mobile","Newport News","Tempe",
  "Shreveport","Chattanooga","Fort Lauderdale","Aurora","Elk Grove","Ontario","Salem","Cary","Santa Rosa",
  "Rancho Cucamonga","Eugene","Oceanside","Clarksville","Garden Grove","Lancaster","Springfield",
  "Pembroke Pines","Fort Collins","Palmdale","Salinas","Hayward","Corona","Paterson","Murfreesboro",
  "Macon","Lakewood","Killeen","Springfield","Alexandria","Kansas City","Sunnyvale","Hollywood","Roseville",
  "Charleston","Escondido","Joliet","Jackson","Bellevue","Surprise","Naperville","Pasadena","Pomona",
  "Bridgeport","Denton","Rockford","Mesquite","Savannah","Syracuse","McAllen","Torrance","Olathe","Visalia",
  "Thornton","Fullerton","Gainesville","Waco","West Valley City","Warren","Lakewood","Hampton","Dayton",
  "Columbia","Orange","Cedar Rapids","Stamford","Victorville","Pasadena","Elizabeth","New Haven","Miramar",
  "Kent","Sterling Heights","Carrollton","Coral Springs","Midland","Norman","Athens","Santa Clara","Columbia",
  "Fargo","Pearland","Simi Valley","Meridian","Topeka","Allentown","Thousand Oaks","Abilene","Vallejo","Concord",
  "Round Rock","Arvada","Clovis","Palm Bay","Independence","Lafayette","Ann Arbor","Rochester","Hartford",
  "College Station","Fairfield","Wilmington","North Charleston","Billings","West Palm Beach","Berkeley",
  "Cambridge","Clearwater","West Jordan","Evansville","Richardson","Broken Arrow","Richmond","League City",
  "Manchester","Lakeland","Carlsbad","Antioch","Westminster","High Point","Provo","Lowell","Elgin",
  "Waterbury","Springfield","Gresham","Murrieta","Lewisville","Las Cruces","Lansing","Beaumont","Odessa",
  "Pueblo","Peoria","Downey","Pompano Beach","Miami Gardens","Temecula","Everett","Costa Mesa","Ventura",
  "Sparks","Santa Maria","Sugar Land","Greeley","South Fulton","Dearborn","Concord","Edison","Tyler",
  "Sandy Springs","West Covina","Green Bay","Centennial","Jurupa Valley","El Monte","Allen","Hillsboro",
  "Menifee","Nampa","Spokane Valley","Rio Rancho","Brockton","El Cajon","Burbank","Inglewood","Renton",
  "Davie","Rialto","Boulder","South Bend","Woodbridge","Vacaville","Wichita Falls","Lee's Summit","Edinburg",
  "Chico","San Mateo","Bend","Goodyear","Buckeye","Daly City","Fishers","Quincy","Davenport","Hesperia",
  "New Bedford","Lynn","Carmel","Longmont","Tuscaloosa","Norwalk",
];