import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheckCircle,
  faClose,
  faLongArrowRight,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { logIn, sendVerificationLink } from "../../utils/api";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Tab,
  Typography,
  Checkbox,
  Tabs,
} from "@mui/material";
import Utils from "./utils";
import InputField from "../InputField";
import CustomizedSnackbar from "../CustomizedSnackbar";
import {  Visibility, VisibilityOff } from "@mui/icons-material";
import Loader from "../Loader";
import { setActiveStepAction } from "../../redux/actions/projectFormActions";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const style = {
  content: {
    top: "15%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
    opacity: "1",
    background: "#f8f9fa",
    zIndex: 1000,
  },
};

const Login = () => {
  const [isLoading, setisLoading] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [modalMessage, setmodalMessage] = useState(
    " Sign-up will be completed once you verify your email address.A verification link has been sent to your email."
  );
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [showSnackBar, setShowsnackBar] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const history = useNavigate();
  const { values, handleChange, errors, setFieldTouched, touched } = Utils();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setisLoading(true);
    const body: any = {
      emailOrMobileNo: values.emailAndPhone,
      password: values.password,
    };

    logIn("", undefined, body)
      .then((response: any) => {
        if (response.status === "OK") {
          const {
            menus,
            name,
            accessToken,
            role,
            orgName,
            updatePassword,
            emailVerfified,
          } = response.data;
          if (updatePassword.includes("Y")) {
            history("/reset-password", { state: values.emailAndPhone + "" });
          } else if (emailVerfified.includes("N")) {
            setOpenSuccessModal(true);
            sendVerificationLink("", undefined, {
              email: values.emailAndPhone,
            })
              .then(() => {})
              .catch((error) => {
                setOpenSuccessModal(true);
                setmodalMessage(
                  "Server is not reachable. Please contact the administrator."
                );
                console.log("error", error);
              });
          } else {
            setShowsnackBar(true);
            setAlertMessage("Login Successfully");
            localStorage.setItem("name", name);
            localStorage.setItem("role", role);
            localStorage.setItem("menus", JSON.stringify(menus));
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("orgName", orgName);
            history("/dashboard");
          }
        } else if (response.status === "UNAUTHORIZED") {
          setAlertMessage(
            () =>
              "Bad request. Please fill in correct information and try again."
          );
        } else {
          setShowsnackBar(true);

          let alertMessage;

          if (response.message.trim() === "") {
            alertMessage =
              "Server is not reachable. Please contact the administrator.";
          } else {
            alertMessage = response.message;
          }
          setAlertMessage(alertMessage);
        }
        setisLoading(false);
      })
      .catch((e) => {
        let alertMessage;

        if (e.message.trim() === "") {
          alertMessage =
            "Server is not reachable. Please contact the administrator.";
        } else {
          alertMessage = e.message;
        }
        setAlertMessage(alertMessage);

        setisLoading(false);
      })

      .finally(() => {});
  };

  const handleClickSignup = () => {
      navigate('/sign-up');
      dispatch(setActiveStepAction(0));
  };

  return (
    <>
      {/* <GlobalHeader title="" isLogoVisible /> */}
      <div className="login-container overflow-hidden">
        <div className="col-6 p-0">
          <div
            style={{
              background:
                "linear-gradient(to bottom, #1a2556 10%,#1d285b 50%,#202f71)",
              height: "100%",
            }}
          >
            <div className="container" style={{ background: "#1a2556" }}>
              <div className="ms-2">
                <img
                  src="BW logo-blue bg-svg.svg"
                  width="250"
                  height="70"
                  className="ms-4 mt-5"
                  alt="Login page"
                  onError={(e) => {
                    const img = e.target as HTMLImageElement;
                    img.src = "image-not-found.png";
                  }}
                />
              </div>
              <h2
                className="row offset-1 fs-55 mt-5  glow-text"
                style={{ color: "rgba(102, 140, 255, 0.4)" }}
              >
                Want to explore the
              </h2>
              <h2
                className="row offset-1 fs-55 glow-text"
                style={{ color: "rgba(102, 140, 255, 0.4)" }}
              >
                <span className="col-3 fw-bold">business</span>{" "}
                <span className="col-4 offset-1">opportunities?</span>
              </h2>

              <Link
                className="row"
                to={"/explore"}
                style={{ textDecoration: "none", color: "#579257" }}
              >
                <div className="offset-1 gx-5">
                  <span className="mt-1 size-2xl">
                    <b>Let's Go</b> <FontAwesomeIcon icon={faLongArrowRight} />{" "}
                  </span>
                </div>
              </Link>
            </div>
            <div
              className="container-fluid p-0"
              style={{
                background:
                  "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%,rgb(34, 48, 119) 100%)",
                height: "50vh",
                position: "relative",
              }}
            >
              <img
                className="col-12 p-0"
                style={{ height: "100%", width: "100%" }}
                src="login-form-img.png"
                alt="Login page"
                onError={(e) => {
                  const img = e.target as HTMLImageElement;
                  img.src = "image-not-found.png";
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-6 d-flex justify-content-center mt-3">
          <div
            className="col-6"
            style={{ maxWidth: "600px", marginTop: "20%" }}
          >
            <div className="row justify-content-center">
              <Button
                className="btn col-3"
                style={{
                  borderBottom: "solid",
                  borderColor: "#19255C",
                  fontWeight: "bold",
                  color: "#19255C",
                }}
              >
                Sign In
              </Button>
              <Button
                className=" btn col-8 offset-1"
                style={{
                  color: "#19255C",
                }}
                onClick={handleClickSignup}
              >
                Create An Account
              </Button>
            </div>
            <div className="row">
              <form className="form-group" onSubmit={handleSubmit}>
                <div className="row  mt-3">
                  <div className="col-12">
                    <InputField
                      className="form-control"
                      id="emailAndPhone"
                      name="emailAndPhone"
                      type="text"
                      value={values.emailAndPhone}
                      handleChange={handleChange}
                      handleBlur={() => setFieldTouched("emailAndPhone", true)}
                      label="Email / Mobile No."
                      error={touched.emailAndPhone && errors.emailAndPhone}
                      required
                      maxLength={50}
                    />
                  </div>
                </div>
                <div className="row  mt-2">
                  <div className="col-12">
                    <InputField
                      id="password"
                      className="form-control"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      handleChange={handleChange}
                      handleBlur={() => setFieldTouched("password", true)}
                      label="Password"
                      error={touched.password && errors.password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 mt-2">
                    <Button
                      className="col-12 btn position-relative"
                      type="submit"
                      style={{
                        background: "#19255C",
                        color: "white",
                        fontSize: "14px",
                      }}
                    >
                      {isLoading ? (
                        <CircularProgress
                          className="ms-1"
                          color="info"
                          variant="indeterminate"
                          thickness={2}
                          size={26}
                        />
                      ) : (
                        "Sign In"
                      )}
                    </Button>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-7 d-flex justify-content-start">
                    <Checkbox aria-label="Keep me signed in" />
                    <span className="mt-2">
                      Keep me signed in
                    </span>
                  </div>

                  <Link
                    className="col-5 d-flex justify-content-end mt-2"
                    to="/forget-password"
                  >
                    Reset Password?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <CustomizedSnackbar
        openSnackBar={showSnackBar}
        message={alertMessage}
        handCloseSnackBar={() => setShowsnackBar(false)}
      />
      <Modal
        id="succes"
        isOpen={openSuccessModal}
        style={style}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="mx-auto">
                <Typography className="gx-0 d-block justify-content-center size-sm">
                  {modalMessage}
                </Typography>
              </div>
              <div className="ms-3">
                <FontAwesomeIcon
                  icon={faClose}
                  className="pointer"
                  style={{ color: "black" }}
                  size="lg"
                  onClick={() => setOpenSuccessModal(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Login;
