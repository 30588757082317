import Checkbox from "@mui/material/Checkbox";
import Modal from "react-modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faEdit,
  faEnvelopeCircleCheck,
  faEye,
  faGavel,
  faTrash,
  faBarsProgress,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../redux/selectors/selectors";
import InputField from "../InputField";
import { BidStatus, btnGrpForBidStatus } from "../../utils/const";
import { useDispatch } from "react-redux";
import { setBidInvitationMailList } from "../../redux/actions/projectFormActions";

const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
    opacity: "1",
    background: "#f8f9fa",
    zIndex: "0",
  },
};

interface Props {
  headCells: any[];
  rows: any[];
  style?: any;
  onSelectRowClick?: (row: any, actionType: string, checked: boolean) => void;
  handleCheck?: (row: any, checked: boolean) => void;
  onClickGetCell?: (cell: any) => void;
  onDeleteRowClick?: (index: any) => void;
  onViewRow?: (index: any) => void;
  handleCheckAll?: (rows: any) => void;
  handleChangeDropDownStatus?: (id: number, status: string) => void;
  handelRemoveTrade?: (id:number, actionTaken: string) => void;
}

const CustomTable = ({
  headCells,
  rows,
  onSelectRowClick,
  onClickGetCell = () => {
    return;
  },
  onDeleteRowClick,
  onViewRow,
  style,
  handleCheck,
  handleCheckAll,
  handleChangeDropDownStatus,
  handelRemoveTrade,
}: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>("");
  const [subContractorMails, setSubContractorMails] = useState<string[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [checkStatus, setCheckStatus] = useState<string>("");
  const [selectTradeId, setSelectTradeId] = useState<any[]>([])

  const color = useSelector(selectThemeColor);
  const handleOpenModal = (data: any) => {
    setOpenModal(true);
    setSelectedRow(data);
  };

  const dispatch = useDispatch();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: color.mediumColor,
      color: "#fff",
      fontSize: 16,
      fontWeight: "600",
      padding: "0.7 rem",
      width: "auto",
      minWidth: 100,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
    },
  }));

  const getTableCell = (cell: any, row: any, index: number) => {
    switch (cell.action) {
      case "check": {
        return (
          <StyledTableCell key={cell.id}>
            {row.status === "" ||
              (row.status === null && (
                <Checkbox
                  style={{ color: color.darkColor, width: "20px" }}
                  key={index}
                  checked={subContractorMails
                    .map((mail) => mail)
                    .includes(row["email"])}
                  onChange={(event) => {
                    handleCheck!(row, !row.isSelected);
                    const { email } = row;
                    let mailList = [...subContractorMails];

                    const index = subContractorMails.indexOf(email);
                    if (index !== -1) {
                      mailList = mailList.filter(
                        (mail) =>
                          !mail.toLowerCase().includes(email.toLowerCase())
                      );
                    } else {
                      mailList = [...mailList, email];
                    }
                    dispatch(
                      setBidInvitationMailList(Array.from(new Set(mailList)))
                    );
                    setSubContractorMails(Array.from(new Set(mailList)));
                  }}
                />
              ))}
          </StyledTableCell>
        );
      }
      case "click": {
        return row.status === "Rejected" && headCells[0].id === "response" ? (
          <StyledTableCell sx={{ overflowWrap: "break-word" }} key={cell.id}>
            <span> {row[cell.id]}</span>
          </StyledTableCell>
        ) : (
          <StyledTableCell
            sx={{ cursor: "pointer", overflowWrap: "break-word" }}
            key={cell.id}
            onClick={() => {
              onSelectRowClick!(row, "click", false);
              onClickGetCell!(
                headCells.filter((cell) => cell.id === "invitedBy")
              );
            }}
          >
            <Link
              className="text-truncate"
              style={{ color: "primary", textDecoration: "none" }}
              to={""}
            >
              {row[cell.id]}
            </Link>
          </StyledTableCell>
        );
      }
      case "openModal": {
        return (
          <StyledTableCell
            sx={{ cursor: "pointer" }}
            key={cell.id}
            onClick={() => handleOpenModal(row)}
          >
            {row[cell.id]}
          </StyledTableCell>
        );
      }

      case "date": {
        return (
          <StyledTableCell
            sx={{ cursor: "pointer" }}
            align="left"
            key={cell.id}
          >
            {row[cell.id]}
          </StyledTableCell>
        );
      }

      case "number": {
        return (
          <StyledTableCell
            sx={{ cursor: "pointer" }}
            align="center"
            key={cell.id}
          >
            {row[cell.id]}
          </StyledTableCell>
        );
      }

      case "phone": {
        return (
          <StyledTableCell
            sx={{ cursor: "pointer" }}
            align="center"
            key={cell.id}
          >
            {row[cell.id] ? (
              row[cell.id]
                .replace(/\D/g, "")
                .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
            ) : (
              <></>
            )}
          </StyledTableCell>
        );
      }

      case "isRowActionable": {
        return (
          <StyledTableCell key={cell.id}>
            <Tooltip
              title={
                <React.Fragment>
                  <div className="tooltip-title">
                    <span>View Bid</span>
                  </div>
                </React.Fragment>
              }
            >
              <div className="d-flex jusstify-content-center ms-2 gap-2">
                <img
                  style={{ cursor: "pointer" }}
                  src="/Action-arrow.svg"
                  alt="Action Arrow"
                  onClick={() => {
                    onSelectRowClick!(row, "edit", false);
                  }}
                />
              </div>
            </Tooltip>
          </StyledTableCell>
        );
      }
      case "edit": {
        return (
          <StyledTableCell sx={{ cursor: "pointer" }} key={cell.id}>
            <div className="d-flex ms-2 gap-3">
              <Tooltip
                title={
                  <React.Fragment>
                    <div className="tooltip-title">
                      <span>Edit</span>
                    </div>
                  </React.Fragment>
                }
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  size="lg"
                  onClick={() => {
                    onSelectRowClick!(row, "edit", false);
                  }}
                />
              </Tooltip>
            </div>
          </StyledTableCell>
        );
      }
      case "view": {
        return (
          <StyledTableCell sx={{ cursor: "pointer" }} key={cell.id}>
            <div className="d-flex ms-2 gap-3">
              <Tooltip
                title={
                  <React.Fragment>
                    <div className="tooltip-title">
                      <span>View</span>
                    </div>
                  </React.Fragment>
                }
              >
                <FontAwesomeIcon
                  icon={faEye}
                  size="lg"
                  onClick={() => {
                    onSelectRowClick!(row, "view", false);
                  }}
                />
              </Tooltip>
            </div>
          </StyledTableCell>
        );
      }
      case "delete": {
        return (
          <StyledTableCell sx={{ cursor: "pointer" }} key={cell.id}>
            <div className="d-flex ms-2 gap-3">
              <Tooltip
                title={
                  <React.Fragment>
                    <div className="tooltip-title">
                      <span>Delete</span>
                    </div>
                  </React.Fragment>
                }
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  size="lg"
                  onClick={() => {
                    onDeleteRowClick!(index);
                  }}
                />
              </Tooltip>
            </div>
          </StyledTableCell>
        );
      }
      case "bidsAndEdit": {
        return (
          <StyledTableCell sx={{ cursor: "pointer" }} key={cell.id}>
            <div className="d-flex ms-2 gap-3">
              <Tooltip
                title={
                  <React.Fragment>
                    <div className="tooltip-title">
                      <span>Edit</span>
                    </div>
                  </React.Fragment>
                }
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  size="lg"
                  onClick={() => {
                    onSelectRowClick!(row, "edit", false);
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  <React.Fragment>
                    <div className="tooltip-title">
                      <span>Bids</span>
                    </div>
                  </React.Fragment>
                }
              >
                <FontAwesomeIcon
                  icon={faBarsProgress}
                  size="lg"
                  onClick={() => {
                    onSelectRowClick!(row, "bids", false);
                  }}
                />
              </Tooltip>
            </div>
          </StyledTableCell>
        );
      }
      case "viewAndEdit": {
        return (
          <StyledTableCell sx={{ cursor: "pointer" }} key={cell.id}>
            <div className="d-flex ms-2 gap-3">
              <Tooltip
                title={
                  <React.Fragment>
                    <div className="tooltip-title">
                      <span>Edit</span>
                    </div>
                  </React.Fragment>
                }
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  size="lg"
                  onClick={() => {
                    onSelectRowClick!(row, "edit", false);
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  <React.Fragment>
                    <div className="tooltip-title">
                      <span>Gavel</span>
                    </div>
                  </React.Fragment>
                }
              >
                <FontAwesomeIcon
                  icon={faGavel}
                  size="lg"
                  onClick={() => {
                    onSelectRowClick!(row, "view", false);
                  }}
                />
              </Tooltip>
            </div>
          </StyledTableCell>
        );
      }
      case "viewAndDelete": {
        return (
          <StyledTableCell sx={{ cursor: "pointer" }} key={cell.id}>
            <div className="d-flex ms-2 gap-3">
              <Tooltip
                title={
                  <React.Fragment>
                    <div className="tooltip-title">
                      <span>View</span>
                    </div>
                  </React.Fragment>
                }
              >
                <FontAwesomeIcon
                  icon={faEye}
                  size="lg"
                  onClick={() => {
                    onViewRow!(index);
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  <React.Fragment>
                    <div className="tooltip-title">
                      <span>Delete</span>
                    </div>
                  </React.Fragment>
                }
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  size="lg"
                  onClick={() => {
                    onDeleteRowClick!(index);
                  }}
                />
              </Tooltip>
            </div>
          </StyledTableCell>
        );
      }
      case "button": {

        return (
          <StyledTableCell sx={{ cursor: "pointer" }} key={cell.id}>
            {!row["publicBidStatus"] ? (
              <Button
                className="btn"
                style={{ background: color.mediumColor, color: "#fff" }}
                onClick={() => onSelectRowClick!(row, "edit", false)}
              >
                {cell.id}
              </Button>
            ) : (
              row["publicBidStatus"].toUpperCase()
            )}
          </StyledTableCell>
        );
      }
      case "dropDown": {
        return (
          <StyledTableCell sx={{ cursor: "pointer" }} key={cell.id}>
            <FormControl className="col-12 p-1">
              <InputLabel id="select-label" size="small" className="col-12">
                Select Status
              </InputLabel>
              <Select
                labelId="select-label"
                id="Status"
                name="Status"
                defaultValue={row.status}
                onChange={(e) =>
                  handleStatusChange(row.projectId, e.target.value)
                }
                label="Select Status"
                className="bg-white"
                size="small"
              >
                {BidStatus.map((status, index) => (
                  <MenuItem className="d-block ms-2" key={index} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledTableCell>
        );
      }
      case "inviteDropDown": {
        const showFlag = row.status === "Bid Submitted";
        return (
          <StyledTableCell sx={{ cursor: "pointer" }} key={cell.id}>
            <FormControl className="col-12 p-1">
              <InputLabel
                id="select-label"
                size="small"
                className="col-12 mt-1 bg-white"
              >
                Select Status
              </InputLabel>
              <Select
                labelId="select-label"
                id="Status"
                name="Status"
                defaultValue={row.status || "Invited"}
                onChange={(e) =>
                  handleStatusChange(row.projectId, e.target.value)
                }
                label="Select Status"
                className="bg-white"
                size="small"
                input={
                  showFlag ? (
                    <InputField
                      className="form-control bg-white col-12"
                      id="subject"
                      type="text"
                      name="subject"
                      label=""
                      defaultValue={row.status}
                      readonly
                      shrink
                    />
                  ) : (
                    <OutlinedInput label="Status" />
                  )
                }
              >
                {["Accepted", "Rejected", "May Be", "Invited"].map(
                  (status, index) => (
                    <MenuItem
                      className="d-block ms-2"
                      key={index}
                      value={status}
                    >
                      {status}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </StyledTableCell>
        );
      }

      case "bidStatusUpdate": {
        return (
          <>
            <StyledTableCell>
            <div className="d-flex justify-content-center">
              {btnGrpForBidStatus.map((button: any, index: number) => {
                return (
                  <>
                    <FormControlLabel
                      control={
                        <>
                        <Checkbox
                          key={index}
                          size="small"
                          value={
                            button.value !== row.status
                              ? button.value
                              : "Invited"
                          }
                          checked={row.status === button.value}
                          className="col-3 ms-1"
                          onChange={(e) => {
                            setCheckStatus(e.target.value);
                            handleCheck!(
                              {
                                id: row.id,
                                tradeName: row.tradeName,
                                tradeCode: row.tradeCode,
                                status: e.target.value,
                              },
                              e.target.checked
                            );
                          }}
                        />
                        <span className="ml-1 mt-1">{button.label}</span>
                        </>
                      }
                       label=""
                    />  
                  </>
                );
              })}
              </div>
            </StyledTableCell>
          </>
        );
      }
      case "addRemove": {
        return (
          <>
            <StyledTableCell>
              <Button
                className="btn"
                style={{ background: color.darkColor, color: "white" }}
                onClick={() => onSelectRowClick!(row, "click", true)}
              >
                {row["isAdded"] === "Y" ? "Remove" : "Add"}
              </Button>
            </StyledTableCell>
          </>
        );
      }
      case "subRequestAccess": {

        return (
          <>
            <StyledTableCell>
              {row["status"] === "Pending" ? (
                <div className="d-flex justify-content-between">
                  <Button
                    className="btn p-1"
                    style={{ background: color.darkColor, color: "white" }}
                    onClick={() => onSelectRowClick!(row, "accepted", true)}
                  >
                    Accept
                  </Button>
                  <Button
                    className="btn p-1"
                    style={{ background: color.darkColor, color: "white" }}
                    onClick={() => onSelectRowClick!(row, "declined", true)}
                  >
                    Decline
                  </Button>
                </div>
              ) : (
                row["status"].toUpperCase()
              )}
            </StyledTableCell>
          </>
        );
      }
      case "companyHover": {
        return (
          <StyledTableCell key={cell.id}>
            <Tooltip
              title={
                <React.Fragment>
                  <div className="tooltip-title size-lg">
                    <span className="row gx-0">User Name: {row['userName']}</span>
                    <span className="row gx-0">Contact Number: {row['contactNumber']}</span>
                    <span className="row gx-0">Email: {row['email']}</span>
                    <span className="row gx-0">Classification: {row['classification']}</span>
                    <span className="row gx-0">Labor Affiliation: {row['laborAffiliation']}</span>

                  </div>
                </React.Fragment>
              }
              arrow={true}
              enterDelay={1000}
            >
              <div className="d-flex jusstify-content-center ms-2 gap-2">
              {row[cell.id]}
              </div>
            </Tooltip>
          </StyledTableCell>
        );
      }
      case "tradeCheckBox": {
        return (
          <StyledTableCell key={cell.id}>
            <Checkbox
              style={{ color: color.darkColor, width: "20px" }}
              key={index}
              checked={Array.isArray(selectTradeId) &&  selectTradeId
                .map((value: any) => value)
                .includes(row["id"]) }
              onChange={(event) => {
                event.preventDefault();
                if(event.target.checked){
                  let tempTradeId : any[] = [...selectTradeId,row["id"]]
                  setSelectTradeId(tempTradeId);
                }else{
                  let tempTradeId : any[] = selectTradeId;
                  tempTradeId = tempTradeId.filter((id: any)=> id !== row["id"] );
                  setSelectTradeId(tempTradeId);
                  
                }
                handleCheck!(row, event.target.checked);
              }}
            />
          </StyledTableCell>
        );
      }

      case "removeTrade" :{
        return(
          <StyledTableCell sx={{ cursor: "pointer" }} key={cell.id}>
            <div className="d-flex ms-2 gap-3">
              <Tooltip
                title={
                  <React.Fragment>
                    <div className="tooltip-title">
                      <span>{row.actionTaken === "Deleted" ? "Restore from Bin" : "Remove"}</span>
                    </div>
                  </React.Fragment>
                }
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  size="lg"
                  onClick={() => {
                    handelRemoveTrade!(row.id, row);
                  }}
                />
              </Tooltip>
            </div>
          </StyledTableCell>
        );
      }
      case "tradeName": {
        return (
          <StyledTableCell
            style={{
              maxWidth: 200, // Set the maximum width of the cell
              overflow: "hidden",
            }}
            padding="normal"
            key={cell.id}
          >
            {row[cell.id] ? row[cell.id] : <span className="ms-3">-</span>}
          </StyledTableCell>
        );
      }

      default: {
        return (
          <StyledTableCell
            style={{
              maxWidth: 200, // Set the maximum width of the cell
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            padding="normal"
            key={cell.id}
          >
            {row[cell.id] ? row[cell.id] : <span className="ms-3">-</span>}
          </StyledTableCell>
        );
      }
    }
  };

  const handleStatusChange = (id: any, status: any) => {
    handleChangeDropDownStatus!(id, status);
  };

  return (
    <>
      <TableContainer key="table-container" sx={style}>
        <Table
          key="table"
          className="table shadow"
          sx={{
            overflowX: "hidden",
            overflowY: "scroll",
          }}
          size="small"
          aria-label="data table"
          stickyHeader={true}
        >
          <TableHead key="table-head">
            <TableRow key="row" style={{ zIndex: 1 }}>
              {headCells.map((cell) => (
                <StyledTableCell
                  key={cell.id}
                  style={{
                    width: 300,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {cell.action === "check" ? (
                    <Checkbox
                      style={{ color: color.darkColor, width: "20px" }}
                      checked={checkAll}
                      onChange={() => {
                        if (checkAll) {
                          handleCheckAll!([]);
                          setSubContractorMails([]);
                          dispatch(setBidInvitationMailList([]));
                          setCheckAll(false);
                        } else {
                          const filteredRows = rows.filter(
                            (list) => list.status === "InProgress"
                          );
                          handleCheckAll!(filteredRows);
                          let mailList = filteredRows.map(
                            (list: { email: any }) => list.email
                          );
                          setSubContractorMails(mailList);
                          dispatch(setBidInvitationMailList(mailList));
                          setCheckAll(true);
                        }
                      }}
                      key={"check " + cell.id}
                    />
                  ) : (
                    <span>{cell.name}</span>
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody key="body">
            {rows.length > 0 ? (
              rows.map((row, rowIndex) => (
                <TableRow hover key={row.name} sx={{ borderBottom: "1" }}>
                  {headCells.map((cell, index) =>
                    getTableCell(cell, row, rowIndex)
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={6}
                  align="center"
                  style={{ textAlign: "center" }}
                >
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        id="succes"
        isOpen={openModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div
              className="modal-header mb-1"
              style={{ borderBottom: "0.5px solid" }}
            >
              <div className="mx-auto">
                <FontAwesomeIcon
                  icon={faEnvelopeCircleCheck}
                  className="bg-succes opacity-50"
                  style={{ color: "#9bc569" }}
                  size="4x"
                />
              </div>
              <FontAwesomeIcon
                className="btn-close pointer"
                icon={faClose}
                size="lg"
                onClick={() => setOpenModal(false)}
              />
            </div>
            <div
              className="modal-body mt-5"
              style={{ width: "400px", height: "150px" }}
            >
              <div className="row">
                <InputField
                  id="email"
                  name="email"
                  value={selectedRow.email}
                  className="form-control py-1 form-control-lg"
                  type="text"
                  label="email"
                  required
                  handleChange={(e) => {
                    const email = e.target.value;
                    setSelectedRow({
                      ...selectedRow,
                      email,
                    });
                  }}
                />
              </div>
              <div className="row">
                <Button
                  className="btn col-6 mx-auto gx-5 mt-5"
                  style={{ backgroundColor: color.darkColor, color: "white" }}
                  onClick={(e) => {
                    e.preventDefault();
                    onSelectRowClick!(selectedRow, "click", false);
                    setOpenModal(false);
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CustomTable;
