import { useContext, useRef } from "react";
import { FormControl, InputLabel, Select, Button, MenuItem, TextField } from "@mui/material";
import { CustomTable } from "../../../DataTable";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../../../redux/selectors/selectors";

const MayBeBids = (props: any) => {

    const handleSelectedRow = (row: any, type: string, check: boolean) => {
        if (type === "edit") handleOpenModal(row);
        props.bidProps.getSubMenu("/ May Be Bids");
        props.bidProps.setBackButton(true);
    }

    const handleOpenModal = (data: any) => {
        props.setSelectedRow(data)
    };
    
    const constructionTypeList = [
        { id: 1, value: 'New Construction' },
        { id: 2, value: 'Improvements' },
        { id: 3, value: 'Renovation' }
    ];

    const headerCells = [
        { id: "projectName", name: "Project Name" },
        { id: "division", name: "Trade" },
        { id: "specification", name: "Specification" },
        { id: "city", name: "City" },
        { id: "bidDate", name: "Bid Date" },
        { id: "constructionType", name: "Construction Type" },
        { id: "action", name: "Action", action: "isRowActionable" }
    ];

    const dateRef: any = useRef()

    return (
        <>
            <div className="container">
                <div className="row mt-3">
                    <CustomTable headCells={headerCells} rows={props.tableRowData} onSelectRowClick = {handleSelectedRow}/>
                </div>
            </div>
        </>
    );
};

export default MayBeBids;
